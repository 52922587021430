import React, { Component } from 'react';
import classes from './styles.module.scss';
import { NavLink } from 'react-router-dom';

interface IProps {
    children: object | string;
    clicked?: () => void;
    styles?: object;
    disabled?: boolean;
    to?: string;
    type?: string;
}

class BoxButton extends Component<IProps, {}> {
    render() {
        const { children, clicked, styles, disabled, to, type } = this.props;

        const buttonClasses = [
            classes.BoxButton,
            disabled ? classes['BoxButton--disabled'] : '',
            type ? classes[`BoxButton--${type}`] : ''
        ].join(' ');

        return to ? (
            <NavLink style={styles} onClick={clicked} to={to} className={buttonClasses}>
                <div className={classes['BoxButton-btn']}>
                    {children}
                </div>
            </NavLink>
        ) : (
            <button disabled={disabled} style={styles} onClick={clicked} className={buttonClasses}>
                <div className={classes['BoxButton-btn']}>
                    {children}
                </div>
            </button>
        );
    }
}

export default BoxButton;
