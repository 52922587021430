import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import classes from './styles.module.scss';
import navigations from '../../../shared/data/navigations';
import NavigationLink from './NavigationLink';
import Button from '../Button';
import NavigationButton from './NavigationButton';
import { INavItem, IUser } from '../../../shared/interfaces';
import UserBadge from '../../UserBadge';

interface IProps {
    isAuthenticated: boolean;
    onSignout: () => void;
    onSignin: () => void;
    onNewTitle: () => void;
    currentUser: IUser;
}

const Navigation = (props: IProps) => {
    const { isAuthenticated, currentUser, onSignin, onSignout, onNewTitle } = props;

    const navItems = isAuthenticated ? navigations.AUTH_NAV : navigations.DEFAULT_NAV;

    const navItemsTpl = navItems.map((item: INavItem, index: number) => (
        <NavigationLink key={index} to={item.route} icon={item.icon}>
            {item.label}
        </NavigationLink>
    ));

    return (
        <div className={classes['Navigation']}>
            <div className={classes['Navigation-Left']}>{isAuthenticated && navItemsTpl}</div>
            <div className={classes['Navigation-Right']}>
                {isAuthenticated ? (
                    <Fragment>
                        <Button type={'add-title'} clicked={onNewTitle}>
                            <FormattedMessage id={'title.new'} />
                        </Button>
                        <UserBadge
                            onSignoutClicked={onSignout}
                            spaced={true}
                            currentUser={currentUser}
                        />
                    </Fragment>
                ) : (
                    <Fragment>
                        {navItemsTpl}
                        <NavigationButton clicked={onSignin} name={'Signin'}>
                            <FormattedMessage id={'general.signin'} />
                        </NavigationButton>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default Navigation;
