import React, { Component } from 'react';
import * as routes from '../../shared/routes';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import Loader from '../../components/UI/Loader';
import { ISessionData } from '../../shared/interfaces';

interface IProps {
    onActivation: (code: string) => any;
    activationError: string;
    userError: string;
    newUser: ISessionData;
    match: any;
    history: any;
    loading: boolean;
}

interface IState {}

class Activation extends Component<IProps, IState> {
    componentDidMount(): void {
        const {
            match: {
                params: { code }
            }
        } = this.props;

        if (code) {
            this.props.onActivation(code);
        } else {
            this.props.history.push(routes.HOMEPAGE);
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (
            this.props.activationError !== prevProps.activationError &&
            this.props.activationError
        ) {
            this.props.history.push(routes.HOMEPAGE);
        }
        if (this.props.userError !== prevProps.userError && this.props.userError) {
            this.props.history.push(routes.HOMEPAGE);
        }

        if (this.props.newUser !== prevProps.newUser && this.props.newUser) {
            this.props.history.push(routes.PUBLISHER);
        }
    }

    render() {
        return <Loader size={'md'} fullPage={true} />;
    }
}

const mapStateToProps = (state: any) => {
    return {
        activationError: state.registrationState.error,
        updatedUser: state.userState.user,
        userLoading: state.userState.loading,
        userError: state.userState.error,
        newUser: state.registrationState.newUser
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onActivation: (code: string) => dispatch(actions.activation(code))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activation);
