import request from '../../shared/lib/request';
import constants from '../../shared/constants';

const get = (id?: number) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    const endpoint = id ? `/v2/genre/${id}` : '/v2/genre';

    return request({
        url: endpoint,
        method: 'GET',
        headers
    });
};

const GenresService = {
    get
};

export default GenresService;
