import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    children: any;
    size?: string;
}

const TopBar = (props: IProps) => (
    <div className={[classes.TopBar, props.size ? classes[`TopBar--${props.size}`] : ''].join(' ')}>
        {props.children}
    </div>
);

export default TopBar;
