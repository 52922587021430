import * as actionTypes from './actionTypes';
import AwardsService from '../../services/api/awards';
import { IError, IServerOption, IResponse } from '../../shared/interfaces';

// READ/FETCH
export const fetchAwardStart = () => {
    return {
        type: actionTypes.FETCH_FEATURE_START
    };
};

export const fetchAwardSuccess = (award: IServerOption) => {
    return {
        type: actionTypes.FETCH_FEATURE_SUCCESS,
        award
    };
};

export const fetchAwardFail = (error: string) => {
    return {
        type: actionTypes.FETCH_FEATURE_FAIL,
        error
    };
};

export const fetchAward = (id: number) => {
    return (dispatch: any) => {
        if (id) dispatch(fetchAwardStart());

        AwardsService.get(id).then(
            (response: IResponse) => {
                dispatch(fetchAwardSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchAwardFail(errorMsg));
            }
        );
    };
};

export const fetchAwardsStart = () => {
    return {
        type: actionTypes.FETCH_AWARDS_START
    };
};

export const fetchAwardsSuccess = (awards: Array<IServerOption>) => {
    return {
        type: actionTypes.FETCH_AWARDS_SUCCESS,
        awards
    };
};

export const fetchAwardsFail = (error: string) => {
    return {
        type: actionTypes.FETCH_AWARDS_FAIL,
        error
    };
};

export const fetchAwards = () => {
    return (dispatch: any) => {
        dispatch(fetchAwardsStart());

        AwardsService.get().then(
            (response: IResponse) => {
                dispatch(fetchAwardsSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchAwardsFail(errorMsg));
            }
        );
    };
};
