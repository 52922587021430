import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as routes from '../../../shared/routes'
import { NavLink } from 'react-router-dom';
import { ITextArea, ITitle } from '../../../shared/interfaces';
import mainClasses from '../styles.module.scss';
import stepClasses from './styles.module.scss';
import { RadioGroup, Radio } from 'react-radio-group';
import Text from '../../UI/Text';
import TextAreaEditor from '../../Field/TextAreaEditor';

interface IProps {
    data: ITitle;
    setData(name: string, value: any): void;
}

interface IState {
    selectedValue: string | null;
    controls: {
        copyrightText: ITextArea;
    };
}

class IPDetails extends Component<IProps, IState> {
    state = {
        selectedValue: null,
        controls: {
            copyrightText: {
                isPlainText: true,
                toolbarHidden: true,
                autosize: true,
                name: 'copyrightText',
                value: '',
                type: 'textarea',
                placeholderId: 'placeholder.copyrightText'
            }
        }
    };

    componentDidMount(): void {
        this.initializeData();
    }

    initializeData = () => {
        const {
            data: { copyrightType, copyrightText }
        } = this.props;

        if (copyrightText) {
            let controls = { ...this.state.controls };

            controls.copyrightText.value = copyrightText;

            this.setState({
                controls
            });
        }

        this.setState({
            selectedValue: copyrightType
        });
    };

    handleChange = (value: string) => {
        this.setState(
            {
                selectedValue: value
            },
            () => {
                this.props.setData('copyrightType', value);
            }
        );
    };

    handleInputChanged = (name: string, value: string) => {
        let controls: any = { ...this.state.controls };

        controls[name].value = value;

        this.setState(
            {
                controls
            },
            () => {
                this.props.setData(name, value);
            }
        );
    };

    render() {
        const { selectedValue, controls } = this.state;

        return (
            <div className={[mainClasses.TitleForm, stepClasses.IPDetails].join(' ')}>
                <div className={mainClasses['TitleForm-header']}>
                    <Text tag={'h2'} transform={'uppercase'} weight={600} spacing={true}>
                        <FormattedMessage id={'title.section.ipDetails'} />
                    </Text>
                </div>
                <div className={mainClasses['TitleForm-content']}>
                    <div className={stepClasses['IPDetails-description']}>
                        <p>
                            <FormattedMessage id={'license.title'} />
                            <FormattedMessage
                                id={'license.notsure.title'}
                                values={{
                                    contact: <NavLink to={routes.SUPPORT} target="_blank" rel="noopener noreferrer">contattaci</NavLink>
                                }}/>
                        </p>
                    </div>
                    <div className={stepClasses['IPDetails-field']}>
                        <RadioGroup
                            name="copyright"
                            selectedValue={selectedValue}
                            onChange={this.handleChange}
                            Component="div">
                            <label className={stepClasses['IPDetails-row']}>
                                <Radio value={'full'} />
                                <div className={stepClasses['IPDetails-label']}>
                                    <span className={stepClasses['IPDetails-option']}>
                                        <FormattedMessage id={'license.full.title'} />
                                    </span>
                                    <p className={stepClasses['IPDetails-description']}>
                                        <FormattedMessage id={'license.full.description'} />
                                    </p>
                                </div>
                            </label>
                            <label className={stepClasses['IPDetails-row']}>
                                <Radio value={'partial'} />
                                <div className={stepClasses['IPDetails-label']}>
                                    <span className={stepClasses['IPDetails-option']}>
                                        <FormattedMessage id={'license.partial.title'} />
                                    </span>
                                    <p className={stepClasses['IPDetails-description']}>
                                        <FormattedMessage id={'license.partial.description'} />
                                    </p>
                                    {selectedValue === 'partial' && (
                                        <TextAreaEditor
                                            name={controls.copyrightText.name}
                                            isPlainText={controls.copyrightText.isPlainText}
                                            toolbarHidden={controls.copyrightText.toolbarHidden}
                                            value={controls.copyrightText.value}
                                            autosize={controls.copyrightText.autosize}
                                            placeholderId={controls.copyrightText.placeholderId}
                                            onChange={this.handleInputChanged}
                                        />
                                    )}
                                </div>
                            </label>
                            <label className={stepClasses['IPDetails-row']}>
                                <Radio value={'mandate'} />
                                <div className={stepClasses['IPDetails-label']}>
                                    <span className={stepClasses['IPDetails-option']}>
                                        <FormattedMessage id={'license.mandate.title'} />
                                    </span>
                                    <p className={stepClasses['IPDetails-description']}>
                                        <FormattedMessage id={'license.mandate.description'} />
                                    </p>
                                </div>
                            </label>
                        </RadioGroup>
                    </div>
                </div>
            </div>
        );
    }
}

export default IPDetails;
