import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import Text from '../../components/UI/Text';
import FeedbackMessage from '../../components/UI/FeedbackMessage';

interface IProps {
    history: History;
}

class ThankYou extends Component<IProps> {
    render() {
        return (
            <main className={classes.ThankYou}>
                <Text marginBottom={30}>{'Thank You'}</Text>
                <FeedbackMessage size={'lg'} type={'success'} visible={true}>
                    {'Grazie per esserti registrato'}
                </FeedbackMessage>
            </main>
        );
    }
}

export default ThankYou;
