import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    children: any;
}

const Sidebar = (props: IProps) => <aside className={classes.Sidebar}>{props.children}</aside>;

export default Sidebar;
