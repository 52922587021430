import React, { Component } from 'react';
import classes from './styles.module.scss';
import TitleForm from '../../components/TitleForm';
import AuthWrapper from '../../containers/AuthWrapper';

class Title extends Component {
    render() {
        return (
            <AuthWrapper>
                <main className={classes.Title}>
                    <TitleForm />
                </main>
            </AuthWrapper>
        );
    }
}

export default Title;
