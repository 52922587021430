import React from 'react';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import Toast from '../../UI/Toast';

interface IProps {
    titleId?: string;
    styles?: object;
    multiple?: boolean;
    accept?: string | undefined;
    onSelectedFiles: (uploaded: Array<File>, rejected: Array<File>) => void;
    reference?: any;
    squared?: boolean;
    large?: boolean;
    fullWidth?: boolean;
    maxSize?: number;
    disabled?: boolean;
}

const Uploader = ({
    titleId,
    styles,
    multiple,
    accept,
    onSelectedFiles,
    reference,
    squared,
    large,
    fullWidth,
    maxSize,
    disabled
}: IProps) => {
    const onDrop = (acceptedFiles: Array<File>, rejectedFiles: Array<File>) => {
        if (rejectedFiles.length === 0) {
            onSelectedFiles(acceptedFiles, rejectedFiles);
        } else {
            const message = maxSize
                ? 'Controlla il peso del file caricato!'
                : 'Il file caricato è più pesante del massimo consentito! (Max 8 MB)';

            Toast({ message, type: 'error' });
        }
    };

    const largeTpl = titleId && (
        <label className={[classes['Uploader-label'], classes['Uploader-label--plus']].join(' ')}>
            <FormattedMessage id={titleId} />
            <div className={classes['Uploader-max']}>
                <FormattedMessage
                    id="label.maxSize"
                    values={{
                        maxSize: maxSize ? `${maxSize}MB` : ' 8MB'
                    }}
                />
            </div>
        </label>
    );

    return (
        <div className={classes.UploaderContainer} ref={reference}>
            <Dropzone
                disabled={disabled}
                maxSize={maxSize ? maxSize * 1024000 : 8704000}
                onDrop={onDrop}
                multiple={multiple ? multiple : false}
                accept={accept}
            >
                {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                        <div
                            {...getRootProps()}
                            style={styles}
                            className={[
                                classes.Uploader,
                                isDragActive ? classes['is-active'] : ''
                            ].join(' ')}
                        >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <span
                                    className={[
                                        classes['Uploader-content'],
                                        disabled ? classes['is-disabled'] : ''
                                    ].join(' ')}
                                >
                                    <div className={classes['Uploader-round']}>+</div>
                                    {titleId && (
                                        <div className={classes['Uploader-infos']}>
                                            <label className={classes['Uploader-label']}>
                                                <FormattedMessage id={titleId} />
                                            </label>
                                            <div className={classes['Uploader-max']}>
                                                <FormattedMessage
                                                    id="label.maxSize"
                                                    values={{
                                                        maxSize: maxSize ? `${maxSize}MB` : ' 8MB'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </span>
                            ) : (
                                <div
                                    className={[
                                        classes['Uploader-content'],
                                        large ? classes['Uploader-large'] : '',
                                        fullWidth ? classes['Uploader-fullWidth'] : '',
                                        disabled ? classes['is-disabled'] : ''
                                    ].join(' ')}
                                >
                                    <div
                                        className={[
                                            classes['Uploader-round'],
                                            squared ? classes['Uploader-squared'] : ''
                                        ].join(' ')}
                                    >
                                        { largeTpl }
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                }}
            </Dropzone>
        </div>
    );
};

export default Uploader;
