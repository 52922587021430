import React, { Component } from 'react';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import * as routes from '../../shared/routes';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { resetSession } from '../../shared/helpers/session';
import Button from '../../components/UI/Button';
import Input from '../../components/Field/Input';
import FeedbackMessage from '../../components/UI/FeedbackMessage';
import Text from '../../components/UI/Text';

interface IProps {
    onUpdatePassword: (data: any) => any;
    user: any;
    match: any;
    history: any;
}

interface IState {
    password: any;
    code: any;
    error: any;
    changed: boolean;
}

class Recovery extends Component<IProps, IState> {
    state = {
        code: null,
        password: {
            value: '',
            confirm: ''
        },
        changed: false,
        error: null
    };

    componentDidMount(): void {
        resetSession();
        const {
            match: {
                params: { code }
            }
        } = this.props;
        this.setState({ code });
    }

    handleInputChanged = (name: string, value: string) => {
        console.log(name, value);
        let password = { ...this.state.password };

        password[name] = value;

        this.setState({
            password
        });
    };

    isPasswordsValid() {
        const { password } = this.state;

        let error = null;
        let valid = true;


        if (password.value !== password.confirm) {
            // @todo: Mike use here error = <Formatted..... and then set State for error
            error = <FormattedMessage id="general.passwordDoNotMatch" />;
            valid = false;
        } else {
            if (password.value.length < 8) {
                // @todo: Mike use here error = <Formatted..... and then set State for error
                error = <FormattedMessage id="general.passwordLenght" />;
                valid = false;
            }
        }

        this.setState({
            error
        });

        return valid;
    }

    updateClicked = () => {
        const { code, password } = this.state;

        if (this.isPasswordsValid()) {
            this.props.onUpdatePassword({ code, password: password.value });
        }
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.user !== prevProps.user && this.props.user) {
            this.setState(
                {
                    changed: true
                },
                () => {
                    setTimeout(() => {
                        this.props.history.push(routes.HOMEPAGE);
                    }, 3000);
                }
            );
        }
    }

    render() {
        const { password, error, changed } = this.state;

        return (
            <div className={classes.Recovery}>

                <div className={classes['Recovery-header']}>
                    <Text
                        tag={'h2'}
                        marginBottom={30}
                        weight={400}
                        transform={'uppercase'}
                        spacing={true}
                    >
                        <span>
                            <FormattedMessage id="recovery.claim" />
                        </span>
                    </Text>
                </div>
                
                <div className={classes['Recovery-container']}>

                    <div className={classes['Recovery-content']}>
                        <Input
                            label={<FormattedMessage id={'recovery.label.newPassword'} />}
                            changed={(name: string, value: string) =>
                                this.handleInputChanged('value', value)
                            }
                            type="password"
                            name="new-password"
                            value={password.value}
                            styles={{ marginBottom: '20px' }}
                        />
                        <Input
                            label={<FormattedMessage id={'recovery.label.newPasswordRepeat'} />}
                            changed={(name: string, value: string) =>
                                this.handleInputChanged('confirm', value)
                            }
                            type="password"
                            name="new-password-repeat"
                            value={password.confirm}
                        />
                    </div>
                    {// @todo: Mike style for error here or where you want
                    error && 
                        <FeedbackMessage visible={true} type="error">
                            {error}
                        </FeedbackMessage>
                    }
                    {changed && (
                        <div className={classes['Recovery-confirms']}>
                            <FormattedMessage id={'recovery.label.passwordChanged'} />
                        </div>
                    )}
                    <div className={classes['Recovery-footer']}>
                        <Button
                            disabled={
                                password.value.trim() === '' || password.confirm.trim() === ''
                            }
                            clicked={this.updateClicked}
                        >
                            <FormattedMessage id="recovery.save" />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.userState.user,
        error: state.userState.error
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onUpdatePassword: (data: any) => dispatch(actions.updateUserPassword(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recovery);
