import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { IInput, ITextArea, ITitle } from '../../../shared/interfaces';
import mainClasses from '../styles.module.scss';
import stepClasses from './styles.module.scss';
import Input from '../../Field/Input';
import TextAreaEditor from '../../Field/TextAreaEditor';
import Text from '../../UI/Text';
import Example from '../../UI/Example';

interface IProps {
    data: ITitle;
    setData(name: string, value: any): void;
}

interface IControls {
    title: IInput;
    synopsis: ITextArea;
    logline: IInput;
}

interface IState {
    controls: IControls;
}

class TitleAndStory extends Component<IProps, IState> {
    state = {
        controls: {
            title: {
                name: 'title',
                value: '',
                type: 'text',
                placeholderId: 'placeholder.title',
                labelId: 'label.title',
                required: true
            },
            logline: {
                isPlainText: true,
                toolbarHidden: true,
                autosize: true,
                name: 'logline',
                value: '',
                type: 'textarea',
                placeholderId: 'placeholder.logline',
                labelId: 'label.logline',
                required: true,
                suggestedLength: 180,
                maxLength: 250,
                instructionIds: ['instruction.logline'],
                example: ['example.logline.1', 'example.logline.2'],
                exampleAuthor: ['example.author.1', 'example.author.2']
            },
            synopsis: {
                isPlainText: true,
                toolbarHidden: true,
                autosize: true,
                name: 'synopsis',
                value: '',
                type: 'textarea',
                placeholderId: 'placeholder.synopsis',
                labelId: 'label.synopsis',
                required: true,
                suggestedLength: 1500,
                maxLength: 2500,
                instructionIds: ['instruction.synopsis'],
                example: ['example.synopsis.1', 'example.synopsis.2'],
                exampleAuthor: ['example.author.1', 'example.author.2']
            }
        }
    };

    componentDidMount(): void {
        this.initializeData();
    }

    initializeData = () => {
        const {
            data: { title, synopsis, logline }
        } = this.props;

        this.setTextValue('title', title);
        this.setTextValue('synopsis', synopsis);
        this.setTextValue('logline', logline);
    };

    setTextValue = (name: string, value: string) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value;

        this.setState({
            controls
        });
    };

    handleInputChanged = (name: string, value: string) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value;

        this.setState(
            {
                controls
            },
            () => {
                this.props.setData(name, value);
            }
        );
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.data !== prevProps.data && this.props.data) {
            this.initializeData();
        }
    }

    render() {
        const { controls } = this.state;

        const inputElementsTpl = Object.values(controls).map((item: IInput, index: number) => {
            return (
                    item.type === 'textarea' ? (
                        <div key={index} className={mainClasses['TitleForm-field']}>
                            { item.example && item.example.length > 0 &&
                                <Example reference={item.labelId} textIds={item.example} authors={item.exampleAuthor} />
                            }
                            <TextAreaEditor
                                name={item.name}
                                isPlainText={item.isPlainText}
                                toolbarHidden={item.toolbarHidden}
                                value={item.value}
                                autosize={item.autosize}
                                label={item.labelId ? <FormattedMessage id={item.labelId} /> : ''}
                                placeholderId={item.placeholderId}
                                required={item.required}
                                suggestedLength={item.suggestedLength ? item.suggestedLength : 0}
                                maxLength={item.maxLength}
                                instructionIds={item.instructionIds}
                                onChange={this.handleInputChanged}
                            />
                        </div>
                    ) : (
                        <div
                            key={index}
                            className={[mainClasses['TitleForm-field'], mainClasses['TitleForm-field--small']].join(' ')}>
                        <Input
                            label={item.labelId ? <FormattedMessage id={item.labelId} /> : ''}
                            required={item.required}
                            type={item.type}
                            name={item.name}
                            placeholderId={item.placeholderId}
                            value={item.value}
                            maxLength={item.maxLength}
                            changed={this.handleInputChanged}
                        />
                        </div>
                    )
            );
        });

        return (
            <div className={[mainClasses.TitleForm, stepClasses.TitleAndStory].join(' ')}>
                <div className={mainClasses['TitleForm-header']}>
                    <Text tag={'h2'} transform={'uppercase'} weight={600} spacing={true}>
                        <FormattedMessage id={'title.section.titleAndStory'} />
                    </Text>
                </div>
                <div className={mainClasses['TitleForm-content']}>{inputElementsTpl}</div>
            </div>
        );
    }
}

export default TitleAndStory;
