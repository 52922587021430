import * as actionTypes from './actionTypes';
import FeaturesService from '../../services/api/features';
import { IError, IServerOption, IResponse } from '../../shared/interfaces';

// READ/FETCH
export const fetchFeatureStart = () => {
    return {
        type: actionTypes.FETCH_FEATURE_START
    };
};

export const fetchFeatureSuccess = (feature: IServerOption) => {
    return {
        type: actionTypes.FETCH_FEATURE_SUCCESS,
        feature
    };
};

export const fetchFeatureFail = (error: string) => {
    return {
        type: actionTypes.FETCH_FEATURE_FAIL,
        error
    };
};

export const fetchFeature = (id: number) => {
    return (dispatch: any) => {
        if (id) dispatch(fetchFeatureStart());

        FeaturesService.get(id).then(
            (response: IResponse) => {
                dispatch(fetchFeatureSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchFeatureFail(errorMsg));
            }
        );
    };
};

export const fetchFeaturesStart = () => {
    return {
        type: actionTypes.FETCH_FEATURES_START
    };
};

export const fetchFeaturesSuccess = (features: Array<IServerOption>) => {
    return {
        type: actionTypes.FETCH_FEATURES_SUCCESS,
        features
    };
};

export const fetchFeaturesFail = (error: string) => {
    return {
        type: actionTypes.FETCH_FEATURES_FAIL,
        error
    };
};

export const fetchFeatures = () => {
    return (dispatch: any) => {
        dispatch(fetchFeaturesStart());

        FeaturesService.get().then(
            (response: IResponse) => {
                dispatch(fetchFeaturesSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchFeaturesFail(errorMsg));
            }
        );
    };
};
