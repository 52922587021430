import React, { Component } from 'react';
import classes from './styles.module.scss';
import Autocomplete from 'react-google-autocomplete';
import { FormattedMessage } from 'react-intl';
import Instruction from '../../UI/Instruction';

interface IProps {
    label?: string | object;
    name: string;
    changed: (name: string, value: any, addressComponents?: any) => any;
    value?: string;
    instructionIds?: Array<string>;
    placeholderId?: string;
    required?: boolean;
    onlyCities?: boolean;
}

class AddressAutocomplete extends Component<IProps> {
    onPlaceSelected = (place: any) => {
        const { changed, name } = this.props;

        const data = {
            formatted_address: place ? place.formatted_address : null,
            lat: place && place.geometry ? place.geometry.location.lat() : null,
            lng: place && place.geometry ? place.geometry.location.lng() : null
        };

        if (data.lat && data.lng) changed(name, data, place.address_components);
    };

    render() {
        const {
            label,
            name,
            value,
            instructionIds,
            placeholderId,
            required,
            onlyCities
        } = this.props;

        const inputTpl = (
            <FormattedMessage id={placeholderId || 'general.emptyMessage'} defaultMessage={''}>
                {(placeholder: any) => (
                    <Autocomplete
                        className={classes['AddressAutocomplete-field']}
                        placeholder={placeholder}
                        defaultValue={value}
                        name={name}
                        onPlaceSelected={this.onPlaceSelected}
                        types={[!onlyCities ? 'address' : '(regions)']}
                    />
                )}
            </FormattedMessage>
        );

        return (
            <div className={classes.AddressAutocomplete}>
                <div className={classes['AddressAutocomplete-content']}>
                    <div className={classes['AddressAutocomplete-wrapper']}>
                        {label && (
                            <div className={classes['AddressAutocomplete-header']}>
                                <label className={classes['AddressAutocomplete-label']}>
                                    {label}
                                </label>
                                {required && (
                                    <span className={classes['AddressAutocomplete-required']}>
                                        <FormattedMessage id={'general.required'} />
                                    </span>
                                )}
                            </div>
                        )}
                        {inputTpl}
                    </div>
                    {instructionIds && <Instruction textIds={instructionIds} />}
                </div>
            </div>
        );
    }
}

export default AddressAutocomplete;
