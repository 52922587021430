import { IServerOption } from '../interfaces';

export const randomId = (): string => {
    return (
        '_' +
        Math.random()
            .toString(36)
            .substr(2, 9)
    );
};

export const getUnique = (arr: any, comp: any) => {
    return (
        arr
            .map((e: any) => e[comp])

            // store the keys of the unique objects
            .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter((e: any) => arr[e])
            .map((e: any) => arr[e])
    );
};

export const countWordInArray = (array_elements: any) => {
    array_elements.sort();

    let list = [];

    let current = null;
    let cnt = 0;
    for (let i = 0; i < array_elements.length; i++) {
        if (array_elements[i] !== current) {
            if (cnt > 0) {
                list.push({
                    value: current,
                    count: cnt
                });
                //console.log(current + ' comes --> ' + cnt + ' times<br>');
            }
            current = array_elements[i];
            cnt = 1;
        } else {
            cnt++;
        }
    }
    if (cnt > 0) {
        //console.log(current + ' comes --> ' + cnt + ' times');

        list.push({
            value: current,
            count: cnt
        });
    }

    return list;
};

export const updateObject = (oldState: any, updatedObject: any) => {
    return {
        ...oldState,
        ...updatedObject
    };
};

export const getStringsFromServerList = (options: Array<IServerOption>) => {
    return options.map((item: IServerOption) => item.label);
};

export const getSplitValues = (value: string, separator: string) => {
    return value.split(separator);
};

export const getPlaceBySplitValues = (values: Array<string>) => {
    const splitPlace = getSplitValues(values[0], ' ');

    if (splitPlace.length === 2 && splitPlace[1].length < 3) {
        return splitPlace[0];
    }

    return values[0];
};

export const parseAddressComponents = (components, formatted_address) => {
    const route =
        components
            .filter(component => component.types.indexOf('route') === 0)
            .map(item => item.long_name)
            .pop() || null;
    const streetNumber =
        components
            .filter(component => component.types.indexOf('street_number') === 0)
            .map(item => item.long_name)
            .pop() || null;
    const formattedAddress = formatted_address;
    const postalCode =
        components
            .filter(component => component.types.indexOf('postal_code') === 0)
            .map(item => item.long_name)
            .pop() || '';
    const country =
        components
            .filter(component => component.types.indexOf('country') === 0)
            .map(item => item.long_name)
            .pop() || null;
    const city =
        components
            .filter(component => component.types.indexOf('administrative_area_level_3') === 0)
            .map(item => item.long_name)
            .pop() || null;
    const province =
        components
            .filter(component => component.types.indexOf('administrative_area_level_2') === 0)
            .map(item => item.short_name)
            .pop() || null;

    return {
        route,
        formattedAddress,
        streetNumber,
        postalCode,
        city,
        province,
        country
    };
};
