import * as actionTypes from './actionTypes';
import TranslationsService from '../../services/api/translations';
import { IError, IServerOption, IResponse } from '../../shared/interfaces';

// READ/FETCH
export const fetchTranslationStart = () => {
    return {
        type: actionTypes.FETCH_TRANSLATION_START
    };
};

export const fetchTranslationSuccess = (translation: IServerOption) => {
    return {
        type: actionTypes.FETCH_TRANSLATION_SUCCESS,
        translation
    };
};

export const fetchTranslationFail = (error: string) => {
    return {
        type: actionTypes.FETCH_TRANSLATION_FAIL,
        error
    };
};

export const fetchTranslation = (id: number) => {
    return (dispatch: any) => {
        if (id) dispatch(fetchTranslationStart());

        TranslationsService.get(id).then(
            (response: IResponse) => {
                dispatch(fetchTranslationSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchTranslationFail(errorMsg));
            }
        );
    };
};

export const fetchTranslationsStart = () => {
    return {
        type: actionTypes.FETCH_TRANSLATIONS_START
    };
};

export const fetchTranslationsSuccess = (translations: Array<IServerOption>) => {
    return {
        type: actionTypes.FETCH_TRANSLATIONS_SUCCESS,
        translations
    };
};

export const fetchTranslationsFail = (error: string) => {
    return {
        type: actionTypes.FETCH_TRANSLATIONS_FAIL,
        error
    };
};

export const fetchTranslations = () => {
    return (dispatch: any) => {
        dispatch(fetchTranslationsStart());

        TranslationsService.get().then(
            (response: IResponse) => {
                dispatch(fetchTranslationsSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchTranslationsFail(errorMsg));
            }
        );
    };
};
