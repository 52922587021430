import * as actionTypes from './actionTypes';
import RegistrationsService from '../../services/api/sessions';
import { IError, IResponse, ISessionData } from '../../shared/interfaces';
import SessionsService from '../../services/api/sessions';
import { resetSession, setSession } from '../../shared/helpers/session';

// SESSION
export const sessionStart = () => {
    resetSession();

    return {
        type: actionTypes.SESSION_START
    };
};

export const sessionSuccess = (session: ISessionData) => {
    return {
        type: actionTypes.SESSION_SUCCESS,
        session
    };
};

export const sessionFail = (error: string) => {
    return {
        type: actionTypes.SESSION_FAIL,
        error
    };
};

export const session = (data: object) => {
    return (dispatch: any) => {
        dispatch(sessionStart());

        RegistrationsService.create(data).then(
            (response: IResponse) => {
                const { data } = response;

                setSession(data);

                dispatch(sessionSuccess(data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');

                    if (
                        errorMsg.toLowerCase() === 'entity not found' ||
                        errorMsg.toLowerCase() === 'unauthorized'
                    ) {
                        errorMsg = 'Username e/o passowrd non corrette';
                    }
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(sessionFail(errorMsg));
            }
        );
    };
};

// FETCH ME
export const fetchMeStart = () => {
    return {
        type: actionTypes.FETCH_ME_START
    };
};

export const fetchMeSuccess = (session: object) => {
    return {
        type: actionTypes.FETCH_ME_SUCCESS,
        session
    };
};

export const fetchMeFail = (error: string) => {
    return {
        type: actionTypes.FETCH_ME_FAIL,
        error
    };
};

export const fetchMe = () => {
    return (dispatch: any) => {
        dispatch(fetchMeStart());

        SessionsService.me().then(
            (response: IResponse) => {
                dispatch(fetchMeSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { info }
                } = error;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(fetchMeFail(errorMsg));
            }
        );
    };
};

// SIGNOUT
export const signout = () => {
    resetSession();

    return {
        type: actionTypes.SESSION_SIGNOUT_SUCCESS
    };
};
