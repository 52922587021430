import React from 'react';
import classes from './styles.module.scss';
import { NavLink } from 'react-router-dom';

interface IProps {
    to: string;
    children: string | any;
    icon?: string;
}

const NavigationLink = (props: IProps) => {
    const { to, children, icon } = props;

    return (
        <NavLink
            activeClassName={classes['is-active']}
            to={to}
            className={[
                classes.NavigationLink,
                icon ? classes[`NavigationLink--${icon}`] : ''
            ].join(' ')}
        >
            {children}
        </NavLink>
    );
};

export default NavigationLink;
