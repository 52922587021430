import React, { Component } from 'react';
import classes from './Selector.module.scss';
import { IOption } from '../../../shared/interfaces';
import { FormattedMessage } from 'react-intl';
import Instruction from '../../UI/Instruction';

interface IProps {
    label?: string | object;
    name: string;
    options: Array<IOption>;
    clicked: (name: string, index: number, selected: boolean) => void;
    multiple?: boolean;
    minSelected?: number;
    limit?: number;
    instructionIds?: Array<string>;
}

interface IState {
    originalOptions: Array<IOption>;
    truncatedOptions: Array<IOption>;
    limit: number;
    initialized: boolean;
}

class Selector extends Component<IProps, IState> {
    state = {
        originalOptions: [],
        truncatedOptions: [],
        limit: 0,
        initialized: false
    };

    componentDidMount(): void {
        this.initializeData();
    }

    initializeData = () => {
        const { options, limit } = this.props;

        this.setState({
            originalOptions: options,
            truncatedOptions: limit ? options.slice(0, limit) : options
        });

        if (!this.state.initialized) {
            this.setState({
                initialized: true,
                limit
            });
        }
    };

    toggleOther = () => {
        const limit = this.state.limit;

        this.setState({
            limit: limit > 0 ? 0 : this.props.limit
        });
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.options !== prevProps.options) {
            this.initializeData();
        }
    }

    render() {
        const { name, clicked, minSelected, label, instructionIds }: IProps = this.props;
        const { originalOptions, truncatedOptions, limit } = this.state;

        const optionsTpl = !limit
            ? originalOptions.map((option: IOption, index: number) => {
                  const { label, selected } = option;

                  const optionClass = [
                      classes['Selector-option'],
                      selected ? classes['is-selected'] : ''
                  ].join(' ');

                  return (
                      <button
                          key={index}
                          onClick={() => clicked(name, index, !selected)}
                          className={optionClass}
                      >
                          {label}
                      </button>
                  );
              })
            : truncatedOptions.map((option: IOption, index: number) => {
                  const { label, selected } = option;

                  const optionClass = [
                      classes['Selector-option'],
                      selected ? classes['is-selected'] : ''
                  ].join(' ');

                  return (
                      <button
                          key={index}
                          onClick={() => clicked(name, index, !selected)}
                          className={optionClass}
                      >
                          {label}
                      </button>
                  );
              });

        return (
            <div className={classes.Selector}>
                <div className={classes['Selector-header']}>
                    <label className={classes['Selector-label']}>{label}</label>
                    {minSelected && (
                        <span className={classes['Selector-required']}>
                            <FormattedMessage id={'general.required'} />
                        </span>
                    )}
                </div>
                <div className={classes['Selector-content']}>
                    <div className={classes['Selector-wrapper']}>
                        {optionsTpl}
                        {limit > 0 && (
                            <button
                                onClick={this.toggleOther}
                                className={classes['Selector-option']}
                            >
                                <FormattedMessage id={'label.other'} />
                            </button>
                        )}
                    </div>
                    {instructionIds && <Instruction textIds={instructionIds} />}
                </div>
            </div>
        );
    }
}

export default Selector;
