import React, { Component } from 'react';
import classes from './styles.module.scss';
import TitlesList from '../../components/TitlesList';
import AuthWrapper from '../../containers/AuthWrapper';
import TopBar from '../../containers/TopBar';
import Button from '../../components/UI/Button';
import { FormattedMessage } from 'react-intl';
import * as actions from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ITitle } from '../../shared/interfaces';
import Loader from '../../components/UI/Loader';

interface IProps {
    titles: Array<ITitle>;
    waitingForTitles?: boolean;
    error?: string;
    onGetMyTitles(): Array<ITitle>;
    onSortTitles(ids: Array<number>): void;
    waitingForNewOrder: boolean;
    newOrderSuccess: boolean;
}

interface IState {
    showType: number;
    showSort: boolean;
    titles: Array<ITitle>;
}

class Titles extends Component<IProps & any, IState> {
    state = {
        showType: 0,
        showSort: false,
        titles: []
    };

    componentDidMount(): void {
        this.props.onGetMyTitles();
    }

    onFilterClicked = (type: number = 0) => {
        this.setState({
            showType: type
        });
    };

    onSortConfirmed = (titles: Array<ITitle>) => {
        this.setState(
            {
                titles
            },
            () => {
                this.props.onSortTitles(titles.map((title: ITitle) => title.id));
            }
        );
    };

    handleToggleSortVisibility = () => {
        const showSort = !this.state.showSort;

        this.setState({
            showSort
        });
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({
                titles: this.props.titles
            });
        }

        if (this.props.titles !== prevProps.titles) {
            this.setState({
                titles: this.props.titles
            });
        }
    }

    render() {
        const { titles, showType, showSort } = this.state;
        const { waitingForTitles, waitingForNewOrder } = this.props;

        if (waitingForTitles || waitingForNewOrder) {
            return <Loader theme={'light'} fullContent={true} size={'sm'} />;
        }

        return (
            <AuthWrapper>
                <main className={classes.Titles}>
                    <TopBar size="full">
                        <Button
                            isActive={showType === 0}
                            type={'topbar'}
                            clicked={() => this.onFilterClicked(0)}
                        >
                            <FormattedMessage id={'titles.all'} />
                        </Button>
                        <Button
                            isActive={showType === 1}
                            type={'topbar'}
                            clicked={() => this.onFilterClicked(1)}
                        >
                            <FormattedMessage id={'titles.published'} />
                        </Button>
                        <Button
                            isActive={showType === 2}
                            type={'topbar'}
                            clicked={() => this.onFilterClicked(2)}
                        >
                            <FormattedMessage id={'titles.drafts'} />
                        </Button>
                        {titles.length > 0 && (
                            <Button
                                styles={{ marginLeft: 'auto' }}
                                type={'topbar'}
                                clicked={this.handleToggleSortVisibility}
                            >
                                {showSort ? (
                                    <FormattedMessage id={'general.close'} />
                                ) : (
                                    <FormattedMessage id={'titles.order'} />
                                )}
                            </Button>
                        )}
                    </TopBar>

                    <TitlesList
                        withReorder={showSort}
                        onReorderConfirmed={this.onSortConfirmed}
                        size={'lg'}
                        titles={titles}
                        withEdit={true}
                        onlyDraft={showType === 2}
                        onlyPublished={showType === 1}
                        hasNewButton={!showSort}
                    />
                </main>
            </AuthWrapper>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.userState.user,
        titles: state.titleState.myList,
        waitingForTitles: state.titleState.isFetching,
        waitingForNewOrder: state.titleState.isOrdering,
        newOrderSuccess: state.titleState.newOrderSuccess,
        error: state.titleState.error
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetMyTitles: () => dispatch(actions.fetchMyTitles()),
        onSortTitles: (ids: Array<number>) => dispatch(actions.sortTitles(ids))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Titles));
