import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    clicked: () => void;
    children: object | string;
    name?: string;
}

const NavigationButton = (props: IProps) => {
    const { clicked, children, name } = props;

    return (
        <button
            onClick={clicked}
            className={[
                classes['NavigationButton'],
                name ? classes[`NavigationButton--${name}`] : ''
            ].join(' ')}
        >
            {children}
        </button>
    );
};

export default NavigationButton;
