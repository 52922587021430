import React, { Component } from 'react';
//import { FormattedMessage } from 'react-intl';
import { History } from 'history';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import * as actions from '../../store/actions';
//import * as routes from '../../shared/routes';
import * as routes from '../../shared/routes';
import { IFile, IServerOption, ITitle, IUser } from '../../shared/interfaces';
import classes from './styles.module.scss';
import Loader from '../UI/Loader';
import AuthorCard from '../AuthorCard';
import List from '../UI/List';
import {
    getPlaceBySplitValues,
    getSplitValues,
    getStringsFromServerList
} from '../../shared/helpers/utilities';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PersonagesList from '../PersonagesList';
import PublisherCard from '../PublisherCard';

interface IProps {
    currentUser: IUser;
    title: ITitle;
    error?: string;
    onGetTitle(id: number): ITitle;
    history: History;
    genres: Array<IServerOption>;
    onGetGenres(): Array<IServerOption>;
    onResetMyLastTitle(): void;
    waitingForGenres: boolean;
    waitingForFetching: boolean;
    match: {
        params: { id: number };
    };
}

interface IState {
    titleId: number;
    error: string;
    genres: Array<IServerOption>;
}

class TitlePreview extends Component<IProps, IState> {
    state = {
        titleId: 0,
        error: '',
        genres: []
    };

    componentDidMount(): void {
        const {
            match: {
                params: { id }
            }
        } = this.props;

        this.setState(
            {
                titleId: id || 0
            },
            () => {
                this.props.onGetGenres();
            }
        );
    }

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({
                error: this.props.error
            });
        }
        if (this.props.genres !== prevProps.genres && this.props.genres.length) {
            this.setState(
                {
                    genres: this.props.genres
                },
                () => {
                    this.props.onGetTitle(this.state.titleId);
                }
            );
        }
    }

    componentWillUnmount(): void {
        this.props.onResetMyLastTitle();
    }

    render() {
        const { waitingForGenres, waitingForFetching, title } = this.props;

        if (waitingForGenres || waitingForFetching) {
            return <Loader fullContent={true} size={'sm'} theme={'light'} />;
        }

        let author = null;

        if (title) {
            author = title.authors && title.authors.length ? title.authors[0] : null;
        }

        const coverUrl =
            title && typeof title.fileCover !== 'string'
                ? title.fileCover && title.fileCover.url
                : null;

        return (
            title && (
                <div className={classes.TitlePreview}>
                    {title.fileCover && (
                        <div className={classes['TitlePreview-bg-container']}>
                            <div
                                className={classes['TitlePreview-bg']}
                                style={{ backgroundImage: `url(${coverUrl})` }}
                            />
                        </div>
                    )}
                    <aside className={classes['TitlePreview-side']}>
                        <div className={classes['TitlePreview-side-content']}>
                            {title.user && (
                                <PublisherCard
                                    // titleId={title.id}
                                    publisher={title.user}
                                    label={true}
                                />
                            )}
                            <AuthorCard titleId={title.id} author={author} label={true} />
                        </div>
                    </aside>
                    <section className={classes['TitlePreview-main']}>
                        <div className={classes['TitlePreview-main--block']}>
                            <article className={classes['TitlePreview-title']}>
                                <NavLink
                                    className={classes['TitlePreview-edit-btn']}
                                    to={routes.TITLE.replace(':id', title.id.toString()).replace(
                                        ':step',
                                        '1'
                                    )}
                                >
                                    <FormattedMessage id={'general.edit'} />
                                </NavLink>

                                <div className={classes['TitlePreview-header']}>
                                    {title.fileCover && (
                                        <div className={classes['TitlePreview-cover-container']}>
                                            <div
                                                className={classes['TitlePreview-cover']}
                                                style={{ backgroundImage: `url(${coverUrl})` }}
                                            >
                                                {title.format && title.format['title'] && (
                                                    <span
                                                        className={
                                                            classes['TitlePreview-cover-format']
                                                        }
                                                    >
                                                        {title.format['title']}
                                                    </span>
                                                )}
                                                <img
                                                    src={coverUrl}
                                                    alt={title.title}
                                                    className={classes['TitlePreview-cover-img']}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className={classes['TitlePreview-description']}>
                                        <h1 className={classes['TitlePreview-description--title']}>
                                            {title.title}
                                        </h1>
                                        {author && (
                                            <h4
                                                className={
                                                    classes['TitlePreview-description--author']
                                                }
                                            >
                                                di {author.fullName}
                                            </h4>
                                        )}
                                        <div
                                            className={classes['TitlePreview-description--logline']}
                                        >
                                            <div
                                                dangerouslySetInnerHTML={{ __html: title.logline }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article className={classes['TitlePreview-lists']}>
                                {title.primaryGenre && !Array.isArray(title.primaryGenre) && (
                                    <List label={<FormattedMessage id={'label.genre'} />}>
                                        <span>{title.primaryGenre['label']}</span>
                                        {title.secondaryGenre &&
                                            !Array.isArray(title.secondaryGenre) && (
                                                <span>{title.secondaryGenre['label']}</span>
                                            )}
                                    </List>
                                )}
                                {title.features &&
                                    title.features.length > 0 &&
                                    Array.isArray(title.features) && (
                                        <List label={<FormattedMessage id={'label.features'} />}>
                                            {getStringsFromServerList(title.features).map(
                                                (item: string, index: number) => (
                                                    <span key={index}>{item}</span>
                                                )
                                            )}
                                        </List>
                                    )}
                                {title.tones &&
                                    title.tones.length > 0 &&
                                    Array.isArray(title.tones) && (
                                        <List label={<FormattedMessage id={'label.tones'} />}>
                                            {getStringsFromServerList(title.tones).map(
                                                (item: string, index: number) => (
                                                    <span key={index}>{item}</span>
                                                )
                                            )}
                                        </List>
                                    )}
                                {title.ambientWhereType && (
                                    <List
                                        theme={'orange'}
                                        label={<FormattedMessage id={'title.section.location'} />}
                                    >
                                        {title.ambientWhere && title.ambientWhere.length ? (
                                            title.ambientWhere.map(
                                                (item: string, index: number) => (
                                                    <span key={index}>
                                                        {getPlaceBySplitValues(
                                                            getSplitValues(item, ', ')
                                                        )}
                                                    </span>
                                                )
                                            )
                                        ) : (
                                            <span>{title.ambientWhereType}</span>
                                        )}
                                    </List>
                                )}
                                {title.ambientWhenType && (
                                    <List
                                        theme={'red'}
                                        label={<FormattedMessage id={'label.period'} />}
                                    >
                                        {title.ambientWhenType === 'presente' && (
                                            <span>
                                                <FormattedMessage id={'label.date.present'} />
                                            </span>
                                        )}
                                        {title.ambientWhenType === 'presente-alternativo' && (
                                            <span>
                                                <FormattedMessage
                                                    id={'label.date.presentAlternate'}
                                                />
                                            </span>
                                        )}
                                        {title.ambientWhenType === 'passato' && (
                                            <span>
                                                <FormattedMessage id={'label.date.past'} />
                                            </span>
                                        )}
                                        {title.ambientWhenType === 'futuro' && (
                                            <span>
                                                <FormattedMessage id={'label.date.future'} />
                                            </span>
                                        )}
                                        {title.ambientWhenType === 'non definito' && (
                                            <span>
                                                <FormattedMessage id={'label.date.undefined'} />
                                            </span>
                                        )}
                                        {title.ambientWhenType === 'data specifica' && (
                                            <span>
                                                {moment(title.ambientWhenStartAt).format(
                                                    'DD/MM/YYYY'
                                                )}
                                            </span>
                                        )}
                                        {title.ambientWhenType === 'range di mesi-anni' && (
                                            <>
                                                <span>
                                                    {moment(title.ambientWhenStartAt).format(
                                                        'YYYY'
                                                    )}
                                                </span>
                                                <span>
                                                    {moment(title.ambientWhenEndAt).format('YYYY')}
                                                </span>
                                            </>
                                        )}
                                    </List>
                                )}
                            </article>
                            <article className={classes['TitlePreview-extra']}>
                                <label className={classes['TitlePreview-label']}>
                                    <FormattedMessage id="label.synopsis" />
                                </label>
                                <div className={classes['TitlePreview--synopsis']}>
                                    <div dangerouslySetInnerHTML={{ __html: title.synopsis }} />
                                </div>
                            </article>
                        </div>
                        <div
                            className={[
                                classes['TitlePreview-main--block'],
                                classes['TitlePreview-main--block-transparent']
                            ].join(' ')}
                        >
                            <PersonagesList personages={title.personages} />
                        </div>
                    </section>
                    <section className={classes['TitlePreview-side']}>
                        <div className={classes['TitlePreview-side-content']}>
                            {title.fileDocuments.length > 0 && (
                                <div className={classes['TitlePreview-documents']}>
                                    {title.fileDocuments.map((file: IFile, index: number) => {
                                        return (
                                            <a
                                                key={index}
                                                rel="noopener noreferrer"
                                                target={'_blank'}
                                                href={file.url}
                                                className={classes['TitlePreview-btn--download']}
                                                download
                                            >
                                                <FormattedMessage id="label.download.book" />
                                            </a>
                                        );
                                    })}
                                </div>
                            )}
                            <div className={classes['TitlePreview-box']}>
                                <div className={classes['TitlePreview-box-header']}>
                                    <FormattedMessage id={'title.section.salesData'} />
                                </div>
                                <div className={classes['TitlePreview-box-content']}>
                                    {title.publicTargets && title.publicTargets.length > 0 && (
                                        <List
                                            theme="grey"
                                            label={<FormattedMessage id={'label.publicTargets'} />}
                                        >
                                            {getStringsFromServerList(title.publicTargets).map(
                                                (item: string, index: number) => (
                                                    <span key={index}>{item}</span>
                                                )
                                            )}
                                        </List>
                                    )}
                                    {title.reprints !== null && title.reprints > 0 && (
                                        <List
                                            theme="grey"
                                            label={<FormattedMessage id={'label.reprints'} />}
                                        >
                                            <span>{`${title.reprints}+`}</span>
                                        </List>
                                    )}
                                    {title.translations && title.translations.length > 0 && (
                                        <List
                                            theme="grey"
                                            label={<FormattedMessage id={'label.translations'} />}
                                        >
                                            {title.translations.map((item, index: number) => (
                                                <span key={index}>{item['label']}</span>
                                            ))}
                                        </List>
                                    )}
                                    {title.awards && title.awards.length > 0 && (
                                        <List
                                            theme="grey"
                                            label={<FormattedMessage id={'label.awards'} />}
                                        >
                                            {title.awards.map((item, index: number) => (
                                                <span key={index}>{item['label']}</span>
                                            ))}
                                        </List>
                                    )}
                                </div>
                            </div>

                            {title.reviews.length > 0 && (
                                <div className={classes['TitlePreview-box']}>
                                    <div className={classes['TitlePreview-box-header']}>
                                        <FormattedMessage id={'title.section.reviews'} />
                                    </div>
                                    <div
                                        className={[
                                            classes['TitlePreview-box-content'],
                                            classes['TitlePreview-box-content--reviews']
                                        ].join(' ')}
                                    >
                                        {title.reviews.length > 0 &&
                                            title.reviews.map((review, index: number) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={classes['TitlePreview-review']}
                                                    >
                                                        <a
                                                            href={review['link']}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {review['source']}
                                                        </a>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                </div>
            )
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.userState.user,
        title: state.titleState.title,
        waitingForFetching: state.titleState.isFetching,
        error: state.titleState.error,
        genres: state.genreState.items,
        waitingForGenres: state.genreState.isFetching
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetTitle: (id: number) => dispatch(actions.fetchTitle(id)),
        onGetGenres: () => dispatch(actions.fetchGenres()),
        onResetMyLastTitle: () => dispatch(actions.resetMyLastTitle())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TitlePreview));
