import React, { Component } from 'react';
import { History } from 'history';
import * as routes from '../../shared/routes';
import classes from './styles.module.scss';
import Text from '../../components/UI/Text';
import FeedbackMessage from '../../components/UI/FeedbackMessage';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface IProps {
    history: History;
}

class ErrorPage extends Component<IProps> {
    render() {
        return (
            <main className={classes.ErrorPage}>
                <div className={classes['ErrorPage-header']}>
                    <Text
                        tag={'h2'}
                        marginBottom={30}
                        weight={400}
                        transform={'uppercase'}
                        spacing={true}
                    >
                        <span>{'Ops'}</span>
                    </Text>
                </div>

                <div className={classes['ErrorPage-content']}>
                    <FeedbackMessage type={'error'} visible={true}>
                        <FormattedMessage id="error.generic" />
                    </FeedbackMessage>
                    <NavLink className={classes['ErrorPage-link']} to={routes.PUBLISHER}>
                        <FormattedMessage id="general.goto.profile" />
                    </NavLink>
                </div>
            </main>
        );
    }
}

export default ErrorPage;
