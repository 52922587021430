import React, { Component } from 'react';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import { ITitle, IUser } from '../../shared/interfaces';
import Sidebar from '../../containers/Sidebar';
import UserCard from '../../components/UserCard';
import MainSection from '../../containers/MainSection';
import AuthWrapper from '../../containers/AuthWrapper';
import TopBar from '../../containers/TopBar';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import TitlesList from '../../components/TitlesList';
import UserForm from '../../components/UserForm';
import Dialog from '../../components/UI/Dialog';
import Button from '../../components/UI/Button';
import AuthorsList from '../../components/AuthorsList';
import * as actions from '../../store/actions';
import Loader from '../../components/UI/Loader';
import * as routes from '../../shared/routes';

interface IProps {
    history: History;
    currentUser: IUser;
    titles: Array<ITitle>;
    waitingForTitles?: boolean;
    error?: string;
    onGetMyTitles(): Array<ITitle>;
}

interface IState {
    showEditDialog: boolean;
    showAuthors: boolean;
    showTitles: boolean;
    currentLat: number;
    currentLng: number;
    showMap: boolean;
    showCompleteProfile: boolean;
}

class Publisher extends Component<IProps & RouteComponentProps & any, IState> {
    state = {
        showCompleteProfile: false,
        showEditDialog: false,
        showTitles: true,
        showAuthors: false,
        currentLat: 0,
        currentLng: 0,
        showMap: true
    };

    componentDidMount(): void {
        this.props.onGetMyTitles();

        this.setMapCoordinates();
    }

    toggleListVisibility = () => {
        const showTitles = this.state.showTitles;
        const showAuthors = this.state.showAuthors;

        this.setState({
            showAuthors: !showAuthors,
            showTitles: !showTitles
        });
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.currentUser !== prevProps.currentUser && this.props.currentUser) {
            this.setMapCoordinates();

            this.setState({
                showCompleteProfile:
                    !this.props.currentUser.biography.trim().length ||
                    !this.props.currentUser.fileCodeImage
            });
        }
    }

    setMapCoordinates = () => {
        if (this.props.currentUser) {
            const {
                data: { addressLat, addressLng }
            } = this.props.currentUser;

            this.setState(
                {
                    currentLat: addressLat,
                    currentLng: addressLng,
                    showMap: false
                },
                () => {
                    setTimeout(() => {
                        this.setState({
                            showMap: true
                        });
                    }, 10);
                }
            );
        }
    };

    render() {
        const { currentUser, titles, waitingForTitles } = this.props;
        const { showEditDialog, showAuthors, showTitles, showCompleteProfile } = this.state;

        if (waitingForTitles) {
            return <Loader theme={'light'} fullContent={true} size={'sm'} />;
        }

        return (
            <AuthWrapper>
                <main className={classes.Publisher}>
                    <TopBar>
                        <div className={classes['Publisher-header']}>
                            <Button
                                type={showTitles ? 'topbar-active' : 'topbar'}
                                clicked={this.toggleListVisibility}
                            >
                                <FormattedMessage id={'general.titles'} />
                            </Button>
                            <Button
                                type={showAuthors ? 'topbar-active' : 'topbar'}
                                clicked={this.toggleListVisibility}
                            >
                                <FormattedMessage id={'general.authors'} />
                            </Button>
                        </div>
                    </TopBar>
                    <Sidebar>
                        <div className={classes['Publisher-sticky']}>
                            <UserCard
                                onEditClicked={() => this.setState({ showEditDialog: true })}
                                currentUser={currentUser}
                            />

                            {currentUser && currentUser.biography && (
                                <div className={classes['Publisher-data-block']}>
                                    <h4 className={classes['Publisher-data-block-title']}>
                                        <FormattedMessage id={'label.informations'} />
                                    </h4>
                                    <div className={classes['Publisher-data-block-content']}>
                                        {currentUser.biography}
                                    </div>
                                </div>
                            )}

                            {currentUser && currentUser.data.website && (
                                <div className={classes['Publisher-data-block']}>
                                    <h4 className={classes['Publisher-data-block-title']}>
                                        <FormattedMessage id={'placeholder.website'} />
                                    </h4>
                                    <div className={classes['Publisher-data-block-content']}>
                                        <a
                                            href={currentUser.data.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {currentUser.data.website}
                                        </a>
                                    </div>
                                </div>
                            )}

                            {currentUser && currentUser.city && (
                                <div className={classes['Publisher-data-block']}>
                                    <h4 className={classes['Publisher-data-block-title']}>
                                        <FormattedMessage id={'label.location'} />
                                    </h4>
                                    <div className={classes['Publisher-data-block-content']}>
                                        {currentUser.city}
                                        {currentUser.country && ', ' + currentUser.country}
                                    </div>
                                </div>
                            )}

                            {/* {showMap && (
                                <div className={classes['Publisher-map-container']}>
                                    <MapViewer
                                        googleMapURL={constants.GOOGLE_MAPS_URL}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={
                                            <div className={classes['Publisher-map']} />
                                        }
                                        mapElement={<div style={{ height: `100%` }} />}
                                        lat={currentLat || 0}
                                        long={currentLng || 0}
                                    />
                                </div>
                            )} */}
                        </div>
                        <Dialog
                            showClose={true}
                            visible={showEditDialog}
                            onDialogCloseClicked={() =>
                                this.setState({ showEditDialog: false, showMap: true })
                            }
                        >
                            <UserForm
                                onClose={() =>
                                    this.setState({ showEditDialog: false, showMap: true })
                                }
                            />
                        </Dialog>
                    </Sidebar>
                    <MainSection>
                        <div className={classes['Publisher-boxes']}>
                            {currentUser && !currentUser.visible && (
                                <div
                                    className={[
                                        classes['Publisher-box'],
                                        classes['Publisher-box--full']
                                    ].join(' ')}
                                >
                                    <p>
                                        <FormattedMessage id={'general.activationWait'} />
                                    </p>
                                </div>
                            )}
                            {showCompleteProfile && (
                                <button
                                    className={classes['Publisher-box']}
                                    onClick={() => this.setState({ showEditDialog: true })}
                                >
                                    <FormattedMessage id={'general.completeProfile'} />
                                    <div className={classes['Publisher-box-action']}>
                                        <div
                                            className={[
                                                classes['Publisher-box-btn'],
                                                classes['Publisher-box-btn--edit']
                                            ].join(' ')}
                                        >
                                            <span>
                                                <FormattedMessage id={'general.edit'} />
                                            </span>
                                        </div>
                                    </div>
                                </button>
                            )}
                            {titles.length === 0 && (
                                <NavLink
                                    className={classes['Publisher-box']}
                                    to={routes.TITLE.replace(':id', '0').replace(':step', '1')}
                                >
                                    <FormattedMessage id={'general.uploadTitle'} />

                                    <div className={classes['Publisher-box-action']}>
                                        <div
                                            className={[
                                                classes['Publisher-box-btn'],
                                                classes['Publisher-box-btn--add']
                                            ].join(' ')}
                                        >
                                            <span>
                                                <FormattedMessage id={'title.new'} />
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            )}
                        </div>
                        <div className={classes['Publisher-content']}>
                            {showTitles && titles.length ? (
                                <TitlesList
                                    titles={titles}
                                    onlyPublished={true}
                                    withEdit={true}
                                    hasNewButton={true}
                                />
                            ) : (
                                <AuthorsList />
                            )}
                        </div>
                        {showTitles && titles.filter((title: ITitle) => title.isDraft).length > 0 && (
                            <div className={classes['Publisher-stripe']}>
                                <h3>
                                    <FormattedMessage id={'general.continuePublishing'} />
                                </h3>
                                <TitlesList
                                    size={'sm'}
                                    titles={titles}
                                    onlyDraft={true}
                                    withEdit={true}
                                />
                            </div>
                        )}
                    </MainSection>
                </main>
            </AuthWrapper>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.userState.user,
        titles: state.titleState.myList,
        waitingForTitles: state.titleState.isFetching,
        error: state.titleState.error
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetMyTitles: () => dispatch(actions.fetchMyTitles())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Publisher));
