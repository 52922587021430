import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import {
    IChecker,
    IInput,
    IOption,
    IPersonage,
    ISelector,
    IServerOption,
    IStringsSelector,
    ISwitch,
    ITag,
    ITextArea,
    ITitle
} from '../../shared/interfaces';
import classes from './styles.module.scss';
import Input from '../Field/Input';
import TextAreaEditor from '../Field/TextAreaEditor';
import Button from '../UI/Button';
import Example from '../UI/Example';
import constants from '../../shared/constants';
import mainClasses from '../TitleForm/styles.module.scss';
import Selector from '../Field/Selector';
import Switch from '../Field/Switch';
import StringsSelector from '../Field/StringsSelector';
import Loader from '../UI/Loader';
import { checkPersonageForm } from '../../shared/helpers/checks';

interface IProps {
    titleId: number;
    personage?: IPersonage;
    personageId: number;
    waitingForPersonagesChanging: boolean;
    onCreatePersonage(personageId: number, data: IPersonage): ITitle;
    onUpdatePersonage(titleId: number, data: IPersonage): IPersonage;
    onRemovePersonage(id: number): void;
    showRemove?: boolean;
}

interface ISelectors {
    genre: ISelector;
    age: ISelector;
    adjectives: IStringsSelector;
}

interface IControls {
    fullName: IInput;
    nickname: IInput;
    description: ITextArea;
    quote: ITextArea;
    firstAge: IInput;
    secondAge: IInput;
}

interface IState {
    ageType: string;
    controls: IControls;
    selectors: ISelectors;
    genre: string;
    checkboxes: {
        protagonist: ISwitch;
    };
    showCheckerFeedback: boolean;
    fieldsCheckerList: Array<IChecker>;
}

class Personages extends Component<IProps, IState> {
    state = {
        checkboxes: {
            protagonist: {
                name: 'protagonist',
                labelId: 'registration.protagonist',
                checked: false
            }
        },
        genre: '',
        ageType: 'range',
        controls: {
            fullName: {
                name: 'fullName',
                value: '',
                type: 'text',
                placeholderId: 'placeholder.fullName',
                labelId: 'label.fullName',
                required: true
            },
            nickname: {
                name: 'nickname',
                value: '',
                type: 'text',
                placeholderId: 'placeholder.nickname',
                labelId: 'label.nickname',
                instructionIds: ['instruction.nickname']
            },
            description: {
                isPlainText: true,
                toolbarHidden: true,
                autosize: true,
                name: 'description',
                value: '',
                type: 'textarea',
                placeholderId: 'placeholder.personageDescription',
                labelId: 'label.personageDescription',
                required: true,
                suggestedLength: 700,
                maxLength: 1000,
                instructionIds: ['instruction.personageDesc'],
                example: ['example.character.1', 'example.character.2'],
                exampleAuthor: ['example.author.1', 'example.author.2']
            },
            quote: {
                isPlainText: true,
                toolbarHidden: true,
                autosize: true,
                name: 'quote',
                value: '',
                type: 'textarea',
                placeholderId: 'placeholder.personageQuote',
                labelId: 'label.personageQuote',
                maxLength: 200,
                instructionIds: ['instruction.quote']
            },
            firstAge: {
                name: 'firstAge',
                value: '',
                type: 'number',
                placeholderId: 'placeholder.firstAge'
            },
            secondAge: {
                name: 'secondAge',
                value: '',
                type: 'number',
                placeholderId: 'placeholder.secondAge'
            }
        },
        selectors: {
            genre: {
                labelId: 'label.genre',
                name: 'genre',
                options: constants.GENRE_OPTIONS,
                minSelected: 1
            },
            age: {
                labelId: 'label.age',
                name: 'age',
                options: constants.AGE_OPTIONS,
                minSelected: 1,
                instructionIds: ['instruction.age']
            },
            adjectives: {
                labelId: 'label.adjectives',
                name: 'adjectives',
                options: [],
                selected: [],
                minSelected: 1,
                placeholderId: 'placeholder.adjectives',
                instructionIds: ['instruction.adjectives']
            }
        },
        showCheckerFeedback: false,
        fieldsCheckerList: []
    };

    componentDidMount(): void {
        this.initializeData();
    }

    initializeData = (reset: boolean = false) => {
        const { personage } = this.props;

        //console.log(personage)

        if (personage && !reset) {
            this.setTextValue('fullName', personage.fullName);
            this.setTextValue('description', personage.description);
            this.setTextValue('quote', personage.quote);
            this.setTextValue('nickname', personage.nickname);
            this.setTextValue('firstAge', personage.firstAge);
            this.setTextValue('secondAge', personage.secondAge);
            this.handleCheckboxChanged('protagonist', personage.protagonist, true);

            if (personage.genre) {
                this.setSelectorValue('genre', personage.genre);
                this.setState({
                    genre: personage.genre
                });
            }

            const ageType = personage.firstAge !== personage.secondAge ? 'range' : 'eta-precisa';
            this.setSelectorValue('age', ageType);

            this.initStringsSelectorValue('adjectives', personage.adjectives);

            this.setState({
                ageType
            });
        } else {
            this.setTextValue('fullName', '');
            this.setTextValue('description', '');
            this.setTextValue('quote', '');
            this.setTextValue('nickname', '');
            this.setTextValue('firstAge', '');
            this.setTextValue('secondAge', '');
        }
    };

    setTextValue = (name: string, value: string | number) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value;

        this.setState({
            controls
        });
    };

    setSelectorValue = (name: string, value: string) => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        selectors[name].options = selectors[name].options.map((item: IOption) => {
            return {
                ...item,
                selected: item.code === value
            };
        });

        this.setState({
            selectors
        });
    };

    initStringsSelectorValue = (name: string, values: Array<IServerOption>) => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        selectors[name].selected = values.map((item: IServerOption) => {
            return {
                id: item.id.toString(),
                text: item.label
            };
        });

        this.setState({
            selectors
        });
    };

    setStringsSelectorValue = (name: string, values: Array<ITag>) => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        selectors[name].selected = values;

        this.setState({
            selectors
        });
    };

    handleInputChanged = (name: string, value: string) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value;

        this.setState({
            controls
        });
    };

    handleSelectorClicked = (name: string, index: number, selected: boolean): void => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        selectors[name].options = selectors[name].options.map((item: IOption) => {
            return {
                ...item,
                selected: false
            };
        });

        selectors[name].options[index].selected = selected;

        if (name === 'age') {
            const ageType = selectors[name].options[index].code;

            this.setState({
                ageType
            });
        }

        if (name === 'genre') {
            const genre = selectors[name].options[index].code;

            this.setState({
                genre
            });
        }

        this.setState({
            selectors
        });
    };

    handleCheckboxChanged = (name: string, checked: boolean, initialize: boolean = false) => {
        let checkboxes: any = { ...this.state.checkboxes };

        checkboxes[name].checked = checked;

        this.setState({
            checkboxes
        });
    };

    handleSubmitClicked = () => {
        this.setState({
            showCheckerFeedback: false
        });

        const {
            checkboxes: { protagonist },
            controls: { fullName, description, nickname, quote, firstAge, secondAge },
            selectors: { adjectives },
            genre,
            ageType
        } = this.state;

        const { titleId, personageId } = this.props;

        const model: IPersonage = {
            id: personageId,
            protagonist: protagonist.checked,
            fullName: fullName.value,
            genre,
            nickname: nickname.value,
            firstAge: firstAge.value ? +firstAge.value : 0,
            secondAge: ageType === 'range' && secondAge.value ? +secondAge.value : +firstAge.value,
            description: description.value,
            quote: quote.value,
            adjectives: adjectives.selected.map((item: ITag) => item.text)
        };

        const fieldsCheckerList = checkPersonageForm(model);

        this.setState(
            {
                fieldsCheckerList
            },
            () => {
                if (fieldsCheckerList.length > 0) {
                    this.setState({
                        showCheckerFeedback: true
                    });
                } else {
                    if (personageId > 0) {
                        this.props.onUpdatePersonage(personageId, model);
                    } else {
                        this.props.onCreatePersonage(titleId, model);
                    }
                }
            }
        );
    };

    render() {
        const {
            controls,
            selectors,
            ageType,
            checkboxes,
            showCheckerFeedback,
            fieldsCheckerList
        } = this.state;
        const {
            waitingForPersonagesChanging,
            titleId,
            showRemove,
            onRemovePersonage,
            personageId
        } = this.props;

        if (!titleId) {
            return null;
        }

        const inputElementsTpl = Object.values(controls).map((item: IInput, index: number) => {
            return item.type === 'textarea' ? (
                <div key={index} className={classes['PersonageForm-field']}>
                    {item.example && item.example.length > 0 && (
                        <Example
                            reference={item.labelId}
                            textIds={item.example}
                            authors={item.exampleAuthor}
                        />
                    )}
                    <TextAreaEditor
                        name={item.name}
                        isPlainText={item.isPlainText}
                        toolbarHidden={item.toolbarHidden}
                        value={item.value}
                        autosize={item.autosize}
                        label={item.labelId ? <FormattedMessage id={item.labelId} /> : ''}
                        placeholderId={item.placeholderId}
                        required={item.required}
                        suggestedLength={item.suggestedLength ? item.suggestedLength : 0}
                        maxLength={item.maxLength}
                        instructionIds={item.instructionIds}
                        onChange={this.handleInputChanged}
                    />
                </div>
            ) : (
                <div
                    className={[
                        mainClasses['TitleForm-field'],
                        item.instructionIds ? '' : mainClasses['TitleForm-field--small']
                    ].join(' ')}
                >
                    <Input
                        label={item.labelId ? <FormattedMessage id={item.labelId} /> : ''}
                        required={item.required}
                        type={item.type}
                        name={item.name}
                        placeholderId={item.placeholderId}
                        value={item.value}
                        maxLength={item.maxLength}
                        instructionIds={item.instructionIds}
                        changed={this.handleInputChanged}
                    />
                </div>
            );
        });

        return (
            <div className={[classes.PersonageForm].join(' ')}>
                <div className={classes['PersonageForm-content']}>
                    <div
                        className={[
                            mainClasses['TitleForm-field'],
                            mainClasses['TitleForm-field--small']
                        ].join(' ')}
                    >
                        <Switch
                            name={checkboxes.protagonist.name}
                            changed={this.handleCheckboxChanged}
                            label={<FormattedMessage id="label.protagonist" />}
                            checked={checkboxes.protagonist.checked}
                        />
                    </div>
                    {inputElementsTpl[0]}
                    <div
                        className={[
                            mainClasses['TitleForm-field'],
                            mainClasses['TitleForm-field--small']
                        ].join(' ')}
                    >
                        <Selector
                            label={
                                selectors.genre.labelId ? (
                                    <FormattedMessage id={selectors.genre.labelId} />
                                ) : (
                                    ''
                                )
                            }
                            name={selectors.genre.name}
                            clicked={this.handleSelectorClicked}
                            options={selectors.genre.options}
                        />
                    </div>
                    {inputElementsTpl[1]}
                    <div className={mainClasses['TitleForm-field']}>
                        <Selector
                            label={
                                selectors.age.labelId ? (
                                    <FormattedMessage id={selectors.age.labelId} />
                                ) : (
                                    ''
                                )
                            }
                            name={selectors.age.name}
                            clicked={this.handleSelectorClicked}
                            options={selectors.age.options}
                        />
                        <div
                            className={[
                                mainClasses['TitleForm-range']
                            ].join(' ')}
                        >
                            {ageType === 'eta-precisa' ? (
                                inputElementsTpl[4]
                            ) : (
                                <>
                                    {inputElementsTpl[4]}
                                    {inputElementsTpl[5]}
                                </>
                            )}
                        </div>
                    </div>
                    <div className={mainClasses['TitleForm-field']}>
                        <StringsSelector
                            changed={this.setStringsSelectorValue}
                            placeholderId={selectors.adjectives.placeholderId}
                            selected={selectors.adjectives.selected}
                            instructionIds={selectors.adjectives.instructionIds}
                            label={
                                selectors.adjectives.labelId ? (
                                    <FormattedMessage id={selectors.adjectives.labelId} />
                                ) : (
                                    ''
                                )
                            }
                            name={selectors.adjectives.name}
                        />
                    </div>
                    {inputElementsTpl[2]}
                    {inputElementsTpl[3]}
                </div>
                {showCheckerFeedback && (
                    <div className={classes['PersonageForm-feedback']}>
                        <h5>
                            <FormattedMessage id={'title.checker.personage'} />
                        </h5>
                        <ul>
                            {fieldsCheckerList.map((item: IChecker, index: number) => (
                                <li key={index}>
                                    <FormattedMessage id={item.field} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className={classes['PersonageForm-footer']}>
                    {showRemove && (
                        <Button
                            disabled={waitingForPersonagesChanging}
                            type={'remove'}
                            clicked={() => onRemovePersonage(personageId)}
                        >
                            <FormattedMessage id={'general.remove'} />
                        </Button>
                    )}
                    {waitingForPersonagesChanging && <Loader size={'xs'} />}
                    <Button
                        pullRight={true}
                        disabled={waitingForPersonagesChanging}
                        type={'save'}
                        clicked={this.handleSubmitClicked}
                    >
                        <FormattedMessage id={'general.save'} />
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentPersonagesList: state.titleState.personagesList,
        waitingForPersonagesChanging: state.titleState.isUpdatingPersonages
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreatePersonage: (titleId: number, data: IPersonage) =>
            dispatch(actions.createPersonageForTitle(titleId, data)),
        onUpdatePersonage: (personageId: number, data: IPersonage) =>
            dispatch(actions.updatePersonage(personageId, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Personages);
