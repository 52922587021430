import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as routes from '../../shared/routes';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { ITitle } from '../../shared/interfaces';
import classes from './styles.module.scss';
import BoxButton from '../UI/BoxButton';
import TitleCard from '../TitleCard';
import Loader from '../UI/Loader';
import Dialog from '../UI/Dialog';
import Button from '../UI/Button';
import Text from '../UI/Text';
import FeedbackMessage from '../UI/FeedbackMessage';

// a little function to help us with reordering the result
const reorder = (list: Array<ITitle>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    //padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    //background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#ee4c7c' : 'transparent',
    display: 'flex',
    flexWrap: 'wrap',
    //padding: grid,
    overflow: 'hidden'
});

interface IProps {
    hasNewButton?: boolean;
    titles: Array<ITitle>;
    loading?: boolean;
    onDeleteTitle(id: number): boolean;
    withEdit?: boolean;
    onlyDraft?: boolean;
    onlyPublished?: boolean;
    size?: 'sm' | 'md' | 'lg';
    withReorder?: boolean;
    onReorderConfirmed?: (titles: Array<ITitle>) => void;
}

interface IState {
    error: string;
    showConfirmDialog: boolean;
    idToDelete: number;
    titles: Array<ITitle>;
}

class TitlesList extends Component<IProps, IState> {
    state = {
        titles: [],
        error: '',
        showConfirmDialog: false,
        idToDelete: -1
    };

    handleAddTitle = () => {
        console.log('Add title');
    };

    handleShowDialogForRemovingTitle = (id: number) => {
        this.setState({
            error: '',
            showConfirmDialog: true,
            idToDelete: id
        });
    };

    handleRemoveTitle = () => {
        this.props.onDeleteTitle(this.state.idToDelete);
    };

    componentDidMount(): void {
        window.scrollTo(0, 0);

        this.setState({
            titles: this.props.titles
        });
    }

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.titles !== prevProps.titles) {
            this.setState({
                titles: this.props.titles
            });
        }

        if (this.props.titles !== prevProps.titles) {
            this.setState({
                showConfirmDialog: false,
                idToDelete: -1
            });
        }
    }

    onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(this.state.titles, result.source.index, result.destination.index);

        this.setState(
            {
                titles: items
            },
            () => {
                this.confirm();
            }
        );
    };

    confirm = () => {
        this.props.onReorderConfirmed(this.state.titles);
    };

    render() {
        const {
            hasNewButton,
            loading,
            withEdit,
            onlyDraft,
            onlyPublished,
            size,
            withReorder
        } = this.props;
        const { showConfirmDialog, error, titles } = this.state;

        if (loading) {
            return <Loader theme={'light'} fullContent={true} size={'sm'} />;
        }

        let currentTitles = titles;

        if (onlyDraft) {
            currentTitles = titles.filter((item: ITitle) => item.isDraft);
        }

        if (onlyPublished) {
            currentTitles = titles.filter((item: ITitle) => !item.isDraft);
        }

        let titlesTpl = null;

        if (withReorder) {
            titlesTpl = currentTitles.map((title: ITitle, index: number) => {
                const fileCover: any = title.fileCover;

                return (
                    <Draggable key={index} draggableId={index.toString()} index={index}>
                        {(provided, snapshot) => (
                            <div
                                className={classes['Reorder-item']}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                )}
                            >
                                <TitleCard
                                    size={size || 'md'}
                                    hasEdit={withEdit || false}
                                    coverUrl={fileCover ? fileCover.childrens[1].url : null}
                                    onRemoveClicked={this.handleShowDialogForRemovingTitle}
                                    key={index}
                                    title={title}
                                />
                            </div>
                        )}
                    </Draggable>
                );
            });
        } else {
            titlesTpl = currentTitles.map((title: ITitle, index: number) => {
                const fileCover: any = title.fileCover;

                return (
                    <TitleCard
                        size={size || 'md'}
                        hasEdit={withEdit || false}
                        coverUrl={fileCover ? fileCover.childrens[1].url : null}
                        onRemoveClicked={this.handleShowDialogForRemovingTitle}
                        key={index}
                        title={title}
                    />
                );
            });
        }

        return (
            <div
                className={[classes.TitlesList, size ? classes[`TitleList--${size}`] : ''].join(
                    ' '
                )}
            >
                {error && (
                    <div className={classes['TitlesList-feedback']}>
                        <FeedbackMessage visible={true} type={'error'} size={'md'}>
                            {error}
                        </FeedbackMessage>
                    </div>
                )}
                <div className={classes['TitlesList-content']}>
                    {hasNewButton && (
                        <BoxButton
                            to={routes.TITLE.replace(':id', '0').replace(':step', '1')}
                            type="title"
                        >
                            <FormattedMessage id={'title.new'} />
                        </BoxButton>
                    )}
                    {withReorder ? (
                        <div className={classes['TitleList-reorder']}>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                            {...provided.droppableProps}
                                        >
                                            {titlesTpl}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    ) : (
                        titlesTpl
                    )}
                </div>
                <Dialog
                    visible={showConfirmDialog}
                    onDialogCloseClicked={() =>
                        this.setState({ idToDelete: -1, showConfirmDialog: false })
                    }
                >
                    <Text marginBottom={40}>
                        <FormattedMessage id={'title.remove.message'} />
                    </Text>
                    <div className={classes['TitlesList-dialogButtons']}>
                        <Button
                            type={'secondary'}
                            clicked={() =>
                                this.setState({ idToDelete: -1, showConfirmDialog: false })
                            }
                        >
                            <FormattedMessage id={'general.undo'} />
                        </Button>
                        <Button clicked={this.handleRemoveTitle}>
                            <FormattedMessage id={'general.confirm'} />
                        </Button>
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onDeleteTitle: (id: number) => dispatch(actions.deleteTitle(id))
    };
};

export default connect(null, mapDispatchToProps)(TitlesList);
