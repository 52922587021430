import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import * as routes from '../../shared/routes';
import { FormattedMessage } from 'react-intl';
import classes from './styles.module.scss';
import { ITitle } from '../../shared/interfaces';
import Text from '../UI/Text';
import { getPlaceBySplitValues, getSplitValues } from '../../shared/helpers/utilities';

interface IProps {
    title: ITitle;
    size?: 'sm' | 'md' | 'lg';
    disabled?: boolean;
    onRemoveClicked?: (id: number) => void;
    hasEdit?: boolean;
    coverUrl?: string;
    isFlat?: boolean;
}

class TitleCard extends Component<IProps, {}> {
    render() {
        const { title, coverUrl, disabled, onRemoveClicked, hasEdit, size, isFlat } = this.props;

        if (!title) return null;

        const buttonClasses = [
            classes.TitleCard,
            disabled ? classes['TitleCard--disabled'] : '',
            title && title.isDraft ? classes['is-draft'] : '',
            size ? classes[`TitleCard--${size}`] : '',
            isFlat ? classes[`TitleCard--flat`] : ''
        ].join(' ');

        const editTitleRoute = routes.TITLE.replace(':id', title.id.toString()).replace(
            ':step',
            title.lastStep.toString()
        );
        const previewRoute = routes.PREVIEW.replace(':id', title.id.toString());

        const coverBackground = {
            backgroundImage: coverUrl ? `url(${coverUrl})` : 'none'
        };

        return (
            <article style={coverBackground} className={buttonClasses}>
                {!isFlat && (
                    <div className={classes['TitleCard-actions']}>
                        <div className={classes['TitleCard-actions--left']}>
                            {hasEdit && (
                                <NavLink
                                    className={[
                                        classes['TitleCard-btn'],
                                        classes['TitleCard-btn--edit']
                                    ].join(' ')}
                                    to={editTitleRoute}
                                >
                                    <span>
                                        <FormattedMessage id={'general.edit'} />
                                    </span>
                                </NavLink>
                            )}
                            <NavLink
                                className={[
                                    classes['TitleCard-btn'],
                                    classes['TitleCard-btn--preview']
                                ].join(' ')}
                                to={previewRoute}
                            >
                                <span>
                                    <FormattedMessage id={'general.preview'} />
                                </span>
                            </NavLink>
                        </div>
                        {onRemoveClicked && (
                            <button
                                className={classes['TitleCard-remove']}
                                onClick={() => onRemoveClicked(title.id ? title.id : -1)}
                            >
                                <span>
                                    <FormattedMessage id={'general.remove'} />
                                </span>
                            </button>
                        )}
                    </div>
                )}
                {title.isDraft && <div className={classes['TitleCard-draft']}>{'Bozza'}</div>}
                <div className={classes['TitleCard-bg']} />
                <div className={classes['TitleCard-content']}>
                    <h2 className={classes['TitleCard-title']}>
                        <NavLink to={previewRoute}>
                            <Text size={'md'} color="#fff" weight={600} marginBottom={5}>
                                {title.title}
                            </Text>
                        </NavLink>
                    </h2>
                    {title && title.authors.length > 0 && (
                        <div className={classes['TitleCard-author']}>
                            <Text size={'sm'} weight={400}>
                                <span>
                                    <FormattedMessage id={'general.authorOf'} />
                                </span>
                                <span>{title.authors[0].fullName}</span>
                            </Text>
                        </div>
                    )}
                    {!title.isDraft && (
                        <div className={classes['TitleCard-footer']}>
                            {title.primaryGenre && (
                                <span className={classes['TitleCard-genre']}>
                                    {title.primaryGenre['label']}
                                </span>
                            )}
                            {title.secondaryGenre && (
                                <span className={classes['TitleCard-genre']}>
                                    {title.secondaryGenre['label']}
                                </span>
                            )}
                            <span className={classes['TitleCard-location']}>
                                {title.ambientWhere && title.ambientWhere.length ? (
                                    <span>
                                        {getPlaceBySplitValues(
                                            getSplitValues(title.ambientWhere[0], ', ')
                                        )}
                                    </span>
                                ) : 
                                    title.ambientWhereType !== 'non luogo' && 
                                        <span>{title.ambientWhereType}</span>
                                }
                            </span>
                        </div>
                    )}
                </div>
            </article>
        );
    }
}

export default TitleCard;
