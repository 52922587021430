import React, {Component} from 'react';
import classes from './styles.module.scss';
import {FormattedMessage} from "react-intl";
import {NavLink} from 'react-router-dom';
import Button from '../UI/Button';

interface IProps {
    show: boolean;
    checkCookieConsent: any;
}

class CookieBanner extends Component<IProps> {

    handleClick = () => {
        localStorage.setItem('cookieConsent', 'accept');
        this.props.checkCookieConsent();
    }

    render () {
        const { show } = this.props;
        
        const cookieBanner = !show ?
            <div className={classes.CookieBanner}>
                <div className={classes['CookieBanner-container']}>
                    <div className={classes['CookieBanner-header']}>
                        <h4><FormattedMessage id={'cookie.policy.title'} /></h4>
                    </div>
                    <div className={classes['CookieBanner-content']}>
                        <p>
                            <FormattedMessage id={'cookie.policy.text'} />{' '}
                            <NavLink to="/privacy">
                                <FormattedMessage id={'checkbox.cookie'} />
                            </NavLink>
                        </p>
                    </div>
                    <div className={classes['CookieBanner-footer']}>
                        <Button
                        type="primary-small"
                        clicked={this.handleClick}>
                            <FormattedMessage id={'cookie.policy.action'} />
                        </Button>
                    </div>
                </div>
            </div>
            :
            ''

        return cookieBanner
            
    }
};

export default CookieBanner;
