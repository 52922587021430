import React, { Component } from 'react';
import { History } from 'history';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as routes from '../../shared/routes';
import { IInput, ISession, ISessionData, IUser } from '../../shared/interfaces';
import classes from './styles.module.scss';
import Input from '../Field/Input';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import { FormattedMessage } from 'react-intl';
import FeedbackMessage from '../UI/FeedbackMessage';
import { isValidEmail } from '../../shared/helpers/checks';

interface IProps extends RouteComponentProps {
    isAuthenticated?: boolean;
    waitingForSignin?: boolean;
    sessionError?: string;
    resetUserError?: string;
    onSignin(data: ISession): ISessionData;
    onRecoveryUserPassword(data: any): object;
    history: History;
    onResetSigninModal(): void;
    recoveryMode: boolean;
    userReset: IUser;
}

interface IState {
    controls: {
        email: IInput;
        password: IInput;
    };
    error: string;
    recoveryMode: boolean;
    showSuccessfullyReset: boolean;
}

class SigninForm extends Component<IProps, IState> {
    state = {
        controls: {
            email: {
                name: 'email',
                value: '',
                type: 'email',
                label: 'label.email',
                placeholderId: 'placeholder.email'
            },
            password: {
                name: 'password',
                value: '',
                type: 'password',
                label: 'label.password',
                placeholderId: 'placeholder.password'
            }
        },
        error: '',
        recoveryMode: false,
        showSuccessfullyReset: false
    };

    handleInputChanged = (name: string, value: string) => {
        let controls: any = { ...this.state.controls };

        controls[name].value = value;

        this.setState({
            controls,
            error: ''
        });
    };

    handleSubmitClicked = () => {
        const {
            controls: { email, password }
        } = this.state;

        const data = {
            email: email.value,
            password: password.value,
            platform: 'mrkt'
        };

        this.props.onSignin(data);
    };

    handleResetClicked = () => {
        const {
            controls: { email }
        } = this.state;

        const data = {
            email: email.value
        };

        this.props.onRecoveryUserPassword(data);
    };

    toggleFormVisible = () => {
        let controls: any = { ...this.state.controls };
        controls['password'].value = '';

        const recoveryMode = !this.state.recoveryMode;

        this.setState({
            recoveryMode,
            error: '',
            controls
        });
    };

    goToRegisterRoute = () => {
        this.props.onResetSigninModal();

        this.props.history.push(routes.REGISTRATION.replace(':type', 'publisher'));
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (
            this.props.isAuthenticated !== prevProps.isAuthenticated &&
            this.props.isAuthenticated
        ) {
            let controls = { ...this.state.controls };

            controls.email.value = '';
            controls.password.value = '';

            this.setState(
                {
                    controls
                },
                () => {
                    this.props.history.push(routes.PUBLISHER);
                }
            );
        }

        if (this.props.sessionError !== prevProps.sessionError && this.props.sessionError) {
            this.setState({
                error: this.props.sessionError
            });
        }

        if (this.props.resetUserError !== prevProps.resetUserError && this.props.resetUserError) {
            this.setState({
                error: this.props.resetUserError
            });
        }

        if (
            this.props.recoveryMode !== prevProps.recoveryMode &&
            !this.props.recoveryMode &&
            this.state.recoveryMode
        ) {
            this.setState({
                recoveryMode: false
            });
        }

        if (this.props.userReset !== prevProps.userReset && this.props.userReset) {
            this.setState({
                showSuccessfullyReset: true
            });
        }
    }

    render() {
        const { controls, error, recoveryMode, showSuccessfullyReset } = this.state;

        const { waitingForSignin } = this.props;

        const currentFn = recoveryMode ? this.handleResetClicked : this.handleSubmitClicked;

        const submitDisabled =
            !isValidEmail(controls.email.value) ||
            (!recoveryMode && controls.password.value.trim() === '');

        return (
            <div className={classes.SigninForm}>
                {waitingForSignin && (
                    <div className={classes['SigninForm-loader']}>
                        <Loader theme={'light'} />
                    </div>
                )}
                <div className={classes['SigninForm-field']}>
                    <Input
                        type={controls.email.type}
                        name={controls.email.name}
                        label={<FormattedMessage id={controls.email.label} />}
                        placeholderId={controls.email.placeholderId}
                        submitted={
                            controls.email.value.trim() !== '' && !submitDisabled
                                ? currentFn
                                : undefined
                        }
                        value={controls.email.value}
                        changed={this.handleInputChanged}
                    />
                </div>
                {!recoveryMode && (
                    <div className={classes['SigninForm-field']}>
                        <Input
                            type={controls.password.type}
                            name={controls.password.name}
                            label={<FormattedMessage id={controls.password.label} />}
                            placeholderId={controls.password.placeholderId}
                            value={controls.password.value}
                            submitted={
                                controls.email.value.trim() !== '' &&
                                controls.password.value.trim() !== '' &&
                                !submitDisabled
                                    ? currentFn
                                    : undefined
                            }
                            changed={this.handleInputChanged}
                        />
                    </div>
                )}
                {error !== null && error !== '' && (
                    <FeedbackMessage visible={true} type={'error'}>
                        {`${error}`}
                    </FeedbackMessage>
                )}
                {showSuccessfullyReset && (
                    <FeedbackMessage visible={true} type={'success'}>
                        <FormattedMessage id={'password.reset.successfully'} />
                    </FeedbackMessage>
                )}
                <div className={classes['SigninForm-footer']}>
                    <Button disabled={submitDisabled} clicked={currentFn}>
                        <FormattedMessage
                            id={recoveryMode ? 'general.confirm' : 'general.signin'}
                        />
                    </Button>
                    <br />
                    <Button
                        styles={{ marginTop: '30px' }}
                        type={'secondary-small'}
                        clicked={this.toggleFormVisible}
                    >
                        <FormattedMessage id={recoveryMode ? 'general.back' : 'general.recovery'} />
                    </Button>
                    <br />
                    <Button type={'secondary-small'} clicked={this.goToRegisterRoute}>
                        <FormattedMessage id={'general.notRegistered'} />
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        waitingForSignin: state.sessionState.isFetching || state.userState.isFetching,
        sessionError: state.sessionState.error,
        resetUserError: state.userState.error,
        isAuthenticated: state.sessionState.authenticated,
        userReset: state.userState.userReset
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSignin: (data: ISession) => dispatch(actions.session(data)),
        onRecoveryUserPassword: (data: any) => dispatch(actions.recoveryUserPassword(data))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SigninForm));
