import * as actionTypes from '../actions/actionTypes';
import { IServerOption } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    target: IServerOption;
    targets: Array<IServerOption>;
    error: string;
}

const INITIAL_STATE = {
    item: null,
    items: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null
};

const fetchTargetStart = (state = INITIAL_STATE) => {
    return updateObject(state, { item: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchTargetSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { item: action.target, isFetching: false });
};

const fetchTargetFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchTargetsStart = (state = INITIAL_STATE) => {
    return updateObject(state, { items: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchTargetsSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { items: action.targets, isFetching: false });
};

const fetchTargetsFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const targetReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // READ/FETCHING
        case actionTypes.FETCH_TARGET_START:
            return fetchTargetStart(state);
        case actionTypes.FETCH_TARGET_SUCCESS:
            return fetchTargetSuccess(state, action);
        case actionTypes.FETCH_TARGET_FAIL:
            return fetchTargetFail(state, action);
        case actionTypes.FETCH_TARGETS_START:
            return fetchTargetsStart(state);
        case actionTypes.FETCH_TARGETS_SUCCESS:
            return fetchTargetsSuccess(state, action);
        case actionTypes.FETCH_TARGETS_FAIL:
            return fetchTargetsFail(state, action);

        default:
            return state;
    }
};

export default targetReducer;
