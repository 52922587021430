import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { IPersonage } from '../../../shared/interfaces';
import mainClasses from '../styles.module.scss';
import stepClasses from './styles.module.scss';
import Text from '../../UI/Text';
import PersonagesForm from '../../PersonageForm';
import classes from '../../PersonageForm/styles.module.scss';
import Loader from '../../UI/Loader';
import Instruction from '../../UI/Instruction';
import PersonagesList from '../../PersonagesList';

import Scroll from 'react-scroll';
import Dialog from '../../UI/Dialog';
import Button from '../../UI/Button';
import * as actions from '../../../store/actions';
const scroll = Scroll.animateScroll;

interface IProps {
    titleId: number;
    currentPersonagesList: Array<IPersonage>;
    waitingForPersonagesChanging: boolean;
    lastPersonageCreated: IPersonage;
    onRemovePersonage(titleId: number, personageId: number): void;
    setPersonagesChecker(counter: number, formOpened: boolean): void;
    personageUpdated: boolean;
    personageRemoved: boolean;
}

interface IState {
    errorNew: boolean;
    selected: IPersonage | null;
    showConfirmDialog: boolean;
    idToDelete: number;
}

class Personages extends Component<IProps, IState> {
    state = {
        selected: null,
        errorNew: false,
        showConfirmDialog: false,
        idToDelete: -1
    };

    onAddPersonage = () => {
        this.setState(
            {
                selected: {
                    id: Math.floor(Math.random() * 9999) * -1,
                    protagonist: false,
                    fullName: '',
                    genre: '',
                    nickname: '',
                    firstAge: 20,
                    secondAge: 40,
                    description: '',
                    quote: '',
                    adjectives: []
                }
            },
            () => {
                this.scrollTop();
            }
        );
    };

    scrollTop = () => {
        scroll.scrollToTop({
            duration: 500,
            delay: 100
        });
    };

    onEdit = (id: number) => {
        const { currentPersonagesList } = this.props;

        const selected = currentPersonagesList.find((p: IPersonage) => +p.id === +id);

        if (selected) {
            this.setState(
                {
                    selected
                },
                () => {
                    this.scrollTop();
                }
            );
        }
    };

    onRemove = (id: number) => {
        this.setState({
            idToDelete: id,
            showConfirmDialog: true
        });
    };

    handleRemoveClicked = () => {
        const { titleId } = this.props;
        const idToDelete = this.state.idToDelete;

        if (idToDelete > 0 && titleId > 0) {
            this.setState(
                {
                    showConfirmDialog: false
                },
                () => {
                    this.props.onRemovePersonage(titleId, idToDelete);
                }
            );
        } else {
            this.setState({
                selected: null,
                idToDelete: -1,
                showConfirmDialog: false
            });
        }
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.currentPersonagesList.length !== prevProps.currentPersonagesList.length) {
            this.resetForm();
        }

        if (
            this.props.personageRemoved !== prevProps.personageRemoved &&
            this.props.personageRemoved
        ) {
            this.resetForm();
        }

        if (
            this.props.personageUpdated !== prevProps.personageUpdated &&
            this.props.personageUpdated
        ) {
            this.resetForm();
        }

        if (this.state.selected !== prevState.selected) {
            this.props.setPersonagesChecker(
                this.props.currentPersonagesList.length,
                this.state.selected !== null
            );
        }
    }

    resetForm = () => {
        this.setState(
            {
                showConfirmDialog: false,
                idToDelete: -1,
                selected: null
            },
            () => {
                this.props.setPersonagesChecker(
                    this.props.currentPersonagesList.length,
                    this.state.selected !== null
                );
            }
        );
    };

    render() {
        const { errorNew, selected, showConfirmDialog } = this.state;
        const { titleId, waitingForPersonagesChanging, currentPersonagesList } = this.props;

        if (!titleId) {
            return null;
        }

        const currPersonageTpl = selected ? (
            <PersonagesForm
                onRemovePersonage={this.onRemove}
                showRemove={selected.id}
                titleId={titleId}
                personageId={selected.id}
                personage={selected}
            />
        ) : null;

        const personagesCounterCount = currentPersonagesList.map(
            (item: IPersonage, index: number) => {
                return (
                    index < 4 &&
                    <div key={index} className={stepClasses['Personages-counter-block']} />
                );
            }
        );

        const personagesCounterTpl = (
            <div className={stepClasses['Personages-counter']}>
                <h4>
                    <FormattedMessage id="instruction.characters.added.title" />
                </h4>
                <div
                    className={[
                        stepClasses['Personages-counter-count'],
                        currentPersonagesList.length > 2
                            ? stepClasses['Personages-counter-count--ok']
                            : ''
                    ].join(' ')}
                >
                    {personagesCounterCount}
                </div>
            </div>
        );

        return (
            <>
                <div
                    id={'personages'}
                    className={[
                        mainClasses.TitleForm,
                        mainClasses['TitleForm--nopadding'],
                        stepClasses.Personages
                    ].join(' ')}
                >
                    <div className={mainClasses['TitleForm-header']}>
                        <Text tag={'h2'} transform={'uppercase'} weight={600} spacing={true}>
                            <FormattedMessage id={'title.section.personages'} />
                        </Text>
                        <div className={classes['PersonageForm-loader']}>
                            {waitingForPersonagesChanging && (
                                <Loader theme={'light'} size="sm" fullContent />
                            )}
                        </div>
                    </div>
                    <div className={mainClasses['TitleForm-content']}>
                        <Instruction
                            onTop
                            type="suggestion"
                            additionalContent={personagesCounterTpl}
                            textIds={['instruction.characters']}
                        />
                        {currPersonageTpl}
                    </div>
                    {errorNew && (
                        <div className={stepClasses['Personages-feedback']}>
                            <FormattedMessage id={'personages.alert'} />
                        </div>
                    )}
                    {!selected && (
                        <div className={stepClasses['Personages-add']}>
                            <button onClick={() => this.onAddPersonage()}>
                                <span>
                                    <FormattedMessage id={'button.addPersonage'} />
                                </span>
                            </button>
                        </div>
                    )}
                    <div className={stepClasses['Personages-list']}>
                        <PersonagesList
                            onEdit={this.onEdit}
                            onRemove={this.onRemove}
                            hasActions={true}
                            personages={currentPersonagesList}
                        />
                    </div>
                </div>
                <Dialog
                    visible={showConfirmDialog}
                    onDialogCloseClicked={() =>
                        this.setState({ idToDelete: -1, showConfirmDialog: false })
                    }
                >
                    <Text marginBottom={40}>
                        <FormattedMessage id={'personage.remove.message'} />
                    </Text>
                    <div className={stepClasses['Personages-dialogButtons']}>
                        <Button
                            type={'secondary'}
                            clicked={() =>
                                this.setState({ idToDelete: -1, showConfirmDialog: false })
                            }
                        >
                            <FormattedMessage id={'general.undo'} />
                        </Button>
                        <Button clicked={this.handleRemoveClicked}>
                            <FormattedMessage id={'general.confirm'} />
                        </Button>
                    </div>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentPersonagesList: state.titleState.personagesList,
        waitingForPersonagesChanging: state.titleState.isUpdatingPersonages,
        lastPersonageCreated: state.titleState.lastPersonageCreated,
        personageUpdated: state.titleState.personageUpdated,
        personageRemoved: state.titleState.personageRemoved
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onRemovePersonage: (titleId: number, personageId: number) =>
            dispatch(actions.deletePersonageForTitleTitle(titleId, personageId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Personages);
