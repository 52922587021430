import request from '../../shared/lib/request';
import { IRegistration } from '../../shared/interfaces';

const create = (data: IRegistration) => {
    const headers = {
        'Content-Type': 'application/json'
    };

    return request({
        url: '/registration',
        method: 'POST',
        headers,
        data
    });
};

const activation = (code: string) => {
    const headers = {
        'Content-Type': 'application/json'
    };

    return request({
        url: `/registration/${code}/validate`,
        method: 'POST',
        headers
    });
};

const registrationForSchool = (data: IRegistration) => {
    const headers = {
        'Content-Type': 'application/json'
    };

    return request({
        url: `/registration/school`,
        method: 'POST',
        headers,
        data
    });
};

const RegistrationsService = {
    create,
    activation,
    registrationForSchool
};

export default RegistrationsService;
