import React, { Component } from 'react';
import classes from './styles.module.scss';
import AuthWrapper from '../../containers/AuthWrapper';
import TitlePreview from '../../components/TitlePreview';

class ShowTitle extends Component {
    render() {
        return (
            <AuthWrapper>
                <main className={classes.ShowTitle}>
                    <TitlePreview />
                </main>
            </AuthWrapper>
        );
    }
}

export default ShowTitle;
