import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    children: string | object;
    weight?: 300 | 400 | 600 | 700;
    transform?: 'capitalize' | 'uppercase' | 'none';
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    type?: 'default' | 'success' | 'error' | 'alert';
    marginTop?: number;
    marginBottom?: number;
    visible: boolean;
}

const FeedbackMessage = (props: IProps) => {
    const { children, weight, transform, size, type, visible, marginTop, marginBottom } = props;

    const propsStyle = {
        fontWeight: weight || 700,
        textTransform: transform || 'none',
        margin: `${marginTop || 0}px 0 ${marginBottom || 0}px 0`
    };

    const feedbackClasses = [
        classes.FeedbackMessage,
        size && classes[`FeedbackMessage--${size}`],
        type && classes[`FeedbackMessage--${type}`],
        visible && classes['is-visible']
    ].join(' ');

    return (
        <span style={propsStyle} className={feedbackClasses}>
            {children}
        </span>
    );
};

export default FeedbackMessage;
