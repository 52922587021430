import React, { Component } from 'react';
import classes from './styles.module.scss';
import { IPersonage, IServerOption } from '../../shared/interfaces';
import { FormattedMessage } from 'react-intl';
import Button from '../UI/Button';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import './styles.scss';

interface IProps {
    personages: Array<IPersonage>;
    onEdit?(id: number): void;
    onRemove?(id: number): void;
    hasActions?: boolean;
    flex?: boolean;
}

interface IState {
    selectedIndex: number;
}

class PersonagesList extends Component<IProps, IState> {
    state = {
        selectedIndex: -1,
        settings: {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            centerMode: false,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    };

    handleSelected = (index) => {
        let currentIndex = this.state.selectedIndex;

        if (currentIndex === index) {
            this.setState({ selectedIndex: -1 })
        } else {
            this.setState({ selectedIndex: index })
        }
    }

    render() {
        const { personages, hasActions, onEdit, onRemove, flex } = this.props;

        const { selectedIndex, settings } = this.state;

        const personagesTpl = personages.map((item: IPersonage, index: number) => {
            const tpl = (
                <>
                    {hasActions && onEdit && onRemove && (
                        <div className={classes['PersonagesList-actions']}>
                            <Button type="remove-onlyicon" clicked={() => onRemove(item.id)}>
                            </Button>
                            <Button type="edit-onlyicon" clicked={() => onEdit(item.id)}>
                            </Button>
                        </div>
                    )}
                    <h3>{item.fullName}</h3>
                    {item.protagonist ? (
                        <div className={classes['PersonagesList-protagonist']}>
                            <FormattedMessage id={'general.protagonist'} />
                        </div>
                    ) : (
                        <div className={classes['PersonagesList-protagonist']}>
                            <FormattedMessage id={'general.secondary'} />
                        </div>
                    )}
                    <div className={classes['PersonagesList-ages']}>
                        {item.firstAge > 0 &&
                        item.firstAge &&
                        item.secondAge &&
                        item.firstAge !== item.secondAge ? (
                            <FormattedMessage
                                id={'general.ages'}
                                values={{
                                    firstAge: item.firstAge,
                                    secondAge: item.secondAge
                                }}
                            />
                        ) : (
                            item.firstAge > 0 && (
                                <FormattedMessage
                                    id={'general.age'}
                                    values={{
                                        age: item.firstAge
                                    }}
                                />
                            )
                        )}
                    </div>
                    <div className={classes['PersonagesList-adjectives']}>
                        {item.adjectives.map((item: IServerOption) => item.label).join(', ')}
                    </div>
                </>
            );

            return !hasActions ? (
                <button
                    onClick={
                        !hasActions ? () => this.handleSelected(index) : undefined
                    }
                    className={[
                        classes['PersonagesList-item'],
                        hasActions ? classes['PersonagesList-item--withActions'] : '',
                        selectedIndex === index ? classes['PersonagesList-item--active'] : ''
                    ].join(' ')}
                    key={item.id}
                >
                    {tpl}
                </button>
            ) : (
                <article
                    className={[
                        classes['PersonagesList-item'],
                        hasActions ? classes['PersonagesList-item--withActions'] : '',
                        selectedIndex === index ? classes['PersonagesList-item--active'] : ''
                    ].join(' ')}
                    key={item.id}
                >
                    {tpl}
                </article>
            );
        });

        return (
            <div className={[classes.PersonagesList].join(' ')}>
                <div
                    className={[
                        classes['PersonagesList-list'],
                        flex ? classes['PersonagesList-list--flex'] : '',
                        'PersonagesList-slider'
                    ].join(' ')}
                >
                    {personages.length > 3 ? (
                        <Slider {...settings}>{personagesTpl}</Slider>
                    ) : (
                        personagesTpl
                    )}
                </div>
                {selectedIndex >= 0 && personages.length > selectedIndex && (
                    <div className={classes['PersonagesList-content']}>
                        <div className={classes['PersonagesList-header']}>
                            <h3>{personages[selectedIndex].fullName}</h3>
                            <h4>{personages[selectedIndex].nickname}</h4>
                        </div>
                        {personages[selectedIndex].quote && (
                            <blockquote>{personages[selectedIndex].quote}</blockquote>
                        )}
                        {personages[selectedIndex].description && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: personages[selectedIndex].description
                                }}
                                className={classes['PersonagesList-description']}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default PersonagesList;
