import request from '../../shared/lib/request';
import constants from '../../shared/constants';

const create = (data: object) => {
    const headers = {
        'Content-Type': 'application/json'
    };

    return request({
        url: '/session',
        method: 'POST',
        headers,
        data
    });
};

const me = () => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
    };

    return request({
        url: '/session/me',
        method: 'GET',
        headers
    });
};

const SessionsService = {
    create,
    me
};

export default SessionsService;
