import * as actionTypes from '../actions/actionTypes';
import { IServerOption } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    genre: IServerOption;
    genres: Array<IServerOption>;
    error: string;
}

const INITIAL_STATE = {
    item: null,
    items: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null
};

const fetchGenreStart = (state = INITIAL_STATE) => {
    return updateObject(state, { item: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchGenreSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { item: action.genre, isFetching: false });
};

const fetchGenreFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchGenresStart = (state = INITIAL_STATE) => {
    return updateObject(state, { items: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchGenresSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { items: action.genres, isFetching: false });
};

const fetchGenresFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const genreReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // READ/FETCHING
        case actionTypes.FETCH_GENRE_START:
            return fetchGenreStart(state);
        case actionTypes.FETCH_GENRE_SUCCESS:
            return fetchGenreSuccess(state, action);
        case actionTypes.FETCH_GENRE_FAIL:
            return fetchGenreFail(state, action);
        case actionTypes.FETCH_GENRES_START:
            return fetchGenresStart(state);
        case actionTypes.FETCH_GENRES_SUCCESS:
            return fetchGenresSuccess(state, action);
        case actionTypes.FETCH_GENRES_FAIL:
            return fetchGenresFail(state, action);

        default:
            return state;
    }
};

export default genreReducer;
