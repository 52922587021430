import React from 'react';
import classes from './styles.module.scss';
import Cleave from 'cleave.js/react';
import { FormattedMessage } from 'react-intl';

interface IProps {
    label?: string | object;
    required?: boolean;
    placeholder?: string;
    value: string;
    name: string;
    onChanged(name: string, value: string, type: 'date' | 'year' | 'month/year'): void;
    type?: 'date' | 'year' | 'month/year';
}

const DateInput = (props: IProps) => {
    const { value, onChanged, placeholder, type, name, label, required } = props;

    return (
        <div className={classes.DateInput}>
            {label && (
                <div className={classes['DateInput-header']}>
                    <label className={classes['DateInput-label']}>{label}</label>
                    {required && (
                        <span className={classes['DateInput-required']}>
                            <FormattedMessage id={'general.required'} />
                        </span>
                    )}
                </div>
            )}
            <div className={classes['DateInput-content']}>
                <Cleave
                    placeholder={placeholder || 'DD/MM/YYYY'}
                    value={value}
                    options={{
                        date: true,
                        delimiter: '/',
                        datePattern:
                            type && type === 'year' ?
                                ['Y']
                                :
                                ( type === 'date' ? ['d', 'm', 'Y'] : ['m', 'Y'] )
                    }}
                    onChange={(e: any) => onChanged(name, e.target.value, type || 'date')}
                />
            </div>
        </div>
    );
};

export default DateInput;
