import React, { Component } from 'react';
import classes from './styles.module.scss';

interface IProps {
    label?: string | object;
    children: any;
    theme?: 'default' | 'red' | 'orange' | 'grey';
}

class List extends Component<IProps> {
    render() {
        const { label, children, theme } = this.props;

        return (
            <div className={[classes.List, theme ? classes[`List--${theme}`] : ''].join(' ')}>
                <div className={classes['List-content']}>
                    <div className={classes['List-wrapper']}>
                        <div className={classes['List-header']}>
                            <label className={classes['List-label']}>{label}</label>
                        </div>
                        <div className={classes['List-content']}>{children}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default List;
