import constants from '../constants';
import { IFile, ISessionData } from '../interfaces';

export const checkSession = (): boolean => {
    return localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN) !== null;
};

export const resetSession = (): void => {
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE);
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_NAME);
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_SURNAME);
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_EMAIL);
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_ID);
};

export const setSession = (session: ISessionData): void => {
    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN, session.token);
    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_NAME, session.name);
    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_SURNAME, session.surname);
    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_EMAIL, session.email);
    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_ID, session.uid);
};

export const setStorageImage = (file: IFile | null) => {
    if (!file) return null;

    const imageUrl = file.url ? file.url : null;

    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.USER_IMAGE, imageUrl);
};
