import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { ITag } from '../../../shared/interfaces';
import classes from './style.module.scss';
import { WithContext as ReactTags } from 'react-tag-input';
import './styles.scss';
import Instruction from '../../UI/Instruction';

interface IProps {
    selected: Array<ITag>;
    placeholderId?: string;
    lined?: boolean;
    label?: string | object;
    name: string;
    minSelected?: number;
    maxSelected?: number;
    changed(name: string, values: Array<ITag>): void;
    instructionIds?: Array<string>;
}

interface IState {
    tags: Array<any>;
}

class StringsSelector extends Component<IProps, IState> {
    state: IState = {
        tags: []
    };

    componentDidMount(): void {
        this.initializeData();
    }

    initializeData = () => {
        if (this.props.selected.length) {
            this.setSelected(this.props.selected);
        }
    };

    handleDelete(i: number) {
        const tags = this.state.tags.slice(0);
        tags.splice(i, 1);

        this.setState({ tags }, () => this.updateDate());
    }

    handleAddition(tag: ITag) {
        //console.log(tag);
        if (!this.checkIfAlreadySelected(tag)) {
            let tags: Array<any> = [...this.state.tags];

            tags = tags.concat(tag);

            this.setState({ tags }, () => this.updateDate());
        }
    }

    updateDate = () => {
        this.props.changed(this.props.name, this.state.tags);
    };

    checkIfAlreadySelected = (tag: ITag) => {
        return this.state.tags.find((item: ITag) => item.text === tag.text) || false;
    };

    setSelected = (values: Array<any>) => {
        this.setState({
            tags: values
        });
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.selected.length !== prevProps.selected.length) {
            this.initializeData();
        }
    }

    render() {
        const { lined, label, minSelected, placeholderId, instructionIds } = this.props;
        const { tags } = this.state;

        return (
            <div
                className={[
                    classes.StringsSelector,
                    lined ? classes['StringsSelector--lined'] : ''
                ].join(' ')}
            >
                <div className={classes['StringsSelector-header']}>
                    <label className={classes['StringsSelector-label']}>{label}</label>
                    {minSelected && (
                        <span className={classes['StringsSelector-required']}>
                            <FormattedMessage id={'general.required'} />
                        </span>
                    )}
                </div>
                <div className={classes['StringsSelector-content']}>
                    <div className={classes['StringsSelector-wrapper']}>
                        <FormattedMessage
                            id={placeholderId || 'general.emptyMessage'}
                            defaultMessage={''}
                        >
                            {(placeholder: string) => (
                                <ReactTags
                                    autofocus={false}
                                    placeholder={placeholder}
                                    allowDragDrop={false}
                                    allowNew={true}
                                    tags={tags}
                                    suggestions={[]}
                                    handleDelete={this.handleDelete.bind(this)}
                                    handleAddition={this.handleAddition.bind(this)}
                                />
                            )}
                        </FormattedMessage>
                    </div>
                    {instructionIds && <Instruction textIds={instructionIds} />}
                </div>
            </div>
        );
    }
}

export default StringsSelector;
