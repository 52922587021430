import * as routes from '../routes';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
    AUTH_NAV: [
        {
            label: <FormattedMessage id={'navigation.titles'} />,
            route: routes.TITLES,
            icon: 'nav-titles'
        },
        {
            label: <FormattedMessage id={'navigation.support'} />,
            route: routes.SUPPORT,
            icon: 'nav-support'
        }
    ],
    DEFAULT_NAV: [
        /*
        {
            label: <FormattedMessage id={'navigation.registration.producer'} />,
            route: routes.REGISTRATION.replace(':type', 'producer')
        },*/
        {
            label: <FormattedMessage id={'navigation.registration'} />,
            route: routes.REGISTRATION.replace(':type', 'publisher')
        }
    ]
};
