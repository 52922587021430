import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/helpers/utilities';
import { ISessionData } from '../../shared/interfaces';
import { checkSession } from '../../shared/helpers/session';

interface IAction {
    type: string;
    session: ISessionData;
    token: string;
    error: string;
}

const INITIAL_STATE = {
    token: null,
    userId: null,
    isFetching: false,
    didInvalidate: false,
    authenticated: checkSession(),
    error: null
};

const createSessionStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        token: null,
        userId: null,
        authenticated: false,
        error: null,
        didInvalidate: false,
        isFetching: true
    });
};

const createSessionSuccess = (state = INITIAL_STATE, action: IAction) => {
    const authenticated = action.session !== undefined && action.session !== null;

    return updateObject(state, {
        userId: action.session && +action.session.uid,
        token: action.session && action.session.token,
        authenticated,
        isFetching: false
    });
};

const createSessionFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchMeStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        token: null,
        userId: null,
        authenticated: false,
        isFetching: true,
        error: null,
        didInvalidate: false
    });
};

const fetchMeSuccess = (state = INITIAL_STATE, action: IAction) => {
    const authenticated = action.session !== undefined && action.session !== null;

    return updateObject(state, {
        userId: action.session && +action.session.uid,
        token: action.session && action.session.token,
        authenticated,
        isFetching: false
    });
};

const fetchMeFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const signoutSuccess = (state = INITIAL_STATE) => {
    return updateObject(state, {
        token: null,
        userId: null,
        authenticated: false,
        isFetching: false,
        error: null,
        didInvalidate: false
    });
};

const sessionReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // CREATE
        case actionTypes.SESSION_START:
            return createSessionStart(state);
        case actionTypes.SESSION_SUCCESS:
            return createSessionSuccess(state, action);
        case actionTypes.SESSION_FAIL:
            return createSessionFail(state, action);

        // FETCH ME
        case actionTypes.FETCH_ME_START:
            return fetchMeStart(state);
        case actionTypes.FETCH_ME_SUCCESS:
            return fetchMeSuccess(state, action);
        case actionTypes.FETCH_ME_FAIL:
            return fetchMeFail(state, action);

        // SIGNOUT
        case actionTypes.SESSION_SIGNOUT_SUCCESS:
            return signoutSuccess(state);

        default:
            return state;
    }
};

export default sessionReducer;
