import * as actionTypes from '../actions/actionTypes';
import { IServerOption } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    award: IServerOption;
    awards: Array<IServerOption>;
    error: string;
}

const INITIAL_STATE = {
    item: null,
    items: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null
};

const fetchAwardStart = (state = INITIAL_STATE) => {
    return updateObject(state, { item: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchAwardSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { item: action.award, isFetching: false });
};

const fetchAwardFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchAwardsStart = (state = INITIAL_STATE) => {
    return updateObject(state, { items: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchAwardsSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { items: action.awards, isFetching: false });
};

const fetchAwardsFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const awardReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // READ/FETCHING
        case actionTypes.FETCH_AWARD_START:
            return fetchAwardStart(state);
        case actionTypes.FETCH_AWARD_SUCCESS:
            return fetchAwardSuccess(state, action);
        case actionTypes.FETCH_AWARD_FAIL:
            return fetchAwardFail(state, action);
        case actionTypes.FETCH_AWARDS_START:
            return fetchAwardsStart(state);
        case actionTypes.FETCH_AWARDS_SUCCESS:
            return fetchAwardsSuccess(state, action);
        case actionTypes.FETCH_AWARDS_FAIL:
            return fetchAwardsFail(state, action);

        default:
            return state;
    }
};

export default awardReducer;
