import React from 'react';
import classes from './styles.module.scss';
import { NavLink } from 'react-router-dom';

import logoUrl from '../../../assets/img/logo.svg';

interface IProps {
    to: string;
    alt?: string;
}

const Logo = (props: IProps) => {
    const { to, alt } = props;

    return (
        <NavLink to={to} className={classes.Logo}>
            <img className={classes['Logo-image']} src={logoUrl} alt={alt || 'Artchivio'} />
        </NavLink>
    );
};

export default Logo;
