import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import Text from '../../components/UI/Text';

interface IProps {
    history: History;
}

class Terms extends Component<IProps> {
    render() {
        return (
            <main className={classes.Terms}>
                <div className={classes['Terms-header']}>
                    <Text
                        tag={'h2'}
                        marginBottom={30}
                        weight={400}
                        transform={'uppercase'}
                        spacing={true}
                    >
                        <span>Termini e Condizioni</span>
                    </Text>
                </div>

                <div className={classes['Terms-content']}>
                    <p>
                        Artchivio è un marketplace online (in seguito “Marketplace”) volto a
                        promuovere la compravendita di diritti di adattamento cinematografico e
                        televisivo derivati da opere letterarie e radiofoniche (in seguito
                        “Servizio”). Il Servizio è gratuito e non implica alcun vincolo di
                        esclusività.
                    </p>

                    <p>
                        L’accettazione dei “Termini e Condizioni” è da considerare a tutti gli
                        effetti come l'accettazione di un contratto. L’attivazione dell’account
                        implica piena accettazione, senza riserve, dei presenti Termini. Eventuali
                        modifiche e/o integrazioni ai Termini e Condizioni saranno efficaci con
                        decorrenza dalla data in cui saranno pubblicate sul Sito e si applicheranno
                        agli utenti attivati a decorrere da detta data. L’ultima versione aggiornata
                        dei Termini e Condizioni è quella reperibile sul Sito.
                    </p>

                    <h2>Termini e condizioni di Artchivio</h2>

                    <h3>1) Indicazioni preliminari</h3>

                    <p>La premessa costituisce parte integrante del presente Accordo.</p>

                    <p>
                        Per la fruizione del servizio è necessario disporre di una connessione
                        Internet, i cui costi sono a carico dell’Utente, secondo i termini stabiliti
                        dal proprio provider.
                    </p>

                    <p>
                        Le apparecchiature (tablet, computer, smartphone, applicazioni, altri mezzi
                        di telecomunicazioni, ecc.) che consentono l’accesso al Servizio, nonché le
                        spese di telecomunicazione necessarie alla fruizione del Servizio stesso,
                        sono a carico esclusivo dell’Utente.
                    </p>

                    <p>
                        Artchivio informa espressamente l’Utente che, qualora un aggiornamento del
                        servizio richieda cambiamenti nel software o nella strumentazione
                        dell’Utente, questi dovranno essere integralmente effettuati a cura e spese
                        dell’Utente.
                    </p>

                    <h3>2) Utenze</h3>

                    <p>
                        Il Marketplace prevede due tipi di utenze diverse: “Utenza Produttore” e
                        “Utenza Editore”.{' '}
                    </p>

                    <p>
                        L’Utenza Produttore è pensata per tutti coloro che utilizzano la piattaforma
                        per la ricerca e l'acquisizione di diritti di adattamento cinematografici e
                        televisivi di opere letterarie e radiofoniche.
                    </p>

                    <p>
                        L’Utenza Editore è pensata per tutti coloro che utilizzano la piattaforma
                        per la vendita di diritti di adattamento cinematografici e televisivi di
                        opere letterarie e radiofoniche di cui sono in possesso o per cui hanno
                        un’autorizzazione, come disciplinato dall’art. 6.
                    </p>

                    <p>
                        In base all’utenza scelta, l’utente avrà accesso a una fruizione diversa del
                        Marketplace, come previsto dall’art 3.
                        <br />
                        Resta inteso che l’Utente registrandosi, come utente Produttore o Editore,
                        aderisce allo stesso modo a tutte le clausole previste dall’Accordo, nessuna
                        esclusa così come disciplinato dall’art 8.
                    </p>

                    <h3>3) Caratteristiche del Servizio</h3>

                    <p>
                        Il Servizio ha ad oggetto l’utilizzo di uno strumento originale ed autentico
                        realizzato da Artchivio per promuovere la compravendita di diritti di
                        adattamento cinematografico e televisivo derivati da opere letterarie e
                        radiofoniche (in seguito “Opere”).
                    </p>

                    <p>
                        Con l’accettazione dei Termini e Condizioni, l’Utenza Editore potrà fruire
                        della piattaforma tramite le seguenti funzionalità:
                    </p>
                    <ul>
                        <li>
                            il caricamento di schede che riassumono i tratti salienti delle Opere;
                        </li>
                        <li>
                            un dashboard relativo all’account e ai singoli titoli che monitora le
                            visualizzazioni delle schede, in dettaglio, e il numero di download di
                            ogni titolo;
                        </li>
                        <li>
                            una chat di supporto connessa direttamente al team di customer care di
                            Artchivio.
                        </li>
                    </ul>

                    <p>
                        Con l’accettazione dei Termini e Condizioni, l’Utenza Produttore potrà
                        fruire della piattaforma nei seguenti termini:
                    </p>
                    <ul>
                        <li>
                            un motore di ricerca per sfogliare le schede caricate dagli Utenti del
                            sito utilizzando filtri per genere, caratteristiche, ambientazione e
                            periodo;
                        </li>
                        <li>la visualizzazione delle schede caricate da altri utenti;</li>
                        <li>
                            accesso, tramite download, al file completo dell’opera letteraria, con
                            un massimo di 3 download mensili, con le modalità di cui agli artt. 8 e
                            12;
                        </li>
                        <li>
                            il contatto mail o telefonico dell’Editore, o in ogni caso del referente
                            incaricato alla negoziazione per la vendita dei diritti di adattamento
                            cinematografico e televisivo;
                        </li>
                        <li>
                            una chat di supporto connessa direttamente al team di customer care di
                            Artchivio.
                        </li>
                    </ul>

                    <p>
                        Artchivio, su espressa richiesta offre un accompagnamento editoriale agli
                        utenti Editori, volto a premiare la qualità dei contenuti caricati dagli
                        stessi. L’accompagnamento è previsto per il caricamento della prima Opera.
                        L’Editore può richiedere una consulenza editoriale per il caricamento di più
                        Opere, i termini di questo servizio saranno accordati in un altro contratto.
                    </p>

                    <h3>4) Costi del servizio </h3>

                    <p>
                        L’utilizzo dei servizi di cui all’art. 3 del presente accordo è gratuito.{' '}
                    </p>

                    <p>
                        Tutte le modifiche in ordine all’ampliamento, alla limitazione, alla
                        gratuità e agli eventuali costi dei suddetti servizi saranno pubblicate
                        sulla piattaforma nonché comunicate agli Utenti, agli indirizzi e-mail
                        forniti al momento dell’iscrizione.{' '}
                    </p>

                    <p>
                        Resta inteso che ogni accordo introduttivo di servizi a pagamento costituirà
                        oggetto di separate pattuizioni subordinate all'accettazione degli Utenti.
                        Non sono previsti dal presente Accordo corrispettivi per Artchivio nel caso
                        in cui si concretizzi la compravendita di IP, prevista offline (fuori dal
                        sito).{' '}
                    </p>

                    <h3>5) Versione Beta </h3>

                    <p>
                        Il Servizio è fornito come “versione beta” e Artchivio lo distribuisce come
                        una versione “di prova”, quindi “non definitiva” con lo scopo di ricevere
                        opinioni sulla qualità e fruibilità del Servizio.{' '}
                    </p>

                    <p>
                        Iscrivendosi al Servizio, l’utente si assume la responsabilità per errori ed
                        inesattezze che potrebbero causare errori o perdita dei dati per cause non
                        imputabili a dolo o colpa grave di Artchivio .{' '}
                    </p>

                    <p>
                        Artchivio non possiede obbligo alcuno di fornire nessun tipo di manutenzione
                        né di assistenza tecnica o di altro tipo relativamente al Servizio.
                    </p>

                    <h3>6) Proprietà dei Diritti di Adattamento </h3>

                    <p>
                        Gli Editori nel caricare le Opere dichiarano di possedere i diritti di
                        adattamento audiovisivo, cinematografico e televisivo delle Opere in
                        questione o di avere l’autorizzazione da parte del proprietario degli
                        anzidetti diritti di poter pubblicare, promuovere e negoziare la vendita di
                        questi ultimi.
                    </p>

                    <p>
                        In ogni caso, Artchivio declina ogni responsabilità da qualsiasi violazione
                        risultante dal caricamento da parte degli Utenti di contenuti effettuato
                        senza poterne effettivamente disporre ovvero in mancanza di preventiva
                        autorizzazione da parte del proprietario dei ridetti diritti.
                    </p>

                    <h3>6.1) Licenze sui Contenuti </h3>

                    <p>
                        L’Utente, proprietario di tutti i contenuti caricati sulla piattaforma,
                        concede ad Artchivio alcune licenze sui contenuti. Sui contenuti caricati o
                        inviati sulla piattaforma, è concesso un diritto non esclusivo, mondiale,
                        esente da royalty, in licenza e trasferibile per utilizzare, riprodurre,
                        mostrare pubblicamente, distribuire, modificare (in modo da mostrare meglio
                        i contenuti, ad esempio), eseguire pubblicamente e tradurre i contenuti. La
                        licenza è concessa al solo fine di operare, commercializzare, promuovere e
                        migliorare i Servizi. Qualora i contenuti caricati da un Utente fossero
                        incorporati in una funzione del Servizio o in materiali promozionali o di
                        marketing, l’Utente sarà accreditato, attribuendogli la loro paternità.
                        <br />
                        Con il caricamento dei contenuti sulla piattaforma, l’Editore espressamente
                        manleva Artchivio da eventuali responsabilità per qualsivoglia utilizzo non
                        autorizzato degli stessi da parte di terzi, nonché da eventuali pretese
                        avanzate da terzi in relazione alla titolarità del diritto d’autore
                        sull’Opera.
                    </p>

                    <h3>7) Negoziazione e Compravendita di Proprietà Intellettuale (IP) </h3>

                    <p>
                        Il presente Servizio fornito da Artchivio non prevede alcun tipo di
                        fornitura in fase di negoziazione e di chiusura degli accordi relativi alla
                        cessione dei diritti di utilizzo e di sfruttamento economico delle Opere.{' '}
                    </p>

                    <h3>8) Obblighi e Responsabilità dell’Utente per la fruizione del Servizio </h3>

                    <p>
                        L’Utente dichiara e garantisce: (i) di avere più di 18 anni; (ii) che i dati
                        dallo stesso forniti per l’esecuzione del Contratto sono corretti e
                        veritieri; (iii) che aggiornerà i dati forniti a Artchivio ogniqualvolta si
                        renda necessario.
                    </p>

                    <p>
                        L’Utente è autorizzato a fruire del Servizio ad uso personale e/o
                        collettivamente per conto di un’azienda, garantendo di poter assumere questo
                        tipo di contratto, e si assume la piena responsabilità per ogni uso non
                        autorizzato tra cui, a puro titolo esemplificativo, l’utilizzo dei contenuti
                        trovati sulla piattaforma per realizzare prodotti editoriali e/o audiovisivi
                        senza averne il diritto.
                    </p>

                    <p>
                        L’Utente garantisce di avere diritto a pubblicare i contenuti caricati sulla
                        piattaforma ed è allo stesso modo consapevole di non avere alcun diritto sui
                        contenuti originali della piattaforma. Artchivio non controlla e non può
                        controllare l’età o la capacità di agire degli Utenti che attivano il
                        Servizio, è pertanto esclusa ogni responsabilità di Artchivio conseguente
                        all’attivazione di profili non autorizzati.
                    </p>

                    <h3>9) Interruzione nella fornitura del Servizio</h3>

                    <p>
                        Artchivio non è in alcun modo responsabile per eventuali interruzioni nella
                        fornitura del Servizio dovute a cause ad essa non direttamente imputabili
                        e/o che sfuggano al proprio controllo tecnico, quali disfunzioni della rete
                        telematica o telefonica. Per qualsiasi accertamento relativo al
                        funzionamento del Servizio faranno fede i sistemi di Artchivio.
                    </p>

                    <p>
                        Artchivio si riserva il diritto di bloccare o sospendere, in qualsiasi
                        momento e senza preavviso, la fornitura del Servizio agli Utenti che
                        dovessero infrangere i termini previsti nelle presenti Condizioni Generali,
                        riservandosi inoltre il diritto di disattivare o cancellare definitivamente
                        l’attivazione dell’Utente e tutte le informazioni correlate e/o bloccare
                        ogni suo eventuale accesso futuro al Servizio.
                    </p>

                    <p>
                        Il Servizio è fornito da Artchivio tramite il sito web www.artchivio.net e
                        all’universo di siti compreso all’interno del dominio.
                    </p>

                    <h3>10) Attivazione e gestione del Servizio</h3>

                    <p>
                        L’Utente potrà attivare il Servizio accettando il presente termini e
                        condizioni. Artchivio richiede la registrazione di un account che permetterà
                        all’Utente l’utilizzo del servizio. In assenza della creazione di una
                        utenza, l’abbonamento non sarà fruibile. Il Servizio non prevede sospensioni
                        in quanto, può essere consultato continuativamente.
                    </p>

                    <h3>11) Durata del Servizio</h3>
                    <p>
                        Il Servizio inizierà dalla data di attivazione dell’utenza e la contestuale
                        accettazione dei presenti Termini e Condizioni e durerà indefinitamente.
                    </p>

                    <p>
                        L’utente potrà disattivare il Servizio in ogni momento inviando una e-mail
                        a: <a href="mailto:info@artchivio.net">info@artchivio.net</a>.
                    </p>

                    <p>
                        Salvo espressa richiesta dell’Utente, la disattivazione non implica la
                        cancellazione permanente del profilo.
                    </p>

                    <h3>12) Diritti di proprietà intellettuale di Artchivio.net</h3>

                    <p>
                        Il sito web, i siti mobile, i marchi, i loghi, la grafica, le fotografie, le
                        animazioni, i video, i testi e qualsiasi altro contenuto offerto nel
                        Servizio sono proprietà intellettuale esclusiva di Artchivio o delle terze
                        parti aventi diritto e non possono essere riprodotti, usati o rappresentati
                        senza l’esplicita autorizzazione dei titolari.{' '}
                    </p>

                    <p>
                        I diritti d’uso concessi da Artchivio all’Utente sono rigorosamente limitati
                        all’accesso, alla richiesta, ricezione e visualizzazione sul proprio
                        dispositivo dei contenuti editoriali pubblicati e promossi a tale scopo e
                        all’uso di tali Contenuti a scopo privato e personale e senza alcun fine di
                        condivisione, diffusione, comunicazione commerciale o profitto al di fuori
                        degli ambiti concessi da Artchivio. Qualsiasi altro uso da parte
                        dell’Utente, senza l’autorizzazione di Artchivio, è vietato. In particolare,
                        l’Utente non può modificare, copiare, riprodurre, scaricare, diffondere,
                        trasmettere, sfruttare commercialmente e/o distribuire in qualsiasi modo i
                        contenuti fruiti.
                    </p>

                    <p>
                        L’Utente si impegna a rispettare i diritti di copyright di Artchivio e di
                        terze parti ove dichiarato. Copiare e conservare, utilizzare qualsiasi
                        contenuti cui l’Utente ha avuto accesso mediante l’Abbonamento per scopi
                        diversi da quelli di cui al presente Contratto, è vietato.
                    </p>

                    <h3>13) Manleva</h3>

                    <p>
                        Nel caso in cui Artchivio venga indicata come responsabile di una violazione
                        commessa, da parte di un Utente, degli obblighi che derivano ai sensi della
                        legge vigente o delle presenti Termini e Condizioni, l’Utente si impegna a
                        tenere indenne Artchivio da qualsiasi contestazione, azione, richiesta di
                        risarcimento, sentenza, transazione avanzata /pronunciata nei suoi
                        confronti. Tale garanzia copre qualsiasi importo a qualsiasi titolo
                        richiesto che dovrà eventualmente essere versato, gli onorari degli avvocati
                        e le spese legali che verranno addebitate a Artchivio.
                    </p>

                    <h3>14) Responsabilità di Artchivio</h3>

                    <p>
                        Artchivio non può garantire in alcun modo la precisione e/o l’attualità dei
                        contenuti sulla piattaforma. Artchivio non potrà pertanto essere considerata
                        responsabile per eventuali danni che possano derivare agli Utenti dall’uso
                        delle informazioni e dei contenuti inclusi nel Servizio.
                    </p>

                    <p>
                        Parimenti, Artchivio declina ogni responsabilità per qualsivoglia attività
                        degli Utenti che sia e/o contenga materiale:
                    </p>
                    <ul>
                        <li>Offensivo, diffamatorio, pornografico o osceno;</li>
                        <li>
                            Illegale o volto alla realizzazione di attività illegali di ogni tipo,
                            ivi compresa la violazione del diritto alla privacy, all’immagine, alla
                            proprietà intellettuale;
                        </li>
                        <li>
                            Includa contenuti personali di terze parti, o sia volto alla promozione
                            di tali contenuti;
                        </li>
                        <li>
                            Includa contenuti dannosi quali malware, trojan o virus, ovvero
                            interferisca con l’accesso al Servizio degli Utenti;
                        </li>
                        <li>Sia volto a molestie o bullismo nei confronti degli altri Utenti;</li>
                        <li>
                            Usi mezzi automatizzati per la promozione artificiale di contenuti (i.e.
                            spam, bots ecc.).;
                        </li>
                        <li>Contrario ai presenti Termini e Condizioni.</li>
                    </ul>

                    <p>
                        Artchivio si impegna a mantenere l’efficienza del Servizio nei limiti
                        previsti dalla fase Beta anzidetta, art. 3. Qualora fosse costretta ad
                        interrompere il Servizio per eventi eccezionali o manutenzione, cercherà di
                        contenere nel minor tempo possibile i periodi di interruzione e/o
                        malfunzionamento.
                        <br />
                        Per qualsiasi accertamento relativo al funzionamento dei Servizi faranno
                        fede i sistemi di Artchivio.
                    </p>

                    <h3>15) Malfunzionamento del Servizio</h3>

                    <p>
                        In nessun caso Artchivio sarà ritenuta responsabile del malfunzionamento del
                        Servizio derivante da guasti, sovraccarichi e interruzioni anche temporanee
                        da parte dei gestori di linee telefoniche, elettriche, reti mondiali e
                        nazionali.
                    </p>

                    <p>
                        Nessun risarcimento danni potrà essere richiesto a Artchivio per danni
                        diretti e/o indiretti causati dall’utilizzo o mancato utilizzo del Servizio,
                        salvo il caso di dolo o colpa grave.
                    </p>

                    <p>
                        In nessun caso Artchivio sarà ritenuta responsabile del malfunzionamento di
                        servizi esterni ai propri server, non espressamente contemplati nel
                        Contratto e gestiti da terze parti.
                    </p>

                    <h3>16) Comunicazioni e servizio clienti</h3>

                    <p>
                        I servizi sono offerti da Artchivio S.r.l. Per ottenere informazioni,
                        Artchivio può essere contattata nelle seguenti modalità rispetto al
                        dispositivo con cui si fruisce del Servizio:
                    </p>

                    <p>
                        SITO:{' '}
                        <a rel="noopener noreferrer" href="https://artchivio.net" target="_blank">
                            https://artchivio.net
                        </a>
                    </p>
                    <p>
                        MAIL: <a href="mailto:info@artchivio.net">info@artchivio.net</a>
                    </p>

                    <h3>17) Privacy</h3>

                    <p>
                        I dati forniti dall’Utente sono raccolti e trattati da Artchivio in qualità
                        di autonomo Titolare del trattamento per la fornitura dei servizi richiesti.
                        I dati saranno trattati con modalità prevalentemente elettroniche.
                    </p>

                    <p>
                        I dati personali forniti al momento della registrazione verranno registrati
                        e conservati su supporti elettronici protetti e trattati con adeguate misure
                        di sicurezza anche associandoli ed integrandoli con altri DataBase, sempre
                        nel pieno rispetto delle misure di sicurezza a tutela della riservatezza.
                    </p>

                    <p>
                        I dati saranno trattati da Artchivio esclusivamente con modalità e procedure
                        necessarie per la gestione e l’erogazione del Servizio richiesto e solo con
                        l’espresso consenso dell’Utente, per effettuare analisi statistiche,
                        indagini di mercato e invio di informazioni commerciali sui prodotti e sulle
                        iniziative promozionali del Titolare del trattamento.
                    </p>

                    <p>
                        Le comunicazioni per indagini di mercato o di informazioni commerciali sui
                        prodotti e sulle iniziative promozionali potranno essere effettuate mediante
                        mailing postale, posta elettronica, telemarketing, sms, mms.
                    </p>

                    <p>
                        Ai sensi dell’art. 12 del Regolamento UE 679/2016, si possono esercitare i
                        relativi diritti, fra cui consultare, modificare, aggiornare, rettificare o
                        cancellare i dati od opporsi al loro trattamento per fini di invio di
                        materiale pubblicitario e ricerche di mercato, rivolgendosi ai Titolari del
                        trattamento Artchivio S.r.l. – via dei Barbieri 5, 00186 Roma e/o
                        all’indirizzo email{' '}
                        <a href="mailto:info@artchivio.net">info@artchivio.net</a>
                    </p>

                    <h3>18) Legge applicabile e Giurisdizione</h3>

                    <p>
                        Il presente contratto è soggetto al diritto italiano e la lingua del
                        presente contratto è la lingua italiana.{' '}
                    </p>

                    <p>
                        Le disposizioni del presente Accordo saranno applicate in tutti i Paesi dove
                        è attivo il Servizio.
                    </p>

                    <p>
                        Eventuali deroghe in merito alla Legge applicabile saranno soggette ad
                        apposita pattuizione e delle stesse sarà data comunicazione agli Utenti.
                    </p>

                    <p>
                        In caso di controversia, connessa con l’interpretazione, esecuzione o
                        cessazione dell’efficacia del presente accordo, sarà competente il Foro di
                        Roma.
                    </p>

                    <p>
                        Il presente documento costituisce tutti i Termini e le Condizioni ai quali
                        gli Utenti ed Artchivio aderiscono integralmente anche ai sensi degli artt.
                        1341 e 1342 c.c..
                        <br />
                        L’Utente dichiara di non aver accettato ulteriori accordi, in forma scritta
                        od orale, che non siano contenuti nel presente documento.
                        <br />
                        Si dichiara, in ogni caso, che ulteriori aspetti del Servizio prestato
                        possano essere soggetti a successivi accordi. L’Utente accetta di aderire a
                        tali accordi in separata sede.
                        <br />
                        Il presente accordo prevarrà sui successivi accordi, in caso di
                        controversia, salvo che non sia diversamente previsto.
                    </p>

                    <p>Roma, giugno 2020.</p>

                    <p>Artchivio S.r.l.</p>
                </div>
            </main>
        );
    }
}

export default Terms;
