import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { ISelectOption, IServerOption } from '../../../shared/interfaces';
import classes from './style.module.scss';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './styles.scss';
import Instruction from '../../UI/Instruction';

interface IProps {
    lined?: boolean;
    label?: string | object;
    name: string;
    minSelected?: number;
    maxSelected?: number;
    changed(name: string, values: Array<ISelectOption>): void;
    selected: Array<IServerOption>;
    options: Array<ISelectOption>;
    allowCreation?: boolean;
    instructionIds?: Array<string>;
}

interface IState {
    error: boolean;
}

/* Mike see guide */
const customStyles = {
    menu: (styles: any) => ({ ...styles, zIndex: '2' }),
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
        ...styles,
        width: '98%',
        fontSize: '1.2rem',
        fontFamily: '"Montserrat", sans-serif',
        fontWeight: 600,
        borderColor: isFocused
        ? '#7F9FA8'
        : isSelected
        ? '#7F9FA8'
        : 'rgba(209, 209, 209, 0.7)',
        boxShadow: isFocused
        ? '0px 0px 1px #7F9FA8'
        : isSelected
        ? '0px 0px 1px #7F9FA8'
        : '0px 0px 1px rgba(209, 209, 209, 0.7)',

            '&:hover': {
                borderColor: '#7F9FA8'
            }
        }
    },
    singleValue: (styles: any) => ({
        ...styles,
        fontSize: '1.2rem',
        fontFamily: '"Montserrat", sans-serif',
        fontWeight: 600,
    }),
    menuList: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            fontSize: '1.2rem',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            letterSpacing: '1px',
            color: 'rgba(2, 2, 2, 0.7)'
        }
    },
    option: (styles: any) => ({
        ...styles,
        '&:hover': {
            backgroundColor: '#7F9FA8',
            color: '#fff'
        },
        '&:focused': {
            backgroundColor: '#7F9FA8',
            color: '#fff'
        },
        '&:selected': {
            backgroundColor: '#7F9FA8',
            color: '#fff'
        }
    }),
    multiValue: (styles: any) => ({
        ...styles,
        backgroundColor: '#fff',
        border: '1px solid rgba(209, 209, 209, 0.7)'
    }),
    multiValueLabel: (styles: any) => ({
        ...styles,
        fontSize: '1.2rem',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        letterSpacing: '1px',
        color: 'rgba(2, 2, 2, 0.7)',
        padding: '7px 6px'
    }),
};

class OptionsSelect extends Component<IProps, IState> {
    state = {
        error: false
    };

    handleChange = (selectedOptions: Array<ISelectOption> | ISelectOption) => {
        let error = false;

        const selOptions = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];

        if (this.props.maxSelected && selOptions && selOptions.length > this.props.maxSelected) {
            error = true;
        } else {
            this.props.changed(this.props.name, selOptions || []);
        }

        this.setState({
            error
        });
    };

    render() {
        const {
            name,
            lined,
            label,
            minSelected,
            options,
            selected,
            maxSelected,
            allowCreation,
            instructionIds
        } = this.props;
        const { error } = this.state;

        return (
            <div
                className={[
                    classes.OptionsSelect,
                    lined ? classes['OptionsSelect--lined'] : ''
                ].join(' ')}
            >
                <div className={classes['OptionsSelect-header']}>
                    <label className={classes['OptionsSelect-label']}>{label}</label>
                    {minSelected && (
                        <span className={classes['OptionsSelect-required']}>
                            <FormattedMessage id={'general.required'} />
                        </span>
                    )}
                    {error && (
                        <span className={classes['OptionsSelect-error']}>
                            <FormattedMessage
                                id="error.maxOptions"
                                values={{
                                    max: maxSelected
                                }}
                            />
                        </span>
                    )}
                </div>
                <div className={classes['OptionsSelect-content']}>
                    <div className={classes['Selector-wrapper']}>
                        {allowCreation ? (
                            <CreatableSelect
                                isClearable
                                placeholder={<FormattedMessage id="general.choose"/>}
                                styles={customStyles}
                                value={selected}
                                onChange={this.handleChange}
                                options={options}
                            />
                        ) : (
                            <Select
                                placeholder={<FormattedMessage id="general.choose"/>}
                                styles={customStyles}
                                value={selected}
                                isMulti={true}
                                name={name}
                                onChange={this.handleChange}
                                options={options}
                            />
                        )}
                    </div>
                    {instructionIds && <Instruction textIds={instructionIds} />}
                </div>
            </div>
        );
    }
}

export default OptionsSelect;
