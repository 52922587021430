import React from 'react';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import RoundImage from '../UI/RoundImage';
import { IUser } from '../../shared/interfaces';
import Button from '../UI/Button';

interface IProps {
    currentUser: IUser;
    onEditClicked(): void;
}

const UserCard = (props: IProps) => {
    const { currentUser, onEditClicked } = props;

    const userPicture =
        currentUser && currentUser.fileCodeImage
            ? currentUser.fileCodeImage.childrens[1].url
            : null;

    return currentUser ? (
        <div className={classes['UserCard']}>
            <div className={classes['UserCard-content']}>
                <div className={classes['UserCard-header']}>
                    <div className={classes['UserCard-logo']}>
                        <RoundImage src={userPicture} />
                    </div>
                    <Button type={'edit'} clicked={onEditClicked}>
                        <FormattedMessage id={'general.edit'} />
                    </Button>
                </div>
                <div className={classes['UserCard-name']}>{currentUser.data.publishingHouse}</div>
            </div>
        </div>
    ) : null;
};

export default UserCard;
