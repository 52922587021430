// REGISTRATION
export const REGISTRATION_START = 'REGISTRATION_START';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';

export const REGISTRATION_FOR_SCHOOL_START = 'REGISTRATION_FOR_SCHOOL_START';
export const REGISTRATION_FOR_SCHOOL_SUCCESS = 'REGISTRATION_FOR_SCHOOL_SUCCESS';
export const REGISTRATION_FOR_SCHOOL_FAIL = 'REGISTRATION_FOR_SCHOOL_FAIL';

// SESSION
export const SESSION_START = 'SESSION_START';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_FAIL = 'SESSION_FAIL';

export const FETCH_ME_START = 'FETCH_ME_START';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAIL = 'FETCH_ME_FAIL';

export const SESSION_SIGNOUT_SUCCESS = 'SESSION_SIGNOUT_SUCCESS';

// ACTIVATION
export const ACTIVATION_START = 'ACTIVATION_START';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';

// USERS
export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAIL = 'FETCH_CURRENT_USER_FAIL';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_AUTHORS_BY_USER_START = 'FETCH_AUTHORS_BY_USER_START';
export const FETCH_AUTHORS_BY_USER_SUCCESS = 'FETCH_AUTHORS_BY_USER_SUCCESS';
export const FETCH_AUTHORS_BY_USER_FAIL = 'FETCH_AUTHORS_BY_USER_FAIL';

export const FETCH_USER_BY_ID_START = 'FETCH_USER_BY_ID_START';
export const FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS';
export const FETCH_USER_BY_ID_FAIL = 'FETCH_USER_BY_ID_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const RECOVERY_USER_PASSWORD_START = 'RECOVERY_USER_PASSWORD_START';
export const RECOVERY_USER_PASSWORD_SUCCESS = 'RECOVERY_USER_PASSWORD_SUCCESS';
export const RECOVERY_USER_PASSWORD_FAIL = 'RECOVERY_USER_PASSWORD_FAIL';

export const UPDATE_USER_PASSWORD_START = 'UPDATE_USER_PASSWORD_START';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAIL = 'UPDATE_USER_PASSWORD_FAIL';

export const RESET_USER_SUCCESS = 'RESET_USER_SUCCESS';

// FILES
export const CREATE_FILE_START = 'CREATE_FILE_START';
export const CREATE_FILE_SUCCESS = 'CREATE_FILE_SUCCESS';
export const CREATE_FILE_FAIL = 'CREATE_FILE_FAIL';

export const FETCH_FILE_START = 'FETCH_FILE_START';
export const FETCH_FILE_SUCCESS = 'FETCH_FILE_SUCCESS';
export const FETCH_FILE_FAIL = 'FETCH_FILE_FAIL';

export const RESET_FILE = 'RESET_FILE';

// MEDIA
export const CREATE_MEDIA_START = 'CREATE_MEDIA_START';
export const CREATE_MEDIA_SUCCESS = 'CREATE_MEDIA_SUCCESS';
export const CREATE_MEDIA_FAIL = 'CREATE_MEDIA_FAIL';

export const FETCH_MEDIA_LIST_START = 'FETCH_MEDIA_LIST_START';
export const FETCH_MEDIA_LIST_SUCCESS = 'FETCH_MEDIA_LIST_SUCCESS';
export const FETCH_MEDIA_LIST_FAIL = 'FETCH_MEDIA_LIST_FAIL';

export const FETCH_MEDIA_START = 'FETCH_MEDIA_START';
export const FETCH_MEDIA_SUCCESS = 'FETCH_MEDIA_SUCCESS';
export const FETCH_MEDIA_FAIL = 'FETCH_MEDIA_FAIL';

export const UPDATE_MEDIA_START = 'UPDATE_MEDIA_START';
export const UPDATE_MEDIA_SUCCESS = 'UPDATE_MEDIA_SUCCESS';
export const UPDATE_MEDIA_FAIL = 'UPDATE_MEDIA_FAIL';

export const DELETE_MEDIA_START = 'DELETE_MEDIA_START';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAIL = 'DELETE_MEDIA_FAIL';

export const RESET_MEDIA = 'RESET_MEDIA';

// ATTACHMENTS
export const CREATE_ATTACHMENT_START = 'CREATE_ATTACHMENT_START';
export const CREATE_ATTACHMENT_SUCCESS = 'CREATE_ATTACHMENT_SUCCESS';
export const CREATE_ATTACHMENT_FAIL = 'CREATE_ATTACHMENT_FAIL';

export const FETCH_ATTACHMENTS_START = 'FETCH_ATTACHMENTS_START';
export const FETCH_ATTACHMENTS_SUCCESS = 'FETCH_ATTACHMENTS_SUCCESS';
export const FETCH_ATTACHMENTS_FAIL = 'FETCH_ATTACHMENTS_FAIL';

export const FETCH_ATTACHMENT_START = 'FETCH_ATTACHMENT_START';
export const FETCH_ATTACHMENT_SUCCESS = 'FETCH_ATTACHMENT_SUCCESS';
export const FETCH_ATTACHMENT_FAIL = 'FETCH_ATTACHMENT_FAIL';

export const UPDATE_ATTACHMENT_START = 'UPDATE_ATTACHMENT_START';
export const UPDATE_ATTACHMENT_SUCCESS = 'UPDATE_ATTACHMENT_SUCCESS';
export const UPDATE_ATTACHMENT_FAIL = 'UPDATE_ATTACHMENT_FAIL';

export const DELETE_ATTACHMENT_START = 'DELETE_ATTACHMENT_START';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAIL = 'DELETE_ATTACHMENT_FAIL';

export const RESET_ATTACHMENT = 'RESET_ATTACHMENT';

// NOTIFICATIONS
export const FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAIL = 'FETCH_NOTIFICATIONS_FAIL';
export const SET_READ_NOTIFICATION_START = 'SET_READ_NOTIFICATION_START';
export const SET_READ_NOTIFICATION_SUCCESS = 'SET_READ_NOTIFICATION_SUCCESS';
export const SET_READ_NOTIFICATION_FAIL = 'SET_READ_NOTIFICATION_FAIL';
export const SET_ALL_READ_NOTIFICATION_BY_USER_START = 'SET_ALL_READ_NOTIFICATION_BY_USER_START';
export const SET_ALL_READ_NOTIFICATION_BY_USER_SUCCESS =
  'SET_ALL_READ_NOTIFICATION_BY_USER_SUCCESS';
export const SET_ALL_READ_NOTIFICATION_BY_USER_FAIL = 'SET_ALL_READ_NOTIFICATION_BY_USER_FAIL';

// GENRES
export const FETCH_GENRE_START = 'FETCH_GENRE_START';
export const FETCH_GENRE_SUCCESS = 'FETCH_GENRE_SUCCESS';
export const FETCH_GENRE_FAIL = 'FETCH_GENRE_FAIL';
export const FETCH_GENRES_START = 'FETCH_GENRES_START';
export const FETCH_GENRES_SUCCESS = 'FETCH_GENRES_SUCCESS';
export const FETCH_GENRES_FAIL = 'FETCH_GENRES_FAIL';

// TRANSLATIONS
export const FETCH_TRANSLATION_START = 'FETCH_TRANSLATION_START';
export const FETCH_TRANSLATION_SUCCESS = 'FETCH_TRANSLATION_SUCCESS';
export const FETCH_TRANSLATION_FAIL = 'FETCH_TRANSLATION_FAIL';
export const FETCH_TRANSLATIONS_START = 'FETCH_TRANSLATIONS_START';
export const FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_FAIL = 'FETCH_TRANSLATIONS_FAIL';

// TARGETS
export const FETCH_TARGET_START = 'FETCH_TARGET_START';
export const FETCH_TARGET_SUCCESS = 'FETCH_TARGET_SUCCESS';
export const FETCH_TARGET_FAIL = 'FETCH_TARGET_FAIL';
export const FETCH_TARGETS_START = 'FETCH_TARGETS_START';
export const FETCH_TARGETS_SUCCESS = 'FETCH_TARGETS_SUCCESS';
export const FETCH_TARGETS_FAIL = 'FETCH_TARGETS_FAIL';

// TONES
export const FETCH_TONE_START = 'FETCH_TONE_START';
export const FETCH_TONE_SUCCESS = 'FETCH_TONE_SUCCESS';
export const FETCH_TONE_FAIL = 'FETCH_TONE_FAIL';
export const FETCH_TONES_START = 'FETCH_TONES_START';
export const FETCH_TONES_SUCCESS = 'FETCH_TONES_SUCCESS';
export const FETCH_TONES_FAIL = 'FETCH_TONES_FAIL';

// FORMATS
export const FETCH_FORMAT_START = 'FETCH_FORMAT_START';
export const FETCH_FORMAT_SUCCESS = 'FETCH_FORMAT_SUCCESS';
export const FETCH_FORMAT_FAIL = 'FETCH_FORMAT_FAIL';
export const FETCH_FORMATS_START = 'FETCH_FORMATS_START';
export const FETCH_FORMATS_SUCCESS = 'FETCH_FORMATS_SUCCESS';
export const FETCH_FORMATS_FAIL = 'FETCH_FORMATS_FAIL';

// AWARDS
export const FETCH_AWARD_START = 'FETCH_AWARD_START';
export const FETCH_AWARD_SUCCESS = 'FETCH_AWARD_SUCCESS';
export const FETCH_AWARD_FAIL = 'FETCH_AWARD_FAIL';
export const FETCH_AWARDS_START = 'FETCH_AWARDS_START';
export const FETCH_AWARDS_SUCCESS = 'FETCH_AWARDS_SUCCESS';
export const FETCH_AWARDS_FAIL = 'FETCH_AWARDS_FAIL';

// FEATURES
export const FETCH_FEATURE_START = 'FETCH_FEATURE_START';
export const FETCH_FEATURE_SUCCESS = 'FETCH_FEATURE_SUCCESS';
export const FETCH_FEATURE_FAIL = 'FETCH_FEATURE_FAIL';
export const FETCH_FEATURES_START = 'FETCH_FEATURES_START';
export const FETCH_FEATURES_SUCCESS = 'FETCH_FEATURES_SUCCESS';
export const FETCH_FEATURES_FAIL = 'FETCH_FEATURES_FAIL';

// TITLES
export const CREATE_TITLE_START = 'CREATE_TITLE_START';
export const CREATE_TITLE_SUCCESS = 'CREATE_TITLE_SUCCESS';
export const CREATE_TITLE_FAIL = 'CREATE_TITLE_FAIL';

export const FETCH_TITLES_START = 'FETCH_TITLES_START';
export const FETCH_TITLES_SUCCESS = 'FETCH_TITLES_SUCCESS';
export const FETCH_TITLES_FAIL = 'FETCH_TITLES_FAIL';

export const FETCH_TITLE_START = 'FETCH_TITLE_START';
export const FETCH_TITLE_SUCCESS = 'FETCH_TITLE_SUCCESS';
export const FETCH_TITLE_FAIL = 'FETCH_TITLE_FAIL';

export const FETCH_MY_TITLES_START = 'FETCH_MY_TITLES_START';
export const FETCH_MY_TITLES_SUCCESS = 'FETCH_MY_TITLES_SUCCESS';
export const FETCH_MY_TITLES_FAIL = 'FETCH_MY_TITLES_FAIL';

export const FETCH_TITLES_BY_USER_START = 'FETCH_TITLES_BY_USER_START';
export const FETCH_TITLES_BY_USER_SUCCESS = 'FETCH_TITLES_BY_USER_SUCCESS';
export const FETCH_TITLES_BY_USER_FAIL = 'FETCH_TITLES_BY_USER_FAIL';

export const UPDATE_TITLE_START = 'UPDATE_TITLE_START';
export const UPDATE_TITLE_SUCCESS = 'UPDATE_TITLE_SUCCESS';
export const UPDATE_TITLE_FAIL = 'UPDATE_TITLE_FAIL';

export const DELETE_TITLE_START = 'DELETE_TITLE_START';
export const DELETE_TITLE_SUCCESS = 'DELETE_TITLE_SUCCESS';
export const DELETE_TITLE_FAIL = 'DELETE_TITLE_FAIL';

export const RESET_MY_LAST_TITLE = 'RESET_MY_LAST_TITLE';
export const RESET_MY_TITLES = 'RESET_MY_TITLES';

export const CREATE_PERSONAGE_FOR_TITLE_START = 'CREATE_PERSONAGE_FOR_TITLE_START';
export const CREATE_PERSONAGE_FOR_TITLE_SUCCESS = 'CREATE_PERSONAGE_FOR_TITLE_SUCCESS';
export const CREATE_PERSONAGE_FOR_TITLE_FAIL = 'CREATE_PERSONAGE_FOR_TITLE_FAIL';

export const DELETE_PERSONAGE_FOR_TITLE_START = 'DELETE_PERSONAGE_FOR_TITLE_START';
export const DELETE_PERSONAGE_FOR_TITLE_SUCCESS = 'DELETE_PERSONAGE_FOR_TITLE_SUCCESS';
export const DELETE_PERSONAGE_FOR_TITLE_FAIL = 'DELETE_PERSONAGE_FOR_TITLE_FAIL';

export const CREATE_AUTHOR_FOR_TITLE_START = 'CREATE_AUTHOR_FOR_TITLE_START';
export const CREATE_AUTHOR_FOR_TITLE_SUCCESS = 'CREATE_AUTHOR_FOR_TITLE_SUCCESS';
export const CREATE_AUTHOR_FOR_TITLE_FAIL = 'CREATE_AUTHOR_FOR_TITLE_FAIL';

export const DELETE_AUTHOR_FOR_TITLE_START = 'DELETE_AUTHOR_FOR_TITLE_START';
export const DELETE_AUTHOR_FOR_TITLE_SUCCESS = 'DELETE_AUTHOR_FOR_TITLE_SUCCESS';
export const DELETE_AUTHOR_FOR_TITLE_FAIL = 'DELETE_AUTHOR_FOR_TITLE_FAIL';

export const SORT_TITLES_START = 'SORT_TITLES_START';
export const SORT_TITLES_SUCCESS = 'SORT_TITLES_SUCCESS';
export const SORT_TITLES_FAIL = 'SORT_TITLES_FAIL';

// PERSONAGE
export const UPDATE_PERSONAGE_START = 'UPDATE_PERSONAGE_START';
export const UPDATE_PERSONAGE_SUCCESS = 'UPDATE_PERSONAGE_SUCCESS';
export const UPDATE_PERSONAGE_FAIL = 'UPDATE_PERSONAGE_FAIL';

// AUTHOR
export const UPDATE_AUTHOR_START = 'UPDATE_AUTHOR_START';
export const UPDATE_AUTHOR_SUCCESS = 'UPDATE_AUTHOR_SUCCESS';
export const UPDATE_AUTHOR_FAIL = 'UPDATE_AUTHOR_FAIL';

// MESSAGES
export const CREATE_MESSAGE_START = 'CREATE_MESSAGE_START';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAIL = 'CREATE_MESSAGE_FAIL';

export const FETCH_MESSAGES_BY_USER_SENDER_START = 'FETCH_MESSAGES_BY_USER_SENDER_START';
export const FETCH_MESSAGES_BY_USER_SENDER_SUCCESS = 'FETCH_MESSAGES_BY_USER_SENDER_SUCCESS';
export const FETCH_MESSAGES_BY_USER_SENDER_FAIL = 'FETCH_MESSAGES_BY_USER_SENDER_FAIL';

export const FETCH_MESSAGES_BY_USER_DESTINATION_START = 'FETCH_MESSAGES_BY_USER_DESTINATION_START';
export const FETCH_MESSAGES_BY_USER_DESTINATION_SUCCESS =
  'FETCH_MESSAGES_BY_USER_DESTINATION_SUCCESS';
export const FETCH_MESSAGES_BY_USER_DESTINATION_FAIL = 'FETCH_MESSAGES_BY_USER_DESTINATION_FAIL';

export const FETCH_INTERLOCUTORS_START = 'FETCH_INTERLOCUTORS_START';
export const FETCH_INTERLOCUTORS_SUCCESS = 'FETCH_INTERLOCUTORS_SUCCESS';
export const FETCH_INTERLOCUTORS_FAIL = 'FETCH_INTERLOCUTORS_FAIL';

export const FETCH_CONVERSATION_START = 'FETCH_CONVERSATION_START';
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const FETCH_CONVERSATION_FAIL = 'FETCH_CONVERSATION_FAIL';

export const SET_READ_MESSAGE_START = 'SET_READ_MESSAGE_START';
export const SET_READ_MESSAGE_SUCCESS = 'SET_READ_MESSAGE_SUCCESS';
export const SET_READ_MESSAGE_FAIL = 'SET_READ_MESSAGE_FAIL';

export const FETCH_UNREAD_MESSAGES_BY_USER_START = 'FETCH_UNREAD_MESSAGES_BY_USER_START';
export const FETCH_UNREAD_MESSAGES_BY_USER_SUCCESS = 'FETCH_UNREAD_MESSAGES_BY_USER_SUCCESS';
export const FETCH_UNREAD_MESSAGES_BY_USER_FAIL = 'FETCH_UNREAD_MESSAGES_BY_USER_FAIL';

export const RESET_CONVERSATION = 'RESET_CONVERSATION';

// AUTH
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
