import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import Text from '../../components/UI/Text';

interface IProps {
    history: History;
}

class Support extends Component<IProps> {
    render() {
        return (
            <main className={classes.Support}>

                <div className={classes['Support-header']}>
                    <Text
                        tag={'h2'}
                        marginBottom={30}
                        weight={400}
                        transform={'uppercase'}
                        spacing={true}
                    >
                        <span>
                            <FormattedMessage id="support.subtitle" />
                        </span>
                    </Text>
                </div>

                <div className={classes['Support-content']}>
                    <div className={classes['Support-content-text']}>
                        <FormattedMessage id="support.text" />
                    </div>

                    <div className={classes['Support-blocks']}>
                        <a 
                            href="tel:+393270307801"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={[classes['Support-block'], classes['Support-block--phone']].join(' ')}>
                            <em>Telefono: <strong>+39 327 030 7801</strong></em>
                        </a>
                        <a
                            href="https://wa.me/3270307801"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={[classes['Support-block'], classes['Support-block--wa']].join(' ')}>
                            <em>Whatsapp: <strong>+39 327 030 7801</strong></em>
                        </a>
                        <a
                            href="mailto:a.schiavoni@artchivio.net"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={[classes['Support-block'], classes['Support-block--email']].join(' ')}>
                            <em>E-mail: <strong>a.schiavoni@artchivio.net</strong></em>
                        </a>
                    </div>
                </div>
            </main>
        );
    }
}

export default Support;
