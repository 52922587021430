import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import {
    IChecker,
    IInput,
    IOption,
    IRegistration,
    ISelector,
    ISwitch
} from '../../shared/interfaces';
import { resetControl, resetSelector, resetSwitch } from '../../shared/helpers/forms';
import classes from './styles.module.scss';
import Input from '../Field/Input';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import FeedbackMessage from '../UI/FeedbackMessage';
import Switch from '../Field/Switch';
import Selector from '../Field/Selector';
import AddressAutocomplete from '../Field/AddressAutocomplete';
import { parseAddressComponents } from '../../shared/helpers/utilities';
import { checkRegistrationFields } from '../../shared/helpers/checks';

interface IProps {
    registration: IRegistration;
    waitingForRegistration?: boolean;
    error?: string;
    onRegistration(data: IRegistration): IRegistration;
    onSuccessfullyRegistration(): void;
}

interface IState {
    lat: number;
    lng: number;
    cap: string;
    country: string;
    region: string;
    city: string;
    province: string;
    controls: {
        publishingHouse: IInput;
        name: IInput;
        surname: IInput;
        email: IInput;
        password: IInput;
        address: IInput;
        website: IInput;
        phoneNumber: IInput;
        vatNumber: IInput;
    };
    checkboxes: {
        adaptationAlreadySold: ISwitch;
        terms: ISwitch;
        privacy: ISwitch;
    };
    selectors: {
        employeesNumber: ISelector;
    };
    error: string;
    saveCompleted: boolean;
    fieldsCheckerList: Array<IChecker>;
    showCheckerDialog: boolean;
}

class PublisherRegistrationForm extends Component<IProps, IState> {
    state = {
        fieldsCheckerList: [],
        showCheckerDialog: false,
        lat: null,
        lng: null,
        cap: '',
        country: '',
        region: '',
        city: '',
        province: '',
        controls: {
            publishingHouse: {
                name: 'publishingHouse',
                value: '',
                type: 'text',
                placeholderId: 'placeholder.publishingHouse',
                labelId: 'label.publishingHouse',
                required: true
            },
            name: {
                name: 'name',
                value: '',
                type: 'text',
                placeholderId: 'placeholder.refName',
                labelId: 'label.refName',
                required: true
            },
            surname: {
                name: 'surname',
                labelId: 'label.refSurname',
                value: '',
                type: 'text',
                placeholderId: 'placeholder.refSurname',
                required: true
            },
            email: {
                name: 'email',
                labelId: 'label.refEmail',
                value: '',
                type: 'email',
                placeholderId: 'placeholder.refEmail',
                required: true
            },
            password: {
                name: 'password',
                labelId: 'label.password',
                value: '',
                type: 'password',
                placeholderId: 'placeholder.password',
                required: true
            },
            address: {
                name: 'address',
                labelId: 'label.address',
                value: '',
                type: 'geo',
                placeholderId: 'placeholder.address'
            },
            website: {
                name: 'website',
                labelId: 'label.website',
                value: '',
                type: 'url',
                placeholderId: 'placeholder.website'
            },
            phoneNumber: {
                name: 'phoneNumber',
                labelId: 'label.phoneNumber',
                value: '',
                type: 'phone',
                placeholderId: 'placeholder.phoneNumber'
            },
            vatNumber: {
                name: 'vatNumber',
                labelId: 'label.vatNumber',
                value: '',
                type: 'text',
                placeholderId: 'placeholder.vatNumber',
                required: true
            }
        },
        checkboxes: {
            adaptationAlreadySold: {
                name: 'adaptationAlreadySold',
                labelId: 'registration.adaptationAlreadySold',
                checked: false
            },
            terms: {
                name: 'terms',
                labelId: 'registration.terms',
                checked: false,
                required: true
            },
            privacy: {
                name: 'privacy',
                labelId: 'registration.privacy',
                checked: false,
                required: true
            }
        },
        selectors: {
            employeesNumber: {
                labelId: 'label.employeesNumber',
                name: 'employeesNumber',
                options: [
                    {
                        id: 1,
                        label: '1 - 5',
                        selected: false
                    },
                    {
                        id: 2,
                        label: '5 - 10',
                        selected: false
                    },
                    {
                        id: 3,
                        label: '10+',
                        selected: false
                    }
                ],
                multiple: true,
                minSelected: 1
            }
        },
        error: '',
        saveCompleted: false
    };

    handleGeoChanged = (
        name: string,
        value: { formatted_address; lat; lng },
        addressComponents: any
    ) => {
        let controls: any = { ...this.state.controls };

        let addressComponentsData = addressComponents
            ? parseAddressComponents(addressComponents, value.formatted_address)
            : null;

        //console.log(addressComponentsData);
        if (addressComponentsData) {
            this.setState({
                city: addressComponentsData.city,
                cap: addressComponentsData.postalCode,
                country: addressComponentsData.country,
                province: addressComponentsData.province
            });
        }

        controls[name].value = value.formatted_address;

        this.setState({
            lat: value.lat,
            lng: value.lng,
            controls,
            error: ''
        });
    };

    handleInputChanged = (name: string, value: string) => {
        let controls: any = { ...this.state.controls };

        controls[name].value = value;

        this.setState({
            controls,
            error: ''
        });
    };

    handleCheckboxChanged = (name: string, checked: boolean) => {
        let checkboxes: any = { ...this.state.checkboxes };

        checkboxes[name].checked = checked;

        this.setState({
            checkboxes
        });
    };

    handleSelectorClicked = (name: string, index: number, selected: boolean): void => {
        let selectors: any = { ...this.state.selectors };

        if (selectors[name].multiple) {
            selectors[name].options = selectors[name].options.map((item: IOption) => {
                return {
                    ...item,
                    selected: false
                };
            });
        }

        selectors[name].options[index].selected = selected;

        this.setState({
            selectors
        });
    };

    resetRegistration = () => {
        let controls = Object.values({ ...this.state.controls }).map((item: IInput) => {
            return {
                [item.name]: resetControl(item)
            };
        });

        let checkboxes = Object.values({ ...this.state.checkboxes }).map((item: ISwitch) => {
            return {
                [item.name]: resetSwitch(item)
            };
        });

        let selectors = Object.values({ ...this.state.selectors }).map((item: ISelector) => {
            return {
                [item.name]: resetSelector(item)
            };
        });

        this.setState({
            controls: Object.assign({}, ...controls),
            checkboxes: Object.assign({}, ...checkboxes),
            selectors: Object.assign({}, ...selectors),
            saveCompleted: true
        });
    };

    handleSubmitClicked = () => {
        const {
            lat,
            lng,
            cap,
            country,
            region,
            city,
            controls: {
                publishingHouse,
                name,
                surname,
                email,
                password,
                address,
                website,
                phoneNumber,
                vatNumber
            },
            checkboxes: { terms, privacy },
            selectors: { employeesNumber }
        } = this.state;

        const employeesNumberValue = employeesNumber.options.find((item: IOption) => item.selected);

        const model: IRegistration = {
            email: email.value,
            name: name.value,
            surname: surname.value,
            role: 'publisher',
            address: address.value,
            password: password.value,
            cap,
            city,
            region,
            country,
            data: {
                publishingHouse: publishingHouse.value,
                website: website.value,
                phoneNumber: phoneNumber.value,
                vatNumber: vatNumber.value,
                adaptationAlreadySold: true,
                employeesNumber: employeesNumberValue ? employeesNumberValue.label : '',
                terms: terms.checked,
                privacy: privacy.checked,
                addressLat: lat,
                addressLng: lng
            }
        };

        let fieldsToCheck = checkRegistrationFields(model);

        if (!fieldsToCheck.length) {
            this.props.onRegistration(model);
        } else {
            this.setState({
                fieldsCheckerList: fieldsToCheck,
                showCheckerDialog: fieldsToCheck.length > 0
            });
        }
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({
                error:
                    typeof this.props.error === 'string' &&
                    this.props.error.toLowerCase().includes('already exists')
                        ? `Sembra che l'email ${this.state.controls.email.value} sia già stata registrata!`
                        : this.props.error
            });
        }
        if (this.props.registration !== prevProps.registration && this.props.registration) {
            this.resetRegistration();
        }
        if (this.state.saveCompleted !== prevState.saveCompleted && this.state.saveCompleted) {
            this.props.onSuccessfullyRegistration();
        }
    }

    render() {
        const {
            controls,
            checkboxes,
            selectors,
            error,
            showCheckerDialog,
            fieldsCheckerList
        } = this.state;

        const { waitingForRegistration } = this.props;

        const inputElementsTpl = Object.values(controls).map((item: IInput, index: number) => {
            return (
                <div key={index} className={classes['PublisherRegistrationForm-field']}>
                    {item.type === 'geo' ? (
                        <AddressAutocomplete
                            changed={this.handleGeoChanged}
                            name={item.name}
                            placeholderId={item.placeholderId}
                            value={item.value}
                            label={item.labelId ? <FormattedMessage id={item.labelId} /> : ''}
                        />
                    ) : (
                        <Input
                            label={item.labelId ? <FormattedMessage id={item.labelId} /> : ''}
                            required={item.required}
                            type={item.type}
                            name={item.name}
                            placeholderId={item.placeholderId}
                            value={item.value}
                            changed={this.handleInputChanged}
                        />
                    )}
                </div>
            );
        });

        return (
            <div className={classes.PublisherRegistrationForm}>
                {inputElementsTpl}
                <div className={classes['PublisherRegistrationForm-field']}>
                    <Switch
                        name={checkboxes.adaptationAlreadySold.name}
                        changed={this.handleCheckboxChanged}
                        label={<FormattedMessage id="label.adaptationAlreadySold" />}
                        checked={checkboxes.adaptationAlreadySold.checked}
                    />
                </div>
                <div className={classes['PublisherRegistrationForm-field']}>
                    <Selector
                        label={
                            selectors.employeesNumber.labelId ? (
                                <FormattedMessage id={selectors.employeesNumber.labelId} />
                            ) : (
                                ''
                            )
                        }
                        name={selectors.employeesNumber.name}
                        clicked={this.handleSelectorClicked}
                        options={selectors.employeesNumber.options}
                    />
                </div>
                <div className={classes['PublisherRegistrationForm-field']}>
                    <Switch
                        name={checkboxes.terms.name}
                        changed={this.handleCheckboxChanged}
                        label={
                            <FormattedMessage
                                id="label.terms"
                                values={{
                                    link: (
                                        <a
                                            rel="noopener noreferrer"
                                            href={'/terms'}
                                            target={'_blank'}
                                        >
                                            <FormattedMessage id={'link.terms'} />
                                        </a>
                                    )
                                }}
                            />
                        }
                        checked={checkboxes.terms.checked}
                    />
                </div>
                <div className={classes['PublisherRegistrationForm-field']}>
                    <Switch
                        name={checkboxes.privacy.name}
                        changed={this.handleCheckboxChanged}
                        label={
                            <FormattedMessage
                                id="label.privacy"
                                values={{
                                    link: (
                                        <a
                                            rel="noopener noreferrer"
                                            href={'/privacy'}
                                            target={'_blank'}
                                        >
                                            <FormattedMessage id={'link.privacy'} />
                                        </a>
                                    )
                                }}
                            />
                        }
                        checked={checkboxes.privacy.checked}
                    />
                </div>
                <FeedbackMessage
                    size={'md'}
                    marginTop={20}
                    marginBottom={10}
                    visible={error !== null && error !== ''}
                    type={'error'}
                >
                    {`${error}`}
                </FeedbackMessage>

                {showCheckerDialog && (
                    <div
                        className={[
                            classes.CheckerFields,
                            classes['PublisherRegistrationForm-feedbacks']
                        ].join(' ')}
                    >
                        <h5>
                            <FormattedMessage id={'title.checker.registration'} />
                        </h5>
                        <ul>
                            {fieldsCheckerList.map((item: IChecker, index: number) => (
                                <li key={index}>
                                    <FormattedMessage id={item.field} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className={classes['PublisherRegistrationForm-footer']}>
                    <Button disabled={false} clicked={this.handleSubmitClicked}>
                        <FormattedMessage id={'general.createAccount'} />
                    </Button>
                </div>

                <div className={classes['PublisherRegistrationForm-loader']}>
                    {waitingForRegistration && <Loader theme={'light'} size="sm" fullContent />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        registration: state.registrationState.registration,
        waitingForRegistration: state.registrationState.isCreating,
        error: state.registrationState.error
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onRegistration: (data: IRegistration) => dispatch(actions.registration(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublisherRegistrationForm);
