import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as routes from '../../shared/routes';

// PrivateRoute
import PrivateRoute from '../../hocs/PrivateRoute';

// Routes
// import Homepage from '../../routes/Homepage';
import Titles from '../../routes/Titles';
import Title from '../../routes/Title';
import Registration from '../../routes/Registration';
import Publisher from '../../routes/Publisher';
import Terms from '../../routes/Terms';
import Privacy from '../../routes/Privacy';
import ThankYou from '../../routes/ThankYou';
import Dashboard from '../../routes/Dashboard';
import Support from '../../routes/Support';
import Offers from '../../routes/Offers';
import ErrorPage from '../../routes/ErrorPage';
import ShowTitle from '../../routes/ShowTitle';
import constants from '../../shared/constants';
import Activation from '../../routes/Activation';
import Recovery from '../../routes/Recovery';

const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

class RouterWrapper extends Component {
    render() {
        return (
            <Switch>
                <Route exact={true} path={routes.HOMEPAGE} component={Registration} />
                <Route exact={true} path={routes.ACTIVATION} component={Activation} />
                <Route exact={true} path={routes.REGISTRATION} component={Registration} />
                <Route exact={true} path={routes.TERMS} component={Terms} />
                <Route exact={true} path={routes.PRIVACY} component={Privacy} />
                <Route exact={true} path={routes.THANK_YOU} component={ThankYou} />
                <Route exact={true} path={routes.ERROR_PAGE} component={ErrorPage} />
                <Route exact={true} path={routes.RECOVERY} component={Recovery} />
                <PrivateRoute exact={true} path={routes.PUBLISHER} component={Publisher} />
                <PrivateRoute exact={true} path={routes.DASHBOARD} component={Dashboard} />
                <PrivateRoute exact={true} path={routes.TITLES} component={Titles} />
                <PrivateRoute exact={true} path={routes.OFFERS} component={Offers} />
                <PrivateRoute exact={true} path={routes.TITLE} component={Title} />
                <PrivateRoute exact={true} path={routes.SUPPORT} component={Support} />
                <PrivateRoute exact={true} path={routes.PREVIEW} component={ShowTitle} />
                <Redirect to={authToken ? routes.PUBLISHER : routes.HOMEPAGE} />
            </Switch>
        );
    }
}

export default RouterWrapper;
