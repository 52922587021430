import React from 'react';
import classes from './SocialItem.module.scss';

interface socialItemProps {
    href: string,
    title?: string,
    name?: string,
    target?: string,
    rel?: string,
    children: any,
}

const link = ({ href, title, name, target, rel, children}: socialItemProps) => {

    const socialItemClass = [classes.SocialItem, name ? classes[`SocialItem--${name}`] : ''].join(' ');

    return <a rel={rel} href={href} title={title || `Follow us`} target={target || '_blank'} className={socialItemClass}><span>{ children }</span></a>;
};

export default link;