import { combineReducers } from 'redux';
import userReducer from './user';
import registrationReducer from './registration';
import sessionReducer from './session';
import fileReducer from './file';
import notificationReducer from './notification';
import messageReducer from './message';
import titleReducer from './title';
import genreReducer from './genre';
import awardReducer from './award';
import targetReducer from './target';
import translationReducer from './translation';
import toneReducer from './tone';
import featureReducer from './feature';
import formatReducer from './format';

export const rootReducer = combineReducers({
    userState: userReducer,
    registrationState: registrationReducer,
    sessionState: sessionReducer,
    fileState: fileReducer,
    notificationState: notificationReducer,
    messageState: messageReducer,
    titleState: titleReducer,
    genreState: genreReducer,
    awardState: awardReducer,
    translationState: translationReducer,
    targetState: targetReducer,
    toneState: toneReducer,
    featureState: featureReducer,
    formatState: formatReducer
});
