import * as actionTypes from '../actions/actionTypes';
import { IServerOption } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    feature: IServerOption;
    features: Array<IServerOption>;
    error: string;
}

const INITIAL_STATE = {
    item: null,
    items: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null
};

const fetchFeatureStart = (state = INITIAL_STATE) => {
    return updateObject(state, { item: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchFeatureSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { item: action.feature, isFetching: false });
};

const fetchFeatureFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchFeaturesStart = (state = INITIAL_STATE) => {
    return updateObject(state, { items: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchFeaturesSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { items: action.features, isFetching: false });
};

const fetchFeaturesFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const featureReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // READ/FETCHING
        case actionTypes.FETCH_FEATURE_START:
            return fetchFeatureStart(state);
        case actionTypes.FETCH_FEATURE_SUCCESS:
            return fetchFeatureSuccess(state, action);
        case actionTypes.FETCH_FEATURE_FAIL:
            return fetchFeatureFail(state, action);
        case actionTypes.FETCH_FEATURES_START:
            return fetchFeaturesStart(state);
        case actionTypes.FETCH_FEATURES_SUCCESS:
            return fetchFeaturesSuccess(state, action);
        case actionTypes.FETCH_FEATURES_FAIL:
            return fetchFeaturesFail(state, action);

        default:
            return state;
    }
};

export default featureReducer;
