import * as actionTypes from './actionTypes';
import TonesService from '../../services/api/tones';
import { IError, IServerOption, IResponse } from '../../shared/interfaces';

// READ/FETCH
export const fetchToneStart = () => {
    return {
        type: actionTypes.FETCH_TONE_START
    };
};

export const fetchToneSuccess = (tone: IServerOption) => {
    return {
        type: actionTypes.FETCH_TONE_SUCCESS,
        tone
    };
};

export const fetchToneFail = (error: string) => {
    return {
        type: actionTypes.FETCH_TONE_FAIL,
        error
    };
};

export const fetchTone = (id: number) => {
    return (dispatch: any) => {
        if (id) dispatch(fetchToneStart());

        TonesService.get(id).then(
            (response: IResponse) => {
                dispatch(fetchToneSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchToneFail(errorMsg));
            }
        );
    };
};

export const fetchTonesStart = () => {
    return {
        type: actionTypes.FETCH_TONES_START
    };
};

export const fetchTonesSuccess = (tones: Array<IServerOption>) => {
    return {
        type: actionTypes.FETCH_TONES_SUCCESS,
        tones
    };
};

export const fetchTonesFail = (error: string) => {
    return {
        type: actionTypes.FETCH_TONES_FAIL,
        error
    };
};

export const fetchTones = () => {
    return (dispatch: any) => {
        dispatch(fetchTonesStart());

        TonesService.get().then(
            (response: IResponse) => {
                dispatch(fetchTonesSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchTonesFail(errorMsg));
            }
        );
    };
};
