import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '@formatjs/intl-pluralrules/polyfill';
import { IntlProvider } from 'react-intl';
import RouterWrapper from './containers/RouterWrapper';
import Header from './containers/Header';
import Footer from './containers/Footer';
import CookieBanner from './components/CookieBanner';

import '@formatjs/intl-pluralrules/dist/locale-data/it';
import '@formatjs/intl-pluralrules/dist/locale-data/en';

import itMessages from './translations/it.json';
import enMessages from './translations/en.json';

const messages: any = {
    en: enMessages,
    it: itMessages
};

let language = navigator.language.toLowerCase().split(/[_-]+/)[0];
if (!messages[language]) {
    language = Object.keys(messages)[0];
}

interface IState {
	cookieConsent: boolean;
}

// Test for english (remove comment on language)
//language = 'en';

class App extends Component<any, IState> {

    constructor(props: any) {
		super(props);

		this.state = {
			cookieConsent: false,
		}
    }

    componentDidMount(): void {
		this.checkCookieConsent();
	}

	componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
		this.checkCookieConsent();
	}

	checkCookieConsent = () => {
		const cookieConsent = localStorage.getItem('cookieConsent');
        if(cookieConsent === 'accept') {
            this.setState({
                cookieConsent: true
            })
        } else {
			this.setState({
                cookieConsent: false
            })
		}
    };
    
    render() {

        const {cookieConsent} = this.state;

        return (
            <IntlProvider
                defaultLocale="it"
                locale={language}
                messages={messages[language] || messages['it']}
            >
                <BrowserRouter>
                    <Header />
                    <RouterWrapper />
                    <CookieBanner show={cookieConsent} checkCookieConsent={this.checkCookieConsent} />
                    <Footer />
                    {/* <Route path="/" component={Footer} /> */}
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

export default App;
