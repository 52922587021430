import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import * as actions from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as routes from '../../shared/routes';
import { IUser } from '../../shared/interfaces';
import Loader from '../../components/UI/Loader';

interface IProps {
    isAuthenticated?: boolean;
    currentUser: IUser;
    fetchingCurrentUser: boolean;
    error: string;
    onGetCurrentUser(): IUser;
    history: History;
    children?: any;
}

interface IState {}

class AuthWrapper extends Component<IProps, IState> {
    componentDidMount(): void {
        if (!this.props.currentUser) this.props.onGetCurrentUser();
    }

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (
            this.props.isAuthenticated !== prevProps.isAuthenticated &&
            !this.props.isAuthenticated
        ) {
            this.props.history.push(routes.HOMEPAGE);
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            this.props.history.push(routes.ERROR_PAGE);
        }
    }

    render() {
        const { currentUser, children } = this.props;

        return (
            <div className={classes.AuthWrapper}>
                {currentUser ? children : <Loader fullPage={true} size={'sm'} />}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.userState.user,
        fetchingCurrentUser: state.userState.isFetching,
        error: state.userState.error,
        isAuthenticated: state.sessionState.authenticated
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetCurrentUser: () => dispatch(actions.fetchCurrentUser())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthWrapper));
