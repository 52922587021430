import * as actionTypes from '../actions/actionTypes';
import { IServerOption } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    translation: IServerOption;
    translations: Array<IServerOption>;
    error: string;
}

const INITIAL_STATE = {
    item: null,
    items: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null
};

const fetchTranslationStart = (state = INITIAL_STATE) => {
    return updateObject(state, { item: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchTranslationSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { item: action.translation, isFetching: false });
};

const fetchTranslationFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchTranslationsStart = (state = INITIAL_STATE) => {
    return updateObject(state, { items: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchTranslationsSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { items: action.translations, isFetching: false });
};

const fetchTranslationsFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const translationReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // READ/FETCHING
        case actionTypes.FETCH_TRANSLATION_START:
            return fetchTranslationStart(state);
        case actionTypes.FETCH_TRANSLATION_SUCCESS:
            return fetchTranslationSuccess(state, action);
        case actionTypes.FETCH_TRANSLATION_FAIL:
            return fetchTranslationFail(state, action);
        case actionTypes.FETCH_TRANSLATIONS_START:
            return fetchTranslationsStart(state);
        case actionTypes.FETCH_TRANSLATIONS_SUCCESS:
            return fetchTranslationsSuccess(state, action);
        case actionTypes.FETCH_TRANSLATIONS_FAIL:
            return fetchTranslationsFail(state, action);

        default:
            return state;
    }
};

export default translationReducer;
