import request from '../../shared/lib/request';
import constants from '../../shared/constants';

const get = (id?: number) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    const endpoint = id ? `/v2/tone/${id}` : '/v2/tone';

    return request({
        url: endpoint,
        method: 'GET',
        headers
    });
};

const TonesService = {
    get
};

export default TonesService;
