import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
    IInput,
    IOption,
    IReview,
    ISelector,
    IServerOption,
    ISwitch,
    ITextArea,
    ITitle
} from '../../../shared/interfaces';
import Input from '../../Field/Input';
import Selector from '../../Field/Selector';
import mainClasses from '../styles.module.scss';
import stepClasses from './styles.module.scss';
import Text from '../../UI/Text';
import Switch from '../../Field/Switch';
import TextAreaEditor from '../../Field/TextAreaEditor';
import Instruction from '../../UI/Instruction';
import constants from '../../../shared/constants';
import { isValidSecureURL } from '../../../shared/helpers/checks';

interface IProps {
    data: ITitle;
    setData(name: string, value: any): void;
    translations: Array<IServerOption>;
    awards: Array<IServerOption>;
}

interface IControls {
    reprints: IInput;
    commercialNews: ITextArea;
}

interface ISelectors {
    translations: ISelector;
    awards: ISelector;
    reprints: ISelector;
}

interface IState {
    controls: IControls;
    selectors: ISelectors;
    checkboxes: {
        commercialNews: ISwitch;
    };
    data: {
        reviews: Array<IReview>;
    };
}

class SalesData extends Component<IProps, IState> {
    state = {
        data: {
            reviews: []
        },
        controls: {
            reprints: {
                name: 'reprints',
                value: '',
                type: 'number',
                placeholderId: 'placeholder.reprints',
                labelId: 'label.reprints',
                instructionIds: ['instruction.reprint']
            },
            commercialNews: {
                isPlainText: true,
                toolbarHidden: true,
                autosize: true,
                name: 'commercialNews',
                value: '',
                type: 'textarea',
                placeholderId: 'placeholder.commercialNews'
            }
        },
        selectors: {
            translations: {
                labelId: 'label.translations',
                name: 'translations',
                options: [],
                minSelected: 1,
                multiple: true
            },
            awards: {
                labelId: 'label.awards',
                name: 'awards',
                options: [],
                minSelected: 1,
                multiple: true
            },
            reprints: {
                name: 'reprints',
                options: constants.REPRINTS_OPTIONS,
                minSelected: 0,
                labelId: 'label.reprints',
                instructionIds: ['instruction.reprint']
            }
        },
        checkboxes: {
            commercialNews: {
                name: 'commercialNews',
                labelId: 'registration.commercialNews',
                checked: false
            }
        }
    };

    componentDidMount(): void {
        this.setSelectorsOptions();

        this.initializeData();
    }

    initializeData = () => {
        const {
            data: { reprints, reviews, awards, translations, commercialNews }
        } = this.props;

        let data = { ...this.state.data };
        if (!reviews.length) {
            data.reviews = [];
        } else {
            data.reviews = reviews;
        }

        this.setState({
            data
        });

        if (awards) this.setSelectorValue('awards', awards);

        if (translations) this.setSelectorValue('translations', translations);

        if (reprints) {
            const item: IOption = constants.REPRINTS_OPTIONS.find(
                (item: IOption) => item.id === reprints
            );

            if (item) {
                this.setSelectorValue('reprints', [item]);
            }
        }

        this.setTextValue('commercialNews', commercialNews || '');

        this.handleCheckboxChanged(
            'commercialNews',
            commercialNews && commercialNews.trim().length > 0
        );
    };

    setTextValue = (name: string, value: string | number) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value;

        this.setState({
            controls
        });
    };

    handleCheckboxChanged = (name: string, checked: boolean) => {
        let checkboxes: any = { ...this.state.checkboxes };

        checkboxes[name].checked = checked;

        this.setState(
            {
                checkboxes
            },
            () => {
                //this.props.setData(name, checked)
            }
        );
    };

    handleInputChanged = (name: string, value: string) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value;

        this.setState(
            {
                controls
            },
            () => {
                this.props.setData(name, +value);
            }
        );
    };

    handleTextAreaChanged = (name: string, value: string) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value;

        this.setState(
            {
                controls
            },
            () => {
                this.props.setData(name, value);
            }
        );
    };

    handleSelectorClicked = (name: string, index: number, selected: boolean): void => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        if (!selectors[name].multiple) {
            selectors[name].options = selectors[name].options.map((item: IOption) => {
                return {
                    ...item,
                    selected: false
                };
            });
        }

        selectors[name].options[index].selected = selected;

        this.setState(
            {
                selectors
            },
            () => {
                if (name === 'reprints') {
                    const selected: IOption = selectors[name].options.find(
                        (item: IOption) => item.selected
                    );
                    this.props.setData(name, selected ? selected.id : 0);
                } else {
                    this.props.setData(
                        name,
                        selectors[name].options.filter((item: IOption) => item.selected)
                    );
                }
            }
        );
    };

    setSelectorsOptions = () => {
        let selectors = { ...this.state.selectors };
        let awards: ISelector = selectors.awards;
        let translations: ISelector = selectors.translations;

        awards.options = this.props.awards.map(
            (item: IServerOption): IOption => {
                return {
                    id: item.id,
                    label: item.label,
                    selected: false
                };
            }
        );

        translations.options = this.props.translations.map(
            (item: IServerOption): IOption => {
                return {
                    id: item.id,
                    label: item.label,
                    selected: false
                };
            }
        );

        this.setState({
            selectors
        });
    };

    setSelectorValue = (name: string, values: Array<any>) => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        const ids = values.map(item => item.id);

        selectors[name].options = selectors[name].options.map((item: IOption) => {
            return {
                ...item,
                selected: ids.indexOf(item.id) >= 0
            };
        });

        this.setState({
            selectors
        });
    };

    onAddNewReview = () => {
        let data = { ...this.state.data };

        data.reviews.push({
            source: '',
            link: ''
        });

        this.setState({
            data
        });
    };

    removeReview = (index: number) => {
        let data = { ...this.state.data };

        data.reviews.splice(index, 1);

        this.setState(
            {
                data
            },
            () => {
                this.props.setData('reviews', this.state.data.reviews);
            }
        );
    };

    onChangeDataValue = (index: number, name: string, value: string) => {
        let data = { ...this.state.data };

        data.reviews[index][name] = value;

        this.setState(
            {
                data
            },
            () => {
                this.props.setData('reviews', this.state.data.reviews);
            }
        );
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.data !== prevProps.data && this.props.data) {
            this.initializeData();
        }
    }

    render() {
        const { controls, selectors, checkboxes, data } = this.state;

        return (
            <div
                className={[
                    mainClasses.TitleForm,
                    mainClasses['TitleForm--nopadding'],
                    stepClasses.SalesData
                ].join(' ')}
            >
                <div className={mainClasses['TitleForm-header']}>
                    <Text tag={'h2'} transform={'uppercase'} weight={600} spacing={true}>
                        <FormattedMessage id={'title.section.salesData'} />
                    </Text>
                </div>
                <div className={mainClasses['TitleForm-content']}>
                    <Instruction onTop type="suggestion" textIds={['instruction.sensibledata']} />
                    <div className={stepClasses['SalesData-content']}>
                        <div className={mainClasses['TitleForm-field']}>
                            <Selector
                                label={
                                    selectors.reprints.labelId ? (
                                        <FormattedMessage id={selectors.reprints.labelId} />
                                    ) : (
                                        ''
                                    )
                                }
                                name={selectors.reprints.name}
                                multiple={false}
                                clicked={this.handleSelectorClicked}
                                options={selectors.reprints.options}
                                instructionIds={selectors.reprints.instructionIds}
                            />
                        </div>
                        <div className={mainClasses['TitleForm-field']}>
                            <>
                                <label className={mainClasses['TitleForm-label']}>
                                    <FormattedMessage id={'label.press'} />
                                </label>
                                <div className={mainClasses['TitleForm-flex']}>
                                    <div className={stepClasses['SalesData-review-field']}>
                                        {data.reviews.map((item: IReview, index: number) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={mainClasses['TitleForm-row']}
                                                >
                                                    <div
                                                        key={index}
                                                        className={mainClasses['TitleForm-block']}
                                                    >
                                                        <Input
                                                            placeholderId={'placeholder.source'}
                                                            type={'text'}
                                                            value={item.source}
                                                            name={`source-${index}`}
                                                            changed={(
                                                                name: string,
                                                                value: string
                                                            ) =>
                                                                this.onChangeDataValue(
                                                                    index,
                                                                    'source',
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        <Input
                                                            placeholderId={'placeholder.link'}
                                                            type={'url'}
                                                            value={item.link}
                                                            name={`link-${index}`}
                                                            alert={!isValidSecureURL(item.link)}
                                                            alertMessage={
                                                                !isValidSecureURL(item.link) && (
                                                                    <FormattedMessage
                                                                        id={'alert.pressLink'}
                                                                    />
                                                                )
                                                            }
                                                            changed={(
                                                                name: string,
                                                                value: string
                                                            ) =>
                                                                this.onChangeDataValue(
                                                                    index,
                                                                    'link',
                                                                    value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    {data.reviews.length > 1 && (
                                                        <button
                                                            className={
                                                                mainClasses['TitleForm-remove']
                                                            }
                                                            onClick={() => this.removeReview(index)}
                                                        >
                                                            <span>
                                                                <FormattedMessage id="general.remove" />
                                                            </span>
                                                        </button>
                                                    )}
                                                </div>
                                            );
                                        })}
                                        <button
                                            className={mainClasses['TitleForm-plus']}
                                            onClick={this.onAddNewReview}
                                        >
                                            <span>
                                                <FormattedMessage id="general.add" />
                                            </span>
                                        </button>
                                    </div>
                                    <Instruction textIds={['instruction.reviews']} />
                                </div>
                            </>
                        </div>
                        <div
                            className={[
                                mainClasses['TitleForm-field'],
                                mainClasses['TitleForm-field--small']
                            ].join(' ')}
                        >
                            <Selector
                                label={
                                    selectors.awards.labelId ? (
                                        <FormattedMessage id={selectors.awards.labelId} />
                                    ) : (
                                        ''
                                    )
                                }
                                name={selectors.awards.name}
                                multiple={selectors.awards.multiple}
                                clicked={this.handleSelectorClicked}
                                options={selectors.awards.options}
                            />
                        </div>
                        <div
                            className={[
                                mainClasses['TitleForm-field'],
                                mainClasses['TitleForm-field--small']
                            ].join(' ')}
                        >
                            <Selector
                                limit={4}
                                label={
                                    selectors.translations.labelId ? (
                                        <FormattedMessage id={selectors.translations.labelId} />
                                    ) : (
                                        ''
                                    )
                                }
                                name={selectors.translations.name}
                                multiple={selectors.translations.multiple}
                                clicked={this.handleSelectorClicked}
                                options={selectors.translations.options}
                            />
                        </div>
                        <div
                            className={[
                                mainClasses['TitleForm-field'],
                                mainClasses['TitleForm-field--small']
                            ].join(' ')}
                        >
                            <Switch
                                name={checkboxes.commercialNews.name}
                                changed={this.handleCheckboxChanged}
                                label={<FormattedMessage id="label.commercialNews" />}
                                description={<FormattedMessage id="commercialNews.description" />}
                                checked={checkboxes.commercialNews.checked}
                            />
                            {checkboxes.commercialNews.checked && (
                                <TextAreaEditor
                                    name={controls.commercialNews.name}
                                    isPlainText={controls.commercialNews.isPlainText}
                                    toolbarHidden={controls.commercialNews.toolbarHidden}
                                    value={controls.commercialNews.value}
                                    autosize={controls.commercialNews.autosize}
                                    placeholderId={controls.commercialNews.placeholderId}
                                    onChange={this.handleTextAreaChanged}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        translations: state.translationState.items,
        awards: state.awardState.items
    };
};

export default connect(mapStateToProps)(SalesData);
