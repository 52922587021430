import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    children?: string | object;
    weight?: 300 | 400 | 600 | 700;
    color?: string;
    transform?: 'capitalize' | 'uppercase' | 'none';
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    marginTop?: number;
    marginBottom?: number;
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
    html?: string;
    alignment?: 'left' | 'center' | 'right';
    spacing?: boolean;
}

const Text = (props: IProps) => {
    const {
        children,
        weight,
        transform,
        size,
        marginBottom,
        marginTop,
        tag,
        html,
        color,
        alignment,
        spacing
    } = props;

    const propsStyle = {
        fontWeight: weight || 700,
        textTransform: transform || 'none',
        margin: `${marginTop || 0}px 0 ${marginBottom || 0}px 0`,
        color: color || 'rgba(2,2,2,0.7)',
        textAlign: alignment || 'left',
        letterSpacing: spacing ? '2px' : '0px'
    };

    const textClasses = [classes.Text, size && classes[`Text--${size}`]].join(' ');

    if (html)
        return (
            <div
                className={textClasses}
                style={propsStyle}
                dangerouslySetInnerHTML={{ __html: html }}
            />
        );

    if (tag) {
        let tpl = null;

        switch (tag) {
            case 'h1':
                tpl = (
                    <h1 className={textClasses} style={propsStyle}>
                        {children}
                    </h1>
                );
                break;
            case 'h2':
                tpl = (
                    <h2 className={textClasses} style={propsStyle}>
                        {children}
                    </h2>
                );
                break;
            case 'h3':
                tpl = (
                    <h3 className={textClasses} style={propsStyle}>
                        {children}
                    </h3>
                );
                break;
            case 'h4':
                tpl = (
                    <h4 className={textClasses} style={propsStyle}>
                        {children}
                    </h4>
                );
                break;
            case 'h5':
                tpl = (
                    <h5 className={textClasses} style={propsStyle}>
                        {children}
                    </h5>
                );
                break;
            case 'h6':
                tpl = (
                    <h6 className={textClasses} style={propsStyle}>
                        {children}
                    </h6>
                );
                break;
            case 'p':
                tpl = (
                    <p className={textClasses}>
                        {children}
                    </p>
                );
                break;
            default:
                tpl = (
                    <span style={propsStyle} className={textClasses}>
                        {children}
                    </span>
                );
        }

        return tpl;
    }

    return (
        <span style={propsStyle} className={textClasses}>
            {children}
        </span>
    );
};

export default Text;
