import React, { Component } from 'react';
import classes from './styles.module.scss';
import RoundImage from '../UI/RoundImage';
import { IUser } from '../../shared/interfaces';
import Button from '../UI/Button';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import * as routes from '../../shared/routes';

interface IProps {
    currentUser: IUser;
    onSignoutClicked?(): void;
    spaced?: boolean;
}

interface IState {
    showExpander: boolean;
}

class UserBadge extends Component<IProps, IState> {
    userBadgeWrapper = React.createRef<HTMLDivElement>();
    userBadgeContent = React.createRef<HTMLDivElement>();

    state = {
        showExpander: false
    };

    componentDidMount(): void {
        document.addEventListener('click', this.handleClickedOutsideMenu, false);
        document.addEventListener('click', this.handleClickedInsideMenu, false);
    }

    handleClickedOutsideMenu = (e: any) => {
        if (this.userBadgeWrapper.current && !this.userBadgeWrapper.current.contains(e.target)) {
            this.setState({
                showExpander: false
            });
        }
    };

    handleClickedInsideMenu = (e: any) => {
        if (this.state.showExpander === true) {
            if (this.userBadgeContent.current && this.userBadgeContent.current.contains(e.target)) {
                this.setState({
                    showExpander: false
                });
            }
        }
    };

    toggleExpanderVisibility = () => {
        const showExpander = !this.state.showExpander;

        this.setState({
            showExpander
        });
    };

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickedOutsideMenu, false);
    }

    render() {
        const { currentUser, spaced, onSignoutClicked } = this.props;
        const { showExpander } = this.state;

        const userPicture =
            currentUser && currentUser.fileCodeImage
                ? currentUser.fileCodeImage.childrens[1].url
                : null;

        return currentUser ? (
            <div
                ref={this.userBadgeWrapper}
                className={[classes['UserBadge'], spaced ? classes['UserBadge--spaced'] : ''].join(
                    ' '
                )}
            >
                <div
                    className={[
                        classes['UserBadge-content'],
                        showExpander ? classes['is-visible'] : ''
                    ].join(' ')}
                >
                    <NavLink to={routes.PUBLISHER}>
                        <div className={classes['UserBadge-name']}>
                            { currentUser.data.publishingHouse }
                        </div>
                    </NavLink>
                    <NavLink to={routes.PUBLISHER}>
                        <RoundImage size={'sm'} src={userPicture} />
                    </NavLink>
                    <button
                    onClick={this.toggleExpanderVisibility}
                    className={[
                        classes['UserBadge-menu'],
                        showExpander ? classes['is-visible'] : ''
                    ].join(' ')}>
                        <span>Menu</span>
                    </button>
                </div>

                <div
                    className={[
                        classes['UserBadge-expander'],
                        showExpander ? classes['is-visible'] : ''
                    ].join(' ')}
                    ref={this.userBadgeContent}
                >
                    {/* <NavLink to={routes.PUBLISHER} className={classes['UserBadge-expander-btn']}>
                        <FormattedMessage id={'navigation.publisher'} />
                    </NavLink> */}
                    <Button type={'secondary-small'} clicked={onSignoutClicked}>
                        <FormattedMessage id={'general.signout'} />
                    </Button>
                </div>
            </div>
        ) : null;
    }
}

export default UserBadge;
