import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { IUser } from '../../shared/interfaces';
import Text from '../UI/Text';
import classes from './styles.module.scss';
import * as routes from '../../shared/routes';
import { FormattedMessage } from 'react-intl';
import RoundImage from '../UI/RoundImage';

interface IProps {
    publisher: IUser;
    titleId?: number;
    label?: boolean;
}

class PublisherCard extends Component<IProps, {}> {
    render() {
        const { publisher, label, titleId} = this.props;

        if (!publisher) return null;

        return (
            <div className={classes.PublisherCard}>
                {label && (
                    <div className={classes['PublisherCard-header']}>
                        <FormattedMessage id={'navigation.publisher'} />
                    </div>
                )}
                
                {titleId && titleId > 0 && (
                    <NavLink
                        className={classes['PublisherCard-edit-btn']}
                        to={routes.TITLE.replace(':id', titleId.toString()).replace(':step', '3')}
                    >
                        <FormattedMessage id={'general.edit'} />
                    </NavLink>
                )}
                    
                <div className={classes['PublisherCard-content']}>
                    <div className={classes['PublisherCard-wrapper']}>
                        <RoundImage
                            size={'md'}
                            src={publisher.fileCodeImage ? publisher.fileCodeImage.url : null}
                        />
                        <h3>{publisher.data.publishingHouse}</h3>
                    </div>
                    <Text tag={'p'}>{publisher.biography}</Text>
                </div>
            </div>
        );
    }
}

export default PublisherCard;
