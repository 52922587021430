import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classes from './styles.module.scss';

toast.configure({
    autoClose: 3000,
    draggable: false
});

interface IProps {
    message: any;
    position?: 'BOTTOM_RIGHT' | 'TOP_RIGHT' | 'BOTTOM_LEFT' | 'TOP_LEFT';
    type?: 'success' | 'error';
}

const Toast = (props: IProps) => {
    const { message, position, type } = props;

    return type && type === 'error'
        ? toast.error(message, {
              position: toast.POSITION[position || 'TOP_RIGHT'],
              className: [classes['confirm-bubble'], classes['confirm-bubble--error']].join(' ')
          })
        : toast.success(message, {
              position: toast.POSITION[position || 'TOP_RIGHT'],
              className: [classes['confirm-bubble'], classes['confirm-bubble--success']].join(' ')
          });
};

export default Toast;
