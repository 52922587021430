import React, { Component } from 'react';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import {
    IFile,
    IInput,
    ISession,
    ISessionData,
    ITextArea,
    IUploader,
    IUser
} from '../../shared/interfaces';
import classes from './styles.module.scss';
import Input from '../Field/Input';
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import { FormattedMessage } from 'react-intl';
import FeedbackMessage from '../UI/FeedbackMessage';
import Uploader from '../Field/Uploader';
import RoundImage from '../UI/RoundImage';
import AddressAutocomplete from '../Field/AddressAutocomplete';
import TextAreaEditor from '../Field/TextAreaEditor';
import { parseAddressComponents } from '../../shared/helpers/utilities';
import { isValidSecureURL } from '../../shared/helpers/checks';

interface IProps {
    currentUser: IUser;
    waitingForSavingUser: boolean;
    waitingForSavingFile: boolean;
    userError?: string;
    file: Array<IFile>;
    onCreateFile(data: any): any;
    onSignin(data: ISession): ISessionData;
    onUpdateUser(id: number, data: any): IUser;
    onResetFile(): void;
    onClose(): void;
}

interface IUploaderControls {
    userPicture: IUploader;
}

interface IControls {
    publishingHouse: IInput;
    website: IInput;
    phoneNumber: IInput;
}

interface ITextAreaControls {
    biography: ITextArea;
}

interface IState {
    lat: number;
    lng: number;
    cap: string;
    country: string;
    region: string;
    city: string;
    province: string;
    uploader: IUploaderControls;
    controls: IControls;
    textareaControls: ITextAreaControls;
    error: string;
    websiteError: boolean;
}

class UserForm extends Component<IProps, IState> {
    state = {
        lat: null,
        lng: null,
        cap: '',
        country: '',
        region: '',
        city: '',
        province: '',
        uploader: {
            userPicture: {
                value: null,
                accept: 'image/jpeg, image/png',
                titleId: 'upload.image.title',
                multiple: false
            }
        },
        controls: {
            publishingHouse: {
                name: 'publishingHouse',
                value: '',
                type: 'text',
                placeholderId: 'placeholder.publishingHouse',
                labelId: 'label.publishingHouse',
                required: true
            },
            website: {
                name: 'website',
                labelId: 'label.website',
                value: '',
                type: 'url',
                placeholderId: 'placeholder.website',
                error: false,
                errorMessage: null
            },
            phoneNumber: {
                name: 'phoneNumber',
                labelId: 'label.phoneNumber',
                value: '',
                type: 'phone',
                placeholderId: 'placeholder.phoneNumber'
            },
            address: {
                name: 'address',
                labelId: 'label.address',
                value: '',
                type: 'geo',
                placeholderId: 'placeholder.address'
            }
        },
        textareaControls: {
            biography: {
                isPlainText: true,
                toolbarHidden: true,
                autosize: true,
                name: 'biography',
                value: '',
                type: 'textarea',
                placeholderId: 'placeholder.information',
                labelId: 'label.information',
                maxLength: 200
            }
        },
        error: '',
        websiteError: false
    };

    componentDidMount(): void {
        const { currentUser } = this.props;

        if (currentUser) {
            this.setTextValue(
                'publishingHouse',
                currentUser.data ? currentUser.data.publishingHouse : ''
            );
            this.setTextValue('website', currentUser.data ? currentUser.data.website : '');
            this.setTextValue('phoneNumber', currentUser.data ? currentUser.data.phoneNumber : '');
            this.setTextValue('address', currentUser.address);
            this.handleSetUserPicture(currentUser.fileCodeImage);
            this.handleTextAreaChanged('biography', currentUser.biography);

            if (currentUser.data && currentUser.data.addressLat && currentUser.data.addressLng) {
                this.setState({
                    lat: currentUser.data.addressLat,
                    lng: currentUser.data.addressLng
                });
            }
        }
    }

    setTextValue = (name: string, value: string) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value || '';

        this.setState({
            controls
        });
    };

    handleSetUserPicture = (value: IFile | null) => {
        const uploader: any = { ...this.state.uploader };

        uploader.userPicture.value = value;

        this.setState({
            uploader
        });
    };

    handleGeoChanged = (
        name: string,
        value: { formatted_address; lat; lng },
        addressComponents: any
    ) => {
        let controls: any = { ...this.state.controls };

        let addressComponentsData = addressComponents
            ? parseAddressComponents(addressComponents, value.formatted_address)
            : null;

        //console.log(addressComponentsData);
        if (addressComponentsData) {
            this.setState({
                city: addressComponentsData.city,
                cap: addressComponentsData.postalCode,
                country: addressComponentsData.country,
                province: addressComponentsData.province
            });
        }

        controls[name].value = value.formatted_address;

        this.setState({
            lat: value.lat,
            lng: value.lng,
            controls,
            error: ''
        });
    };

    handleInputChanged = (name: string, value: string) => {
        let controls: any = { ...this.state.controls };

        controls[name].value = value;

        this.setState({
            controls,
            error: '',
            websiteError: false
        });
    };

    handleTextAreaChanged = (name: string, value: string) => {
        let textareaControls: ITextAreaControls | any = { ...this.state.textareaControls };

        textareaControls[name].value = value;

        this.setState({
            textareaControls
        });
    };

    checkSecureUrl = (): boolean => {
        const { controls } = this.state;

        const url = controls.website.value;

        if (!url.trim().length) return true;

        return url.trim().length > 0 && isValidSecureURL(url);
    };

    handleSubmitClicked = () => {
        if (this.checkSecureUrl()) {
            const {
                currentUser: { id }
            } = this.props;
            const {
                lat,
                lng,
                cap,
                country,
                region,
                city,
                province,
                controls: { publishingHouse, website, phoneNumber, address },
                uploader: { userPicture },
                textareaControls: { biography }
            } = this.state;

            const file: any = userPicture.value;

            const model = {
                address: address.value,
                fileCodeImage: file ? file.code : '',
                biography: biography.value,
                cap,
                city,
                region,
                country,
                province,
                data: {
                    website: website.value,
                    phoneNumber: phoneNumber.value,
                    addressLat: lat,
                    addressLng: lng,
                    publishingHouse: publishingHouse.value
                }
            };

            this.props.onUpdateUser(id, model);
        } else {
            this.setState({
                websiteError: true
            });
        }
    };

    handleUploadImage = (uploaded: Array<File>) => {
        const data = new FormData();

        data.append('file', uploaded[0]);

        this.props.onCreateFile(data);
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.file !== prevProps.file && this.props.file) {
            this.handleSetUserPicture(this.props.file[0]);
        }

        if (this.props.userError !== prevProps.userError && this.props.userError) {
            this.setState({
                error: this.props.userError
            });
        }

        if (this.props.currentUser !== prevProps.currentUser && this.props.currentUser) {
            this.props.onClose();
        }
    }

    componentWillUnmount(): void {
        this.props.onResetFile();
    }

    render() {
        const {
            controls,
            error,
            textareaControls,
            uploader: { userPicture },
            websiteError
        } = this.state;

        const { waitingForSavingUser, waitingForSavingFile } = this.props;

        const currentFn = this.handleSubmitClicked;

        const formDisabled = waitingForSavingUser;

        const inputElementsTpl = Object.values(controls).map((item: IInput, index: number) => {
            return (
                <div key={index} className={classes['UserForm-field']}>
                    {item.type === 'geo' ? (
                        <AddressAutocomplete
                            changed={this.handleGeoChanged}
                            name={item.name}
                            placeholderId={item.placeholderId}
                            value={item.value}
                            label={item.labelId ? <FormattedMessage id={item.labelId} /> : ''}
                        />
                    ) : (
                        <Input
                            label={item.labelId ? <FormattedMessage id={item.labelId} /> : ''}
                            required={item.required}
                            type={item.type}
                            name={item.name}
                            placeholderId={item.placeholderId}
                            value={item.value}
                            disabled={formDisabled}
                            error={item.type === 'url' && websiteError}
                            errorMessage={
                                item.type === 'url' && websiteError ? (
                                    <FormattedMessage id={'error.url'} />
                                ) : (
                                    ''
                                )
                            }
                            changed={this.handleInputChanged}
                        />
                    )}
                </div>
            );
        });

        const userPictureValue: any = userPicture.value;

        return (
            <div className={classes.UserForm}>
                <div className={classes['UserForm-field']}>
                    <div className={classes['UserForm-field-label']}>
                        <FormattedMessage id={'label.profile.picture'} />
                    </div>

                    {userPictureValue !== null && userPictureValue ? (
                        <div className={classes['UserForm-image']}>
                            <RoundImage src={userPictureValue ? userPictureValue.url : null} />
                            <Button type="remove" clicked={() => this.handleSetUserPicture(null)}>
                                <FormattedMessage id={'general.remove'} />
                            </Button>
                        </div>
                    ) : (
                        <Uploader
                            squared
                            large
                            fullWidth
                            disabled={formDisabled}
                            titleId={'upload.image.title'}
                            accept={'image/jpeg, image/png'}
                            onSelectedFiles={this.handleUploadImage}
                        />
                    )}
                    <FeedbackMessage visible={error !== null && error !== ''} type={'error'}>
                        {`${error}`}
                    </FeedbackMessage>
                </div>
                {inputElementsTpl}
                <div className={classes['UserForm-field']}>
                    <TextAreaEditor
                        label={
                            textareaControls.biography.labelId ? (
                                <FormattedMessage id={textareaControls.biography.labelId} />
                            ) : (
                                ''
                            )
                        }
                        name={textareaControls.biography.name}
                        isPlainText={textareaControls.biography.isPlainText}
                        toolbarHidden={textareaControls.biography.toolbarHidden}
                        value={textareaControls.biography.value}
                        autosize={textareaControls.biography.autosize}
                        placeholderId={textareaControls.biography.placeholderId}
                        maxLength={textareaControls.biography.maxLength}
                        onChange={this.handleTextAreaChanged}
                    />
                </div>
                {(waitingForSavingUser || waitingForSavingFile) && (
                    <div className={classes['RegistrationForm-loader']}>
                        <Loader theme={'light'} size="sm" />
                    </div>
                )}
                <div className={classes['UserForm-footer']}>
                    <Button
                        disabled={formDisabled || !controls.publishingHouse.value.trim().length}
                        clicked={currentFn}
                    >
                        <FormattedMessage id={'general.confirm'} />
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.userState.user,
        waitingForSavingUser: state.userState.isStoring,
        userError: state.userState.error,
        file: state.fileState.file,
        waitingForSavingFile: state.fileState.isStoring
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSignin: (data: ISession) => dispatch(actions.session(data)),
        onCreateFile: (data: any) => dispatch(actions.createFile(data)),
        onResetFile: () => dispatch(actions.resetFile()),
        onUpdateUser: (id: number, data: any) => dispatch(actions.updateUser(id, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
