import React, { Component } from 'react';
import { History } from 'history';
import classes from './styles.module.scss';
import Text from '../../components/UI/Text';

interface IProps {
    history: History;
}

class Privacy extends Component<IProps> {
    render() {
        return (
            <main className={classes.Privacy}>
                <div className={classes['Privacy-header']}>
                    <Text
                        tag={'h2'}
                        marginBottom={30}
                        weight={400}
                        transform={'uppercase'}
                        spacing={true}
                    >
                        <span>Privacy Policy</span>
                    </Text>
                </div>

                <div className={classes['Privacy-content']}>
                    <h2>
                        <b>1. PREMESSA</b>
                    </h2>
                    <p>
                        In questa sezione sono contenute le informazioni relative alle modalità di
                        gestione di artchivio.net in riferimento al trattamento dei dati degli
                        utenti di artchivio.net.
                    </p>

                    <p>
                        La presente informativa ha valore anche ai fini del Codice in materia di
                        protezione dei dati personali (art. 13 del d.lg. n. 196/2003) per i soggetti
                        che interagiscono con artchivio.net ed è raggiungibile all’indirizzo
                        corrispondente alla pagina iniziale:
                    </p>

                    <a href="https://artchivio.net">
                        <span>www.artchivio.net</span>
                    </a>

                    <p>
                        L’informativa è resa solo per artchivio.net e non anche per altri siti web
                        eventualmente consultati dall’utente tramite link in esso contenuti.
                    </p>

                    <p>
                        Scopo del presente documento è fornire indicazioni circa le modalità, i
                        tempi e la natura delle informazioni che i titolari del trattamento devono
                        fornire agli utenti al momento della connessione alle pagine web di
                        artchivio.net, indipendentemente dagli scopi del collegamento stesso,
                        secondo la Raccomandazione n. 2/2001 che le autorità europee per la
                        protezione dei dati personali, riunite dall’art. 29 della direttiva n.
                        95/46/CE, adottata il 17 maggio 2001 per la raccolta dei dati personali
                        on-line.
                    </p>
                    <h2>
                        <b>2. IL TRATTAMENTO DEI DATI</b>
                    </h2>
                    <h3>
                        <b>
                            <i>TITOLARE</i>
                        </b>
                    </h3>
                    <p>Nel corso dell’utilizzazione di </p>
                    <span>artchivio.net</span>
                    <p>
                        {' '}
                        i dati relativi a persone identificate o identificabili possono essere
                        trattati per fornire servizi ovvero migliorare questi ultimi tanto per
                        servizi basici come l’individuazione della lingua dell’utente quanto per
                        servizi più complessi.
                    </p>

                    <p>Titolare del trattamento è Artchivio S.r.l. con sede in:</p>

                    <p>Via dei Barbieri, 5 - 00186 Roma</p>
                    <h3>
                        <b>
                            <i>LUOGO</i>
                        </b>
                    </h3>
                    <p>Il trattamento dei dati generati dall’utilizzo di </p>
                    <span>artchivio.net</span>
                    <p>
                        {' '}
                        avviene presso la sede del titolare del trattamento con l’intervento dei
                        soggetti di esso incaricati. In caso di necessità, i dati connessi al
                        servizio newsletter possono essere trattati dal responsabile del trattamento
                        o soggetti da esso incaricati a tal fine presso la relativa sede.
                    </p>
                    <h3>
                        <b>
                            <i>I DATI TRATTATI</i>
                        </b>
                    </h3>
                    <h3>
                        <b>
                            <i>COOKIES</i>
                        </b>
                    </h3>
                    <p>I cookies sono dati che vengono inviati da</p>
                    <span>artchivio.net</span>
                    <p>
                        {' '}
                        al browser internet dell’utente e vengono memorizzati da questo per poi
                        essere ritrasmessi al sito alla visita successiva.
                    </p>

                    <p>
                        Essi possono essere usati per monitorare le sessioni, per autenticare un
                        utente in modo che possa accedere a un sito senza digitare ogni volta nome e
                        password e per memorizzare le sue preferenze.
                    </p>

                    <p>
                        Esistono tanti tipi di cookies classificabili per per aspetti tecnici,
                        durata, provenienza e funzione.
                    </p>
                    <h3>
                        <b>
                            <i>COOKIES TECNICI</i>
                        </b>
                    </h3>
                    <p>
                        Ci sono numerose tecnologie usate per conservare informazioni nel computer
                        dell’utente, che poi vengono raccolte dai siti. Tra queste la più conosciuta
                        e utilizzata è quella dei cookies HTML.
                    </p>

                    <p>
                        Essi servono per la navigazione e per facilitare l’accesso e la fruizione
                        del sito da parte dell’utente. Sono necessari alla trasmissione di
                        comunicazioni su rete elettronica ovvero al fornitore per erogare il
                        servizio richiesto dal cliente.
                    </p>

                    <p>
                        Le impostazioni per gestire o disattivare i cookies possono variare a
                        seconda del browser internet utilizzato.
                    </p>

                    <p>
                        Ad ogni modo, l’utente può gestire o richiedere la disattivazione generale o
                        la cancellazione dei cookies, modificando le impostazioni del proprio
                        browser internet. Tale disattivazione può rallentare o impedire l’accesso ad
                        alcune parti del sito.
                    </p>

                    <p>
                        L’uso di cookies tecnici consente la fruizione sicura ed efficiente del
                        sito.
                    </p>

                    <p>
                        I cookies che vengono inseriti nel browser e ritrasmessi mediante Google
                        Analytics o tramite il servizio Statistiche di Blogger o similari sono
                        tecnici solo se utilizzati a fini di ottimizzazione del sito direttamente
                        dal titolare del sito stesso, che potrà raccogliere informazioni in forma
                        aggregata sul numero degli utenti e su come questi visitano il sito. A
                        queste condizioni, per i cookies analytics valgono le stesse regole, in tema
                        di informativa e consenso, previste per i cookies tecnici.
                    </p>
                    <h3>
                        <b>
                            <i>COOKIES DI DURATA</i>
                        </b>
                    </h3>
                    <p>
                        Dal punto di vista della durata si possono distinguere cookies temporanei di
                        sessione che si cancellano automaticamente al termine della sessione di
                        navigazione e servono per identificare l’utente e quindi evitare il login ad
                        ogni pagina visitata e quelli permanenti che restano attivi nel pc fino a
                        scadenza o cancellazione da parte dell’utente.
                    </p>

                    <p>
                        Potranno essere installati cookies di sessione al fine di consentire un
                        utilizzo ottimale del sito.
                    </p>

                    <p>
                        Essi non vengono memorizzati in modo persistente ma esclusivamente per la
                        durata della navigazione fino alla chiusura del browser e svaniscono con la
                        chiusura dello stesso. Il loro uso è strettamente limitato alla trasmissione
                        di identificativi di sessione costituiti da numeri casuali generati dal
                        server necessari per consentire l’esplorazione sicura ed efficiente del
                        sito.
                    </p>
                    <h3>
                        <b>
                            <i>DATI DI NAVIGAZIONE</i>
                        </b>
                    </h3>
                    <p>
                        I sistemi informatici e le procedure software preposte al funzionamento di
                        questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni
                        dati personali la cui trasmissione è implicita nell’uso dei protocolli di
                        comunicazione di Internet.
                    </p>

                    <p>
                        Si tratta di informazioni che non sono raccolte per essere associate a
                        interessati identificati, ma che per loro stessa natura potrebbero,
                        attraverso elaborazioni ed associazioni con dati detenuti da terzi,
                        permettere di identificare gli utenti.
                    </p>

                    <p>
                        In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio
                        dei computer utilizzati dagli utenti che si connettono al sito, gli
                        indirizzi in notazione URI delle risorse richieste, l’orario della
                        richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la
                        dimensione del file ottenuto in risposta, il codice numerico indicante lo
                        stato della risposta data dal server ed altri parametri relativi al sistema
                        operativo e all’ambiente informatico dell’utente.
                    </p>

                    <p>
                        Questi dati vengono utilizzati al solo fine di ricavare informazioni
                        statistiche anonime sull’uso del sito e per controllarne il corretto
                        funzionamento e vengono cancellati immediatamente dopo l’elaborazione.
                    </p>

                    <p>
                        I dati potrebbero essere utilizzati per l’accertamento di responsabilità in
                        caso di ipotetici reati informatici ai danni del sito: salva questa
                        eventualità, allo stato i dati sui contatti web non persistono per più di
                        sette giorni.
                    </p>
                    <h3>
                        <b>
                            <i>DATI FORNITI DALL’UTENTE</i>
                        </b>
                    </h3>
                    <p>
                        L’invio facoltativo, esplicito e volontario di posta elettronica agli
                        indirizzi indicati su questo sito comporta la successiva acquisizione
                        dell’indirizzo del mittente, necessario per rispondere alle richieste,
                        nonché degli eventuali altri dati personali inseriti nella missiva.
                    </p>

                    <p>
                        Specifiche informative di sintesi verranno progressivamente riportate o
                        visualizzate nelle pagine del sito predisposte per particolari servizi a
                        richiesta.
                    </p>
                    <h3>
                        <b>
                            <i>I VOSTRI DIRITTI SULLA PRIVACY</i>
                        </b>
                    </h3>
                    <p>
                        Di seguito, vi forniamo una scheda riassuntiva dei vostri diritti. Potete
                        esercitarli in qualsiasi momento inviando una e-mail a{' '}
                        <a href="mailto:info@artchivio.net">info@artchivio.net</a>
                    </p>

                    <p>Il diritto di essere informati. </p>

                    <p>
                        Avete il diritto di ricevere informazioni chiare, trasparenti e facilmente
                        comprensibili riguardo all’uso dei vostri dati personali e dei vostri
                        diritti. Inoltre, vi comunichiamo ulteriori informazioni nella presente
                        Informativa.
                    </p>

                    <p>Il diritto di accesso e rettifica. </p>

                    <p>
                        Avete il diritto di accedere, correggere o aggiornare i vostri dati
                        personali in qualsiasi momento. Comprendiamo l’importanza di questo aspetto
                        e nel caso in cui vogliate esercitare questo diritto vi preghiamo di
                        contattarci.
                    </p>

                    <p>Il diritto alla portabilità dei dati. </p>

                    <p>
                        I dati personali che ci avete fornito sono portabili. Questo implica che
                        possono essere spostati, copiati o trasmessi elettronicamente in base a
                        determinati requisiti.
                    </p>

                    <p>Il diritto all’oblio. </p>

                    <p>
                        In determinate circostanze, avete il diritto di richiedere di cancellare i
                        vostri dati. Se desiderate cancellare i vostri dati personali in nostro
                        possesso, potete comunicarcelo e ci impegneremo a seguire tutte le procedure
                        per rispondere alla vostra richiesta in virtù dei requisiti legali. Se i
                        dati personali raccolti non sono più necessari e non siamo legalmente
                        obbligati a tenerli in nostro possesso, faremo il possibile per cancellarli,
                        distruggerli o renderli permanentemente anonimi.
                    </p>

                    <p>Il diritto di limitare l’elaborazione. </p>

                    <p>
                        In alcuni casi, avete il diritto di limitare l’elaborazione dei vostri dati
                        personali.
                    </p>

                    <p>
                        Il diritto di obiettare. In alcuni casi, avete il diritto di obiettare
                        alcune elaborazioni, tra cui il diritto d’elaborazione per marketing diretto
                        (per esempio, ricevere e-mail nelle quali vengono vi comunichiamo possibili
                        opportunità).
                    </p>

                    <p>Il diritto di presentare un reclamo presso un’autorità di vigilanza. </p>

                    <p>
                        Avete il diritto di presentare un reclamo direttamente presso un’autorità di
                        vigilanza per l’elaborazione dei vostri dati personali.
                    </p>

                    <p>Il diritto di revocare il consenso. </p>

                    <p>
                        Se avete dato il consenso per l’elaborazione dei vostri dati personali (noi
                        richiediamo il vostro consenso per motivi legali per l’elaborazione dei
                        vostri dati personali), avete il diritto di revocarlo in qualsiasi momento
                        (anche se lo fate, non implica che quanto eseguito prima con i vostri dati
                        personali sia irregolare). Potete revocare il vostro consenso
                        all’elaborazione dei vostri dati personali in qualsiasi momento,
                        contattandoci e fornendoci le informazioni riportate di seguito.
                    </p>

                    <p>Diritti relativi al processo decisionale automatizzato. </p>

                    <p>
                        Avete il diritto di non essere soggetti alla decisione basata solo sul
                        processo automatizzato e che abbia effetti legali o altri effetti
                        significanti per voi. In particolare, avete il diritto di:
                    </p>
                    <ul>
                        <li>
                            <p>Ottenere un intervento umano;</p>
                        </li>
                        <li>
                            <p>Esprimere il vostro punto di vista;</p>
                        </li>
                        <li>
                            <p>Ottenere una spiegazione della decisione presa dopo un accordo; e</p>
                        </li>
                        <li>
                            <p>Contestare la suddetta decisione.</p>
                        </li>
                    </ul>
                    <p>
                        Ulteriori informazioni e consigli sui vostri diritti possono essere
                        richiesti al garante della protezione dei dati nel vostro Paese.
                    </p>
                    <h2>
                        <b>3. FACOLTATIVITÀ DEL CONFERIMENTO DI DATI</b>
                    </h2>
                    <p>
                        L’utente è sempre libero di fornire i dati personali richiesti per l’invio
                        di materiale informativo, salvo quanto necessario per la navigazione.
                    </p>

                    <p>
                        Il mancato conferimento, per contro, può comportare l’impossibilità di
                        ottenere quanto richiesto.
                    </p>
                    <h2>
                        <b>4. MODALITÀ DEL TRATTAMENTO</b>
                    </h2>
                    <p>
                        I dati personali sono trattati direttamente da Artchivio S.r.l. o da suoi
                        incaricati, con strumenti automatizzati o manualmente per il tempo
                        strettamente necessario a conseguire gli scopi per cui sono stati raccolti.
                    </p>

                    <p>
                        Specifiche misure di sicurezza sono osservate per prevenire la perdita dei
                        dati, usi illeciti o non corretti ed accessi non autorizzati.
                    </p>
                    <h2>
                        <b>5. DIRITTI DEGLI INTERESSATI</b>
                    </h2>
                    <p>
                        Le informazioni circa il contenuto, l’origine, l’esattezza, l’integrazione,
                        l’aggiornamento ovvero la correzione dei dati sono accessibili al
                        proprietario degli stessi così come la loro l’esistenza negli archivi di{' '}
                    </p>
                    <span>artchivio.net</span>
                    <p>
                        {' '}
                        che può essere verificata mediante richiesta. Parimenti si ha il diritto di
                        chiedere la cancellazione, la trasformazione in forma anonima o il blocco
                        dei dati trattati in violazione di legge, nonché di opporsi in ogni caso,
                        per motivi legittimi, al loro trattamento.
                    </p>

                    <p>Le richieste vanno rivolte:</p>
                    <ul>
                        <li>
                            <p>
                                per e-mail, all’indirizzo:{' '}
                                <a href="mailto:info@artchivio.net">info@artchivio.net</a>
                            </p>
                        </li>
                        <li>
                            <p>
                                a mezzo posta al seguente indirizzo: Via dei Barbieri 5, 00186 -
                                Roma.
                            </p>
                        </li>
                    </ul>
                    <h2>
                        <b>6. SICUREZZA DEI DATI FORNITI</b>
                    </h2>
                    <p>
                        La sicurezza dei dati è garantita durante l’utilizzazione della piattaforma
                        dalla conformità delle procedure adottate da{' '}
                    </p>
                    <span>artchivio.net</span>
                    <p>
                        {' '}
                        al Codice Privacy (D.Lgs 196/2003) con particolare riguardo ai dati
                        personali dell’utente.
                    </p>
                    <h2>
                        <b>7. MODIFICHE AL PRESENTE DOCUMENTO</b>
                    </h2>
                    <p>Il presente documento, pubblicato all’indirizzo:</p>

                    <a href="http://artchivio.net/privacy-policy">
                        <p>artchivio.net/privacy-policy</p>
                    </a>

                    <p>
                        costituisce la privacy policy di questo sito. Esso può essere soggetto a
                        modifiche o aggiornamenti. Vi invitiamo a consultarlo per essere a
                        conoscenza di eventuali aggiornamenti e/o modifiche.
                    </p>

                    <p>Il documento è conforme alle disposizioni normative in materia.</p>
                </div>
            </main>
        );
    }
}

export default Privacy;
