import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { History } from 'history';
import classes from './styles.module.scss';
import Text from '../../components/UI/Text';

interface IProps {
    history: History;
}

interface IState {}

class Dashboard extends Component<IProps, IState> {
    render() {
        return (
            <main className={classes.Dashboard}>
                <Text>
                    <FormattedMessage id={'navigation.dashboard'} />
                </Text>
            </main>
        );
    }
}

export default Dashboard;
