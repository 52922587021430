import axios, { AxiosRequestConfig } from 'axios';
import { resetSession } from '../helpers/session';

//console.log('ENV', process.env);

const API_BASE = process.env.REACT_APP_API_ENDPOINT;

/**
 * Create an Axios Clients with defaults
 */
// Default config options
const defaultOptions: AxiosRequestConfig = {
    baseURL: `${API_BASE}`
};

// Create instance
let Client = axios.create();

interface IResponse {
    data: any;
    config: object;
    status: number;
    headers: object;
}

interface IError {
    message: any;
    config: any;
    response: IResponse;
}

/**
 * Request Wrapper with default success/error actions
 */
const request = (options: AxiosRequestConfig) => {
    const onSuccess = (response: IResponse) => {
        //console.log('Request Successful!', response);
        return response.data;
    };

    const onError = (error: IError) => {
        if (error.response.status === 500) {
            console.error('Request Failed:', error.config);
        }

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            if (error.response.status === 500) {
                console.error('Status:', error.response.status);
                console.error('Data:', error.response.data);
                console.error('Headers:', error.response.headers);
            }

            if (error.response.status === 401) {
                const data = error.response.data;

                if (
                    data.hasOwnProperty('errors') &&
                    data.errors.length &&
                    data.errors[0].message.toLowerCase() !== 'unauthorized' &&
                    data.errors[0].message.toLowerCase() !== 'entity not found'
                ) {
                    resetSession();

                    window.location.href = '/';
                }
            }
        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    const config = {
        ...defaultOptions,
        ...options
    };

    return Client(config)
        .then(onSuccess)
        .catch(onError);
};

export default request;
