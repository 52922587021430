import * as actionTypes from '../actions/actionTypes';
import { IServerOption } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    format: IServerOption;
    formats: Array<IServerOption>;
    error: string;
}

const INITIAL_STATE = {
    item: null,
    items: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null
};

const fetchFormatStart = (state = INITIAL_STATE) => {
    return updateObject(state, { item: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchFormatSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { item: action.format, isFetching: false });
};

const fetchFormatFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchFormatsStart = (state = INITIAL_STATE) => {
    return updateObject(state, { items: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchFormatsSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { items: action.formats, isFetching: false });
};

const fetchFormatsFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const formatReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // READ/FETCHING
        case actionTypes.FETCH_FORMAT_START:
            return fetchFormatStart(state);
        case actionTypes.FETCH_FORMAT_SUCCESS:
            return fetchFormatSuccess(state, action);
        case actionTypes.FETCH_FORMAT_FAIL:
            return fetchFormatFail(state, action);
        case actionTypes.FETCH_FORMATS_START:
            return fetchFormatsStart(state);
        case actionTypes.FETCH_FORMATS_SUCCESS:
            return fetchFormatsSuccess(state, action);
        case actionTypes.FETCH_FORMATS_FAIL:
            return fetchFormatsFail(state, action);

        default:
            return state;
    }
};

export default formatReducer;
