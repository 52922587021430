import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
    IOption,
    ISelectOption,
    ISelector,
    ISelectSearch,
    IServerOption,
    ITitle
} from '../../../shared/interfaces';
import mainClasses from '../styles.module.scss';
import stepClasses from './styles.module.scss';
import Selector from '../../Field/Selector';
import Text from '../../UI/Text';
import OptionsSelect from '../../Field/OptionsSelect';

interface IProps {
    data: ITitle;
    setData(name: string, value: any): void;
    genres: Array<IServerOption>;
    features: Array<ISelectOption>;
    tones: Array<ISelectOption>;
}

interface ISelectors {
    primaryGenre: ISelector;
    secondaryGenre: ISelector;
}

interface IOptionSearchFields {
    features: ISelectSearch;
    tones: ISelectSearch;
}

interface IState {
    selectors: ISelectors;
    optionsSearchFields: IOptionSearchFields;
    initialized: boolean;
}

class GenreAndFeatures extends Component<IProps, IState> {
    state = {
        initialized: false,
        selectors: {
            primaryGenre: {
                labelId: 'label.primaryGenre',
                name: 'primaryGenre',
                options: [],
                minSelected: 1,
                maxSelected: 1,
                instructionIds: ['instruction.primaryGenre']
            },
            secondaryGenre: {
                labelId: 'label.secondaryGenre',
                name: 'secondaryGenre',
                options: [],
                minSelected: 0,
                maxSelected: 1,
                instructionIds: ['instruction.secondaryGenre']
            }
        },
        optionsSearchFields: {
            features: {
                labelId: 'label.features',
                name: 'features',
                selected: [],
                minSelected: 1,
                maxSelected: 5,
                instructionIds: ['instruction.features.one', 'instruction.features.two']
            },
            tones: {
                labelId: 'label.tones',
                name: 'tones',
                selected: [],
                minSelected: 1,
                maxSelected: 2,
                instructionIds: ['instruction.tones.one', 'instruction.tones.two']
            }
        }
    };

    componentDidMount(): void {
        window.scrollTo(0, 0);

        this.setGenres();
        this.initializeData();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.data !== prevProps.data && !this.state.initialized) {
            this.setState(
                {
                    initialized: true
                },
                () => {
                    //this.initializeData();
                }
            );
        }
    }

    initializeData = () => {
        const {
            data: { primaryGenre, secondaryGenre, tones, features }
        } = this.props;

        if (primaryGenre) this.setSelectorValue('primaryGenre', primaryGenre);
        if (secondaryGenre) this.setSelectorValue('secondaryGenre', secondaryGenre);
        if (features && Array.isArray(features)) this.setOptionsValues('features', features);
        if (tones && Array.isArray(tones)) this.setOptionsValues('tones', tones);
    };

    handleSelectorClicked = (name: string, index: number, selected: boolean): void => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        if (!selectors[name].multiple) {
            selectors[name].options = selectors[name].options.map((item: IOption) => {
                return {
                    ...item,
                    selected: false
                };
            });
        }

        selectors[name].options[index].selected = selected;

        this.setState(
            {
                selectors
            },
            () => {
                const values: IOption | undefined | any = selectors[name].options.find(
                    (item: IOption) => item.selected
                );
                this.props.setData(name, values);
            }
        );
    };

    handleOptionsChanged = (name: string, values: Array<ISelectOption>): void => {
        this.setOptionsSelectSearchValue(name, values);
        //this.props.setData(name, values);
    };

    setSelectorValue = (name: string, values: any, multiple: boolean = false) => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        selectors[name].options = selectors[name].options.map((item: IOption) => {
            return {
                ...item,
                selected: multiple ? values.includes(item.id) : item.id === values.id
            };
        });

        this.setState({
            selectors
        });
    };

    setOptionsValues = (name: string, values: any) => {
        let optionsSearchFields: any = { ...this.state.optionsSearchFields };

        optionsSearchFields[name].selected = values.map((item: IServerOption) => {
            return {
                value: item.id,
                label: item.label
            };
        });

        this.setState({
            optionsSearchFields
        });
    };

    setOptionsSelectSearchValue = (name: string, values: Array<ISelectOption>) => {
        let optionsSearchFields: any = { ...this.state.optionsSearchFields };

        optionsSearchFields[name].selected = values;

        this.setState(
            {
                optionsSearchFields
            },
            () => {
                this.props.setData(name, values);
            }
        );
    };

    setGenres = () => {
        let selectors = { ...this.state.selectors };
        let primary: ISelector = selectors.primaryGenre;
        let secondary: ISelector = selectors.secondaryGenre;

        const serverOptions: Array<IOption> = this.props.genres.map(
            (item: IServerOption): IOption => {
                return {
                    id: item.id,
                    label: item.label,
                    selected: false
                };
            }
        );

        primary.options = serverOptions;
        secondary.options = serverOptions;

        this.setState({
            selectors
        });
    };

    render() {
        const { selectors, optionsSearchFields } = this.state;
        const { tones, features } = this.props;

        return (
            <div className={[mainClasses.TitleForm, stepClasses.GenreAndFeatures].join(' ')}>
                <div className={mainClasses['TitleForm-header']}>
                    <Text tag={'h2'} transform={'uppercase'} weight={600} spacing={true}>
                        <FormattedMessage id={'title.section.genreAndFeatures'} />
                    </Text>
                </div>
                <div className={mainClasses['TitleForm-content']}>
                    <div className={[mainClasses['TitleForm-field'], mainClasses['TitleForm-field--small']].join(' ')}>
                        <Selector
                            label={
                                selectors.primaryGenre.labelId ? (
                                    <FormattedMessage id={selectors.primaryGenre.labelId} />
                                ) : (
                                    ''
                                )
                            }
                            minSelected={selectors.primaryGenre.minSelected}
                            name={selectors.primaryGenre.name}
                            clicked={this.handleSelectorClicked}
                            options={selectors.primaryGenre.options}
                        />
                    </div>
                    <div className={mainClasses['TitleForm-field']}>
                        <Selector
                            label={
                                selectors.secondaryGenre.labelId ? (
                                    <FormattedMessage id={selectors.secondaryGenre.labelId} />
                                ) : (
                                    ''
                                )
                            }
                            name={selectors.secondaryGenre.name}
                            clicked={this.handleSelectorClicked}
                            instructionIds={selectors.secondaryGenre.instructionIds}
                            options={selectors.secondaryGenre.options}
                        />
                    </div>
                    <div className={mainClasses['TitleForm-field']}>
                        <OptionsSelect
                            minSelected={5}
                            maxSelected={5}
                            name={optionsSearchFields.features.name}
                            label={<FormattedMessage id={optionsSearchFields.features.labelId} />}
                            changed={this.handleOptionsChanged}
                            selected={optionsSearchFields.features.selected}
                            instructionIds={optionsSearchFields.features.instructionIds}
                            options={features}
                        />
                    </div>
                    <div className={mainClasses['TitleForm-field']}>
                        <OptionsSelect
                            minSelected={2}
                            maxSelected={2}
                            name={optionsSearchFields.tones.name}
                            label={<FormattedMessage id={optionsSearchFields.tones.labelId} />}
                            changed={this.handleOptionsChanged}
                            selected={optionsSearchFields.tones.selected}
                            instructionIds={optionsSearchFields.tones.instructionIds}
                            options={tones}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        genres: state.genreState.items
    };
};

export default connect(mapStateToProps)(GenreAndFeatures);
