export default {
    LOCAL_STORAGE: {
        KEYS: {
            USER_NAME: 'ac_mp_user_name',
            USER_SURNAME: 'ac_mp_user_surname',
            USER_EMAIL: 'ac_mp_user_email',
            USER_IMAGE: 'ac_mp_user_image',
            USER_CITY: 'ac_mp_user_city',
            USER_ID: 'ac_mp_user_id',
            CURRENT_STEP: 'ac_mp_current_step',
            EXPIRATION_DATE: 'expiration_date',
            AUTH_TOKEN: 'ac_mp_auth_token'
        }
    },
    GENRE_OPTIONS: [
        {
            id: 1,
            label: 'MASCHILE',
            code: 'M'
        },
        {
            id: 2,
            label: 'FEMMINILE',
            code: 'F'
        },
        {
            id: 3,
            label: 'ALTRO',
            code: 'Altro'
        }
    ],
    AGE_OPTIONS: [
        {
            id: 1,
            label: 'ETÀ PRECISA',
            code: 'eta-precisa'
        },
        {
            id: 2,
            label: 'DA - A',
            code: 'range',
            selected: true
        }
    ],
    WHERE_OPTIONS: [
        {
            id: 1,
            label: 'NON LUOGO',
            code: 'non luogo'
        },
        {
            id: 2,
            label: 'LUOGO FANTASTICO',
            code: 'luogo fantastico'
        },
        {
            id: 3,
            label: 'SPECIFICA LUOGO',
            code: 'specifica luogo'
        }
    ],
    WHEN_OPTIONS: [
        {
            id: 1,
            label: 'PASSATO',
            code: 'passato'
        },
        {
            id: 2,
            label: 'PRESENTE',
            code: 'presente'
        },
        {
            id: 7,
            label: 'PRESENTE ALTERNATIVO',
            code: 'presente-alternativo'
        },
        {
            id: 3,
            label: 'FUTURO',
            code: 'futuro'
        },
        {
            id: 4,
            label: 'NON DEFINITO',
            code: 'non definito'
        },
        {
            id: 5,
            label: 'DATA SPECIFICA',
            code: 'data specifica'
        },
        {
            id: 6,
            label: 'PERIODO',
            code: 'range di mesi-anni'
        }
    ],
    REPRINTS_OPTIONS: [
        {
            id: 1000,
            label: '1000+',
            code: '1000+'
        },
        {
            id: 10000,
            label: '10000+',
            code: '10000+'
        },
        {
            id: 100000,
            label: '100000+',
            code: '100000+'
        }
    ],
    ENTITY_REDIRECTS: {
        dashboard_unlock: {
            cta: 'Go to Invites',
            route: '/invites',
            hasEntityId: false
        },
        dashboard_access: {
            cta: 'Go to Invites',
            route: '/invites',
            hasEntityId: false
        },
        project_to_draft: {
            cta: 'Edit and publish',
            route: '/work',
            hasEntityId: true
        },
        invitation_sent: {
            cta: 'Go to Invites',
            route: '/invites',
            hasEntityId: false
        },
        invitation_accepted: {
            cta: 'Go to Invites',
            route: '/invites',
            hasEntityId: false
        },
        dashboard_welcome: {
            cta: 'Go to your Portfolio',
            route: '/profile',
            hasEntityId: false
        },
        project_to_online: {
            cta: 'Browse the network',
            route: '/network',
            hasEntityId: false
        },
        user_to_ambassador: {
            cta: '',
            route: '',
            hasEntityId: false
        },
        original_collaboration_sent: {
            cta: 'View the Original',
            route: '/originals',
            hasEntityId: true
        },
        original_user_sent: {
            cta: 'View the Original',
            route: '/originals',
            hasEntityId: true
        },
        original_to_publish: {
            cta: 'Go to Original',
            route: '/originals',
            hasEntityId: true
        },
        original_to_draft: {
            cta: 'Edit and publish',
            route: '/original',
            hasEntityId: true
        },
        original_request_access: {
            cta: 'Manage access',
            route: '/my-originals/requests',
            hasEntityId: false
        },
        original_request_accepted: {
            cta: 'View the Original',
            route: '/originals',
            hasEntityId: true
        }
    },
    NOTIFICATION_FETCH_TIME: 60000,
    NOTIFICATIONS_DISABLED: false,
    TEXT_MIME_TYPES: 'application/pdf',
    DOCUMENT_MIME_TYPES:
        'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/mspowerpoint, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/jpeg, image/png, image/gif',
    IMAGE_MIME_TYPES: 'image/jpeg, image/png, image/gif',
    DOCUMENT_LABEL_FOR_MIME_TYPES: [
        {
            mime_type: 'application/pdf',
            label: 'PDF File'
        },
        {
            mime_type: 'application/msword',
            label: 'MS Office Word'
        },
        {
            mime_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            label: 'MS Office Word'
        },
        {
            mime_type: 'application/mspowerpoint',
            label: 'MS Office Power Point'
        },
        {
            mime_type: 'application/vnd.ms-powerpoint',
            label: 'MS Office Power Point'
        },
        {
            mime_type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            label: 'MS Office Power Point'
        },
        {
            mime_type: 'image/jpeg',
            label: 'Image'
        },
        {
            mime_type: 'image/png',
            label: 'Image'
        },
        {
            mime_type: 'image/gif',
            label: 'GIF'
        }
    ],
    DEFAULT_PAGINATION: {
        offset: 0,
        limit: 12
    },
    CONVERSATION_FETCH_TIME: 10000,
    CONVERSATION_INTERLOCUTORS_FETCH_TIME: 10000,
    UNREAD_MESSAGES_FETCH_TIME: 10000,
    GOOGLE_API_KEY: 'AIzaSyBZmoDLRlhl_QHf1zvIMYP6Cu30ML6zDPo',
    GOOGLE_MAPS_URL:
        'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBZmoDLRlhl_QHf1zvIMYP6Cu30ML6zDPo'
};
