import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { IFile, ITitle, IUploader } from '../../../shared/interfaces';
import mainClasses from '../styles.module.scss';
import stepClasses from './styles.module.scss';
import Text from '../../UI/Text';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import Uploader from '../../Field/Uploader';
import Loader from '../../UI/Loader';
import TitleCard from '../../TitleCard';

interface IProps {
    data: ITitle;
    setData(name: string, value: any): void;
    file: Array<IFile>;
    onResetFile(): void;
    onCreateFile(data: object): IFile;
    waitingForSavingFile: boolean;
}

interface IUploaderControls {
    fileCover: IUploader;
}

interface IState {
    uploader: IUploaderControls;
}

class CoverAndPreview extends Component<IProps, IState> {
    state = {
        uploader: {
            fileCover: {
                value: null,
                accept: 'image/jpeg, image/png',
                titleId: 'upload.cover.title',
                multiple: false
            }
        }
    };

    componentDidMount(): void {
        this.initializeData();
    }

    handleUploadImage = (uploaded: Array<File>) => {
        const data = new FormData();

        data.append('file', uploaded[0]);

        this.props.onCreateFile(data);
    };

    initializeData = () => {
        const {
            data: { fileCover }
        } = this.props;
        const uploader = { ...this.state.uploader };

        if (fileCover) {
            // @ts-ignore
            uploader.fileCover.value = fileCover;

            this.setState({
                uploader
            });
        }
    };

    handleSetCover = (value: IFile | null) => {
        const uploader: any = { ...this.state.uploader };

        uploader.fileCover.value = value;

        this.setState(
            {
                uploader
            },
            () => {
                if (value) this.props.setData('fileCover', value.code);
            }
        );
    };

    handleRemoveCover = () => {
        const uploader: any = { ...this.state.uploader };

        uploader.fileCover.value = null;

        this.setState(
            {
                uploader
            },
            () => {
                this.props.setData('fileCover', null);
            }
        );
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (
            this.props.data !== prevProps.data &&
            this.props.data &&
            !this.state.uploader.fileCover.value
        ) {
            this.initializeData();
        }
        if (this.props.file !== prevProps.file && this.props.file) {
            this.handleSetCover(this.props.file[0]);
        }
    }

    render() {
        const {
            uploader: { fileCover }
        } = this.state;
        const { waitingForSavingFile } = this.props;

        const coverValue: any = fileCover.value;

        return (
            <div className={[mainClasses.TitleForm, stepClasses.CoverAndPreview].join(' ')}>
                <div className={mainClasses['TitleForm-header']}>
                    <Text tag={'h2'} transform={'uppercase'} weight={600} spacing={true}>
                        <FormattedMessage id={'title.section.coverAndPreview'} />
                    </Text>
                </div>
                <div className={mainClasses['TitleForm-content']}>
                    <div className={stepClasses['CoverAndPreview-field']}>
                        <div className={stepClasses['CoverAndPreview-uploader']}>
                            {waitingForSavingFile &&
                                <div className={stepClasses['CoverAndPreview-loading']}>
                                    <Loader size={'sm'} theme={'light'} />
                                </div>
                            }
                            <div className={stepClasses['CoverAndPreview-description']}>
                                <FormattedMessage id={'instruction.cover.description'} />
                            </div>
                            <Uploader
                                squared
                                large
                                fullWidth
                                disabled={waitingForSavingFile}
                                titleId={fileCover.titleId}
                                accept={fileCover.accept}
                                onSelectedFiles={this.handleUploadImage}
                            />
                            {coverValue && (
                                <button
                                    onClick={this.handleRemoveCover}
                                    className={stepClasses['CoverAndPreview-remover']}
                                >
                                    <FormattedMessage id={'upload.cover.remove'} />
                                </button>
                            )}
                        </div>
                        <div className={stepClasses['CoverAndPreview-card']}>
                            <TitleCard
                                isFlat={true}
                                title={this.props.data}
                                coverUrl={coverValue ? coverValue.url : null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        file: state.fileState.file,
        waitingForSavingFile: state.fileState.isStoring
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreateFile: (data: object) => dispatch(actions.createFile(data)),
        onResetFile: () => dispatch(actions.resetFile())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoverAndPreview);
