import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { IAuthor, IUser } from '../../shared/interfaces';
import classes from './styles.module.scss';
import Loader from '../UI/Loader';
import AuthorCard from '../AuthorCard';

interface IProps {
    currentUser: IUser;
    authors: Array<IAuthor>;
    error?: string;
    onGetMyAuthors(userId: number): Array<IAuthor>;
    waitingForAuthors: boolean;
}

class AuthorsList extends Component<IProps, {}> {
    componentDidMount(): void {
        const { currentUser } = this.props;

        this.props.onGetMyAuthors(currentUser.id);
    }

    render() {
        const { authors, waitingForAuthors } = this.props;

        const authorsTpl =
            authors &&
            authors.map((author: IAuthor, index: number) => {
                return <AuthorCard key={index} author={author} />;
            });

        return waitingForAuthors ? (
            <Loader theme={'light'} fullContent={true} size={'sm'} />
        ) : (
            <div className={classes.AuthorsList}>
                <div className={classes['AuthorsList-content']}>{authorsTpl}</div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.userState.user,
        authors: state.userState.authors,
        waitingForAuthors: state.userState.isFetching
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetMyAuthors: (userId: number) => dispatch(actions.fetchMyAuthors(userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorsList);
