import * as actionTypes from './actionTypes';
import TargetsService from '../../services/api/targets';
import { IError, IServerOption, IResponse } from '../../shared/interfaces';

// READ/FETCH
export const fetchTargetStart = () => {
    return {
        type: actionTypes.FETCH_TARGET_START
    };
};

export const fetchTargetSuccess = (target: IServerOption) => {
    return {
        type: actionTypes.FETCH_TARGET_SUCCESS,
        target
    };
};

export const fetchTargetFail = (error: string) => {
    return {
        type: actionTypes.FETCH_TARGET_FAIL,
        error
    };
};

export const fetchTarget = (id: number) => {
    return (dispatch: any) => {
        if (id) dispatch(fetchTargetStart());

        TargetsService.get(id).then(
            (response: IResponse) => {
                dispatch(fetchTargetSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchTargetFail(errorMsg));
            }
        );
    };
};

export const fetchTargetsStart = () => {
    return {
        type: actionTypes.FETCH_TARGETS_START
    };
};

export const fetchTargetsSuccess = (targets: Array<IServerOption>) => {
    return {
        type: actionTypes.FETCH_TARGETS_SUCCESS,
        targets
    };
};

export const fetchTargetsFail = (error: string) => {
    return {
        type: actionTypes.FETCH_TARGETS_FAIL,
        error
    };
};

export const fetchTargets = () => {
    return (dispatch: any) => {
        dispatch(fetchTargetsStart());

        TargetsService.get().then(
            (response: IResponse) => {
                dispatch(fetchTargetsSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchTargetsFail(errorMsg));
            }
        );
    };
};
