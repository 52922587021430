import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    children: any;
}

const MainSection = (props: IProps) => (
    <section className={classes.MainSection}>{props.children}</section>
);

export default MainSection;
