import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FormattedMessage } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';
import classes from './styles.module.scss';
import './style.scss';
import Instruction from '../../UI/Instruction';

interface IProps {
    name: string;
    isPlainText?: boolean;
    toolbarHidden?: boolean;
    label?: string | object;
    placeholderId?: string;
    value?: any;
    onChange?: any;
    color?: string;
    required?: boolean;
    maxLength?: number;
    suggestedLength?: number;
    autosize?: boolean;
    instructionIds?: Array<string>;
}
interface IState {
    editorState: any;
    error: boolean;
    value: string;
}

const EDITOR_OPTIONS = {
    options: ['inline', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        bold: { className: classes['TextAreaEditor-button'] },
        italic: { className: classes['TextAreaEditor-button'] },
        underline: { className: classes['TextAreaEditor-button'] },
        strikethrough: { className: classes['TextAreaEditor-button'] }
    }
};

class TextAreaEditor extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const html = props.value ? props.value : '<p></p>';
        const contentBlock = htmlToDraft(html);

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState,
                error: false,
                value: props.value
            };
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.value !== prevProps.value) {
            const html = this.props.value ? this.props.value : '<p></p>';
            const contentBlock = htmlToDraft(html);

            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState(
                    {
                        editorState,
                        error: false,
                        value: this.props.value
                    },
                    () => {
                        this.checkLength(this.props.value);
                    }
                );
            }
        }
    }

    checkLength = (value: string) => {
        let isOk = true;

        if (this.props.maxLength) {
            if (value.length > this.props.maxLength) {
                isOk = false;
            }
        }

        this.setState({
            error: !isOk
        });

        return isOk;
    };

    onEditorStateChange = (editorState: any) => {
        const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        this.checkLength(value);

        const { isPlainText } = this.props;

        if (!isPlainText) {
            this.setState(
                {
                    editorState
                },
                this.props.onChange(this.props.name, value)
            );
        }
    };

    onTextAreaChange = (value: string) => {
        this.setState(
            {
                value
            },
            () => {
                this.props.onChange(this.props.name, this.state.value);
            }
        );
    };

    render() {
        const { editorState, error, value } = this.state;

        const {
            toolbarHidden,
            label,
            placeholderId,
            color,
            isPlainText,
            required,
            maxLength,
            suggestedLength,
            autosize,
            instructionIds
        } = this.props;

        return (
            <div
                className={[
                    classes.TextAreaEditor,
                    error ? classes['TextAreaEditor--error'] : ''
                ].join(' ')}
            >
                <div className={classes['TextAreaEditor-content']}>
                    <div className={classes['TextAreaEditor-wrapper']}>
                        <div className={classes['TextAreaEditor-header']}>
                            <label className={classes['TextAreaEditor-label']}>{label}</label>
                            {required && (
                                <span className={classes['TextAreaEditor-required']}>
                                    <FormattedMessage id={'general.required'} />
                                </span>
                            )}
                        </div>
                        {!isPlainText ? (
                            <>
                                <FormattedMessage
                                    id={placeholderId || 'general.emptyMessage'}
                                    defaultMessage={''}
                                >
                                    {(placeholder: any) => (
                                        <Editor
                                            stripPastedStyles={true}
                                            toolbarHidden={toolbarHidden}
                                            editorState={editorState}
                                            toolbarClassName={[
                                                classes['TextAreaEditor-toolbar'],
                                                color
                                                    ? classes[`TextAreaEditor-toolbar--${color}`]
                                                    : ''
                                            ].join(' ')}
                                            wrapperClassName={[
                                                classes['TextAreaEditor-wrapper'],
                                                color
                                                    ? classes[`TextAreaEditor-wrapper--${color}`]
                                                    : ''
                                            ].join(' ')}
                                            editorClassName={
                                                toolbarHidden
                                                    ? classes['TextAreaEditor-editor']
                                                    : [
                                                          classes['TextAreaEditor-editor'],
                                                          classes[
                                                              'TextAreaEditor-editor--withToolbar'
                                                          ]
                                                      ].join(' ')
                                            }
                                            toolbar={EDITOR_OPTIONS}
                                            placeholder={placeholder}
                                            onEditorStateChange={this.onEditorStateChange}
                                        />
                                    )}
                                </FormattedMessage>
                                {autosize ? (
                                    <TextareaAutosize
                                        style={{
                                            width: '1px',
                                            height: '1px',
                                            visibility: 'hidden'
                                        }}
                                        disabled
                                        value={draftToHtml(
                                            convertToRaw(editorState.getCurrentContent())
                                        )}
                                    />
                                ) : (
                                    <textarea
                                        style={{
                                            width: '1px',
                                            height: '1px',
                                            visibility: 'hidden'
                                        }}
                                        disabled
                                        value={draftToHtml(
                                            convertToRaw(editorState.getCurrentContent())
                                        )}
                                    />
                                )}
                            </>
                        ) : (
                            <FormattedMessage
                                id={placeholderId || 'general.emptyMessage'}
                                defaultMessage={''}
                            >
                                {(placeholder: any) => (
                                    <div
                                        className={[
                                            classes['TextAreaEditor-wrapper'],
                                            color ? classes[`TextAreaEditor-wrapper--${color}`] : ''
                                        ].join(' ')}
                                    >
                                        {autosize ? (
                                            <TextareaAutosize
                                                placeholder={placeholder}
                                                className={classes['TextAreaEditor-editor']}
                                                onChange={(
                                                    e: React.SyntheticEvent<HTMLTextAreaElement>
                                                ) => this.onTextAreaChange(e.currentTarget.value)}
                                                value={value}
                                            />
                                        ) : (
                                            <textarea
                                                placeholder={placeholder}
                                                className={classes['TextAreaEditor-editor']}
                                                onChange={(
                                                    e: React.SyntheticEvent<HTMLTextAreaElement>
                                                ) => this.onTextAreaChange(e.currentTarget.value)}
                                                value={value}
                                            />
                                        )}
                                    </div>
                                )}
                            </FormattedMessage>
                        )}
                        {maxLength && isPlainText && suggestedLength === 0 && (
                            <span className={classes['TextAreaEditor-counter']}>{`${
                                value ? value.length : '0'
                            }/${maxLength}`}</span>
                        )}

                        { maxLength && suggestedLength > 0 && (
                            <>
                            <span className={classes['TextAreaEditor-counter']}>
                                {`${value ? value.length : '0'}`}
                            </span>
                            <div className={classes['TextAreaEditor-counter-suggestion']}>
                                <div className={classes['TextAreaEditor-counter-suggestion-bar']}>
                                    <div className={classes['TextAreaEditor-counter-suggestion-bar-container']}>
                                        <div
                                            className={
                                                [
                                                    classes['TextAreaEditor-counter-bar-filler'],
                                                    value.length > maxLength ?
                                                    classes['TextAreaEditor-counter-bar-filler--toolong']
                                                    :
                                                    '',
                                                    value.length < suggestedLength ?
                                                    classes['TextAreaEditor-counter-bar-filler--tooshort']
                                                    :
                                                    ''
                                                ].join(' ')
                                            }
                                            style={{width: `${value ? value.length*100/maxLength : '0'}%`}}>
                                        </div>
                                    </div>
                                    <span className={classes['TextAreaEditor-counter-init']}>0</span>
                                    <span
                                        className={classes['TextAreaEditor-counter-suggested']}
                                        style={{width: `${suggestedLength ? suggestedLength*100/maxLength : '0'}%`}}>
                                        {suggestedLength}</span>
                                    <span className={classes['TextAreaEditor-counter-end']}>{maxLength}</span>
                                </div>
                            </div>
                            </>
                        )}
                    </div>
                    {instructionIds && <Instruction textIds={instructionIds} />}
                </div>
            </div>
        );
    }
}

export default TextAreaEditor;
