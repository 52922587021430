import request from '../../shared/lib/request';
import constants from '../../shared/constants';
import { IAuthor, IPersonage, ITitle } from '../../shared/interfaces';

const create = (data: ITitle) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    return request({
        url: '/v2/title',
        method: 'POST',
        headers,
        data
    });
};

const get = (id?: number) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    let endpoint = id ? `/v2/title/${id}` : '/v2/title';

    return request({
        url: endpoint,
        method: 'GET',
        headers
    });
};

const getByUser = (id: number) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    const endpoint = `/v2/title/user/${id}`;

    return request({
        url: endpoint,
        method: 'GET',
        headers
    });
};

const getMine = () => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    const endpoint = `/v2/title/me`;

    return request({
        url: endpoint,
        method: 'GET',
        headers
    });
};

const update = (id: number, data: ITitle) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    const endpoint = `/v2/title/${id}`;

    return request({
        url: endpoint,
        method: 'PATCH',
        headers,
        data
    });
};

const remove = (id: number) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    return request({
        url: `/v2/title/${id}`,
        method: 'DELETE',
        headers
    });
};

const createPersonage = (titleId: number, personage: IPersonage) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    const endpoint = `/v2/title/${titleId}/personage`;

    return request({
        url: endpoint,
        method: 'POST',
        headers,
        data: personage
    });
};

const removePersonage = (titleId: number, personageId: number) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    return request({
        url: `/v2/title/${titleId}/personage/${personageId}`,
        method: 'DELETE',
        headers
    });
};

const updatePersonage = (personageId: number, personage: IPersonage) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    const endpoint = `/v2/personage/${personageId}`;

    return request({
        url: endpoint,
        method: 'PATCH',
        headers,
        data: personage
    });
};

const createAuthor = (titleId: number, author: IAuthor) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    const endpoint = `/v2/title/${titleId}/author`;

    return request({
        url: endpoint,
        method: 'POST',
        headers,
        data: author
    });
};

const removeAuthor = (titleId: number, authorId: number) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    return request({
        url: `/v2/title/${titleId}/author/${authorId}`,
        method: 'DELETE',
        headers
    });
};

const updateAuthor = (authorId: number, author: IAuthor) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    const endpoint = `/v2/author/${authorId}`;

    return request({
        url: endpoint,
        method: 'PATCH',
        headers,
        data: author
    });
};

const sort = (ids: Array<number>) => {
    const authToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);

    const headers = {
        Authorization: `Bearer ${authToken}`
    };

    const endpoint = `/v2/title/index`;

    return request({
        url: endpoint,
        method: 'PATCH',
        headers,
        data: ids
    });
};

const TitlesService = {
    get,
    getByUser,
    getMine,
    create,
    update,
    remove,
    createPersonage,
    removePersonage,
    updatePersonage,
    createAuthor,
    updateAuthor,
    removeAuthor,
    sort
};

export default TitlesService;
