import * as actionTypes from './actionTypes';
import TitlesService from '../../services/api/titles';
import { IAuthor, IError, IPersonage, IResponse, ITitle } from '../../shared/interfaces';

// CREATE
export const createTitleStart = () => {
    return {
        type: actionTypes.CREATE_TITLE_START
    };
};

export const createTitleSuccess = (title: ITitle) => {
    return {
        type: actionTypes.CREATE_TITLE_SUCCESS,
        title
    };
};

export const createTitleFail = (error: string) => {
    return {
        type: actionTypes.CREATE_TITLE_FAIL,
        error
    };
};

export const createTitle = (data: ITitle) => {
    return (dispatch: any) => {
        dispatch(createTitleStart());

        TitlesService.create(data).then(
            (response: IResponse) => {
                dispatch(createTitleSuccess(response.data));
            },
            (errors: any) => {
                const {
                    data: { info, error }
                } = errors;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(createTitleFail(errorMsg));
            }
        );
    };
};

// READ/FETCH
export const fetchTitleStart = () => {
    return {
        type: actionTypes.FETCH_TITLE_START
    };
};

export const fetchTitleSuccess = (title: ITitle) => {
    return {
        type: actionTypes.FETCH_TITLE_SUCCESS,
        title
    };
};

export const fetchTitleFail = (error: string) => {
    return {
        type: actionTypes.FETCH_TITLE_FAIL,
        error
    };
};

export const fetchTitle = (id: number) => {
    return (dispatch: any) => {
        dispatch(fetchTitleStart());

        TitlesService.get(id).then(
            (response: IResponse) => {
                dispatch(fetchTitleSuccess(response.data));
            },
            (error: any) => {
                const {
                    data: { info }
                } = error;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(fetchTitleFail(errorMsg));
            }
        );
    };
};
export const fetchTitlesStart = () => {
    return {
        type: actionTypes.FETCH_TITLES_START
    };
};

export const fetchTitlesSuccess = (title: ITitle) => {
    return {
        type: actionTypes.FETCH_TITLES_SUCCESS,
        title
    };
};

export const fetchTitlesFail = (error: string) => {
    return {
        type: actionTypes.FETCH_TITLES_FAIL,
        error
    };
};

export const fetchTitles = () => {
    return (dispatch: any) => {
        dispatch(fetchTitlesStart());

        TitlesService.get().then(
            (response: IResponse) => {
                dispatch(fetchTitlesSuccess(response.data));
            },
            (error: any) => {
                const {
                    data: { info }
                } = error;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(fetchTitlesFail(errorMsg));
            }
        );
    };
};

export const fetchMyTitlesStart = () => {
    return {
        type: actionTypes.FETCH_MY_TITLES_START
    };
};

export const fetchMyTitlesSuccess = (list: Array<ITitle>) => {
    return {
        type: actionTypes.FETCH_MY_TITLES_SUCCESS,
        list
    };
};

export const fetchMyTitlesFail = (error: string) => {
    return {
        type: actionTypes.FETCH_MY_TITLES_FAIL,
        error
    };
};

export const fetchMyTitles = () => {
    return (dispatch: any) => {
        dispatch(fetchMyTitlesStart());

        TitlesService.getMine().then(
            (response: IResponse) => {
                dispatch(fetchMyTitlesSuccess(response.data));
            },
            (error: any) => {
                const {
                    data: { info }
                } = error;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(fetchMyTitlesFail(errorMsg));
            }
        );
    };
};

// UPDATE
export const updateTitleStart = () => {
    return {
        type: actionTypes.UPDATE_TITLE_START
    };
};

export const updateTitleSuccess = (title: ITitle) => {
    return {
        type: actionTypes.UPDATE_TITLE_SUCCESS,
        title
    };
};

export const updateTitleFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_TITLE_FAIL,
        error
    };
};

export const updateTitle = (id: number, data: ITitle) => {
    return (dispatch: any) => {
        dispatch(updateTitleStart());

        TitlesService.update(id, data).then(
            (response: IResponse) => {
                dispatch(updateTitleSuccess(response.data));
            },
            (errors: any) => {
                const {
                    data: { info, error }
                } = errors;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(updateTitleFail(errorMsg));
            }
        );
    };
};

// DELETE
export const deleteTitleStart = () => {
    return {
        type: actionTypes.DELETE_TITLE_START
    };
};

export const deleteTitleSuccess = (id: number) => {
    return {
        type: actionTypes.DELETE_TITLE_SUCCESS,
        id
    };
};

export const deleteTitleFail = (error: string) => {
    return {
        type: actionTypes.DELETE_TITLE_FAIL,
        error
    };
};

export const deleteTitle = (id: number) => {
    return (dispatch: any) => {
        dispatch(deleteTitleStart());

        TitlesService.remove(id).then(
            (response: IResponse) => {
                dispatch(deleteTitleSuccess(id));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(deleteTitleFail(errorMsg));
            }
        );
    };
};

export const resetMyTitles = () => {
    return {
        type: actionTypes.RESET_MY_TITLES
    };
};

export const resetMyLastTitle = () => {
    return {
        type: actionTypes.RESET_MY_LAST_TITLE
    };
};

// PERSONAGES

// CREATE
export const createPersonageForTitleStart = () => {
    return {
        type: actionTypes.CREATE_PERSONAGE_FOR_TITLE_START
    };
};

export const createPersonageForTitleSuccess = (personage: IPersonage) => {
    return {
        type: actionTypes.CREATE_PERSONAGE_FOR_TITLE_SUCCESS,
        personage
    };
};

export const createPersonageForTitleFail = (error: string) => {
    return {
        type: actionTypes.CREATE_PERSONAGE_FOR_TITLE_FAIL,
        error
    };
};

export const createPersonageForTitle = (titleId: number, personage: IPersonage) => {
    return (dispatch: any) => {
        dispatch(createPersonageForTitleStart());

        TitlesService.createPersonage(titleId, personage).then(
            (response: IResponse) => {
                const title = response.data;
                const lastPersonage: IPersonage = title
                    ? title.personages[title.personages.length - 1]
                    : null;

                dispatch(createPersonageForTitleSuccess(lastPersonage));
            },
            (errors: any) => {
                const {
                    data: { info, error }
                } = errors;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(createPersonageForTitleFail(errorMsg));
            }
        );
    };
};

// DELETE
export const deletePersonageForTitleTitleStart = () => {
    return {
        type: actionTypes.DELETE_PERSONAGE_FOR_TITLE_START
    };
};

export const deletePersonageForTitleTitleSuccess = (id: number) => {
    return {
        type: actionTypes.DELETE_PERSONAGE_FOR_TITLE_SUCCESS,
        id
    };
};

export const deletePersonageForTitleTitleFail = (error: string) => {
    return {
        type: actionTypes.DELETE_PERSONAGE_FOR_TITLE_FAIL,
        error
    };
};

export const deletePersonageForTitleTitle = (titleId: number, personageId: number) => {
    return (dispatch: any) => {
        dispatch(deletePersonageForTitleTitleStart());

        TitlesService.removePersonage(titleId, personageId).then(
            (response: IResponse) => {
                dispatch(deletePersonageForTitleTitleSuccess(personageId));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(deletePersonageForTitleTitleFail(errorMsg));
            }
        );
    };
};

// UPDATE
export const updatePersonageStart = () => {
    return {
        type: actionTypes.UPDATE_PERSONAGE_START
    };
};

export const updatePersonageSuccess = (personage: IPersonage) => {
    return {
        type: actionTypes.UPDATE_PERSONAGE_SUCCESS,
        personage
    };
};

export const updatePersonageFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_PERSONAGE_FAIL,
        error
    };
};

export const updatePersonage = (personageId: number, personage: IPersonage) => {
    return (dispatch: any) => {
        dispatch(updatePersonageStart());

        TitlesService.updatePersonage(personageId, personage).then(
            (response: IResponse) => {
                const lastPersonage: IPersonage = response.data;

                dispatch(updatePersonageSuccess(lastPersonage));
            },
            (errors: any) => {
                const {
                    data: { info, error }
                } = errors;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(updatePersonageFail(errorMsg));
            }
        );
    };
};

// AUTHOR

// CREATE
export const createAuthorForTitleStart = () => {
    return {
        type: actionTypes.CREATE_AUTHOR_FOR_TITLE_START
    };
};

export const createAuthorForTitleSuccess = (author: IAuthor) => {
    return {
        type: actionTypes.CREATE_AUTHOR_FOR_TITLE_SUCCESS,
        author
    };
};

export const createAuthorForTitleFail = (error: string) => {
    return {
        type: actionTypes.CREATE_AUTHOR_FOR_TITLE_FAIL,
        error
    };
};

export const createAuthorForTitle = (titleId: number, author: IAuthor) => {
    return (dispatch: any) => {
        dispatch(createAuthorForTitleStart());

        TitlesService.createAuthor(titleId, author).then(
            (response: IResponse) => {
                const title = response.data;
                const lastAuthor: IAuthor = title ? title.authors[title.authors.length - 1] : null;

                dispatch(createAuthorForTitleSuccess(lastAuthor));
            },
            (errors: any) => {
                const {
                    data: { info, error }
                } = errors;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(createAuthorForTitleFail(errorMsg));
            }
        );
    };
};

// DELETE
export const deleteAuthorForTitleTitleStart = () => {
    return {
        type: actionTypes.DELETE_AUTHOR_FOR_TITLE_START
    };
};

export const deleteAuthorForTitleTitleSuccess = (id: number) => {
    return {
        type: actionTypes.DELETE_AUTHOR_FOR_TITLE_SUCCESS,
        id
    };
};

export const deleteAuthorForTitleTitleFail = (error: string) => {
    return {
        type: actionTypes.DELETE_AUTHOR_FOR_TITLE_FAIL,
        error
    };
};

export const deleteAuthorForTitleTitle = (titleId: number, authorId: number) => {
    return (dispatch: any) => {
        dispatch(deleteAuthorForTitleTitleStart());

        TitlesService.removeAuthor(titleId, authorId).then(
            (response: IResponse) => {
                dispatch(deleteAuthorForTitleTitleSuccess(authorId));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(deleteAuthorForTitleTitleFail(errorMsg));
            }
        );
    };
};

// UPDATE
export const updateAuthorStart = () => {
    return {
        type: actionTypes.UPDATE_AUTHOR_START
    };
};

export const updateAuthorSuccess = (author: IAuthor) => {
    return {
        type: actionTypes.UPDATE_AUTHOR_SUCCESS,
        author
    };
};

export const updateAuthorFail = (error: string) => {
    return {
        type: actionTypes.UPDATE_AUTHOR_FAIL,
        error
    };
};

export const updateAuthor = (authorId: number, author: IAuthor) => {
    return (dispatch: any) => {
        dispatch(updateAuthorStart());

        TitlesService.updateAuthor(authorId, author).then(
            (response: IResponse) => {
                const lastAuthor: IAuthor = response.data;

                dispatch(updateAuthorSuccess(lastAuthor));
            },
            (errors: any) => {
                const {
                    data: { info, error }
                } = errors;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(updateAuthorFail(errorMsg));
            }
        );
    };
};

// SORT
export const sortTitlesStart = () => {
    return {
        type: actionTypes.SORT_TITLES_START
    };
};

export const sortTitlesSuccess = () => {
    return {
        type: actionTypes.SORT_TITLES_SUCCESS
    };
};

export const sortTitlesFail = (error: string) => {
    return {
        type: actionTypes.SORT_TITLES_FAIL,
        error
    };
};

export const sortTitles = (ids: Array<number>) => {
    return (dispatch: any) => {
        dispatch(sortTitlesStart());

        TitlesService.sort(ids).then(
            () => {
                dispatch(sortTitlesSuccess());
            },
            (errors: any) => {
                const {
                    data: { info, error }
                } = errors;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(sortTitlesFail(errorMsg));
            }
        );
    };
};
