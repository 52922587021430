import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
    IAuthor,
    IInput,
    IOption,
    ISelectOption,
    ISelector,
    ISelectSearch,
    IServerOption,
    ISwitch,
    ITextArea,
    ITitle
} from '../../../shared/interfaces';
import mainClasses from '../styles.module.scss';
import stepClasses from './styles.module.scss';
import Input from '../../Field/Input';
import TextAreaEditor from '../../Field/TextAreaEditor';
import Text from '../../UI/Text';
import Switch from '../../Field/Switch';
import DateInput from '../../UI/DateInput';
import moment from 'moment';
import OptionsSelect from '../../Field/OptionsSelect';
import Selector from '../../Field/Selector';
import { isSaga } from '../../../shared/helpers/checks';

interface IProps {
    data: ITitle;
    setData(name: string, value: any): void;
    authorsList: Array<IAuthor>;
    publicTargets: Array<IServerOption>;
    formats: Array<IServerOption>;
    authorChanged(author: IAuthor | null, isNew: boolean): void;
    lastAuthorData: {
        data: IAuthor | null;
        isNew: boolean;
    };
}

interface IControls {
    biography: ITextArea;
    volumes: IInput;
}

interface ISelectors {
    publicTargets: ISelector;
}

interface IOptionSearchFields {
    author: ISelectSearch;
}

interface IState {
    authors: Array<ISelectOption>;
    publishedAt: string;
    controls: IControls;
    checkboxes: {
        alreadyPublished: ISwitch;
    };
    selectors: ISelectors;
    optionsSearchFields: IOptionSearchFields;
    initialized: boolean;
    currentAuthor: null | IAuthor;
    isNewAuthor: boolean;
    currentBiography: string;
}

class AuthorAndFormat extends Component<IProps, IState> {
    state = {
        initialized: false,
        authors: [],
        publishedAt: '',
        controls: {
            biography: {
                isPlainText: true,
                toolbarHidden: true,
                autosize: true,
                name: 'biography',
                value: '',
                type: 'textarea',
                placeholderId: 'placeholder.biography',
                labelId: 'label.biography',
                required: true,
                instructionIds: ['instruction.biography']
            },
            volumes: {
                name: 'volumes',
                value: '',
                type: 'number',
                placeholderId: 'placeholder.volumes',
                labelId: 'label.volumes',
                required: false
            }
        },
        checkboxes: {
            alreadyPublished: {
                name: 'alreadyPublished',
                labelId: 'registration.alreadyPublished',
                checked: true
            }
        },
        optionsSearchFields: {
            author: {
                placeholderId: 'placeholder.author',
                labelId: 'label.author',
                name: 'author',
                selected: [],
                minSelected: 1,
                maxSelected: 1,
                instructionIds: ['instruction.author'],
                required: true
            }
        },
        selectors: {
            publicTargets: {
                labelId: 'label.publicTargets',
                name: 'publicTargets',
                options: [],
                minSelected: 1,
                multiple: true,
                instructionIds: ['instruction.publicTargets']
            },
            format: {
                labelId: 'label.format',
                name: 'format',
                options: [],
                minSelected: 1,
                maxSelected: 1
            }
        },
        currentAuthor: null,
        isNewAuthor: false,
        currentBiography: ''
    };

    componentDidMount(): void {
        this.initializeData(true);
    }

    initializeCurrentAuthor = () => {
        const { authorsList, lastAuthorData } = this.props;

        if (authorsList && Array.isArray(authorsList)) {
            this.setState(
                {
                    currentBiography: lastAuthorData.data ? lastAuthorData.data.biography : ''
                },
                () => {
                    this.setOptionsValues('authors', authorsList, lastAuthorData.data);
                }
            );
        }
    };

    initializeData = (setAuthor: boolean = false) => {
        const {
            data: { volumes, alreadyPublished, publishedAt, publicTargets, format }
        } = this.props;

        this.setSelectorsValues();

        if (setAuthor) this.initializeCurrentAuthor();

        if (publicTargets) this.setSelectorValue('publicTargets', publicTargets);

        if (format) this.setSelectorSingleValue('format', format);

        this.setTextValue('volumes', volumes ? +volumes : '');
        const publishedAtDate = publishedAt ? moment(publishedAt).format('MM/YYYY') : '';
        this.handleCheckboxChanged('alreadyPublished', alreadyPublished, true);

        this.setState({
            publishedAt: publishedAtDate
        });
    };

    setSelectorSingleValue = (name: string, values: any) => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        selectors[name].options = selectors[name].options.map((item: IOption) => {
            return {
                ...item,
                selected: item.id === values.id
            };
        });

        this.setState({
            selectors
        });
    };

    setSelectorsValues = () => {
        let selectors = { ...this.state.selectors };
        let publicTargets: ISelector = selectors.publicTargets;
        let format: ISelector = selectors.format;

        publicTargets.options = this.props.publicTargets.map(
            (item: IServerOption): IOption => {
                return {
                    id: item.id,
                    label: item.label,
                    selected: false
                };
            }
        );

        format.options = this.props.formats.map(
            (item: any): IOption => {
                return {
                    id: item.id,
                    label: item.title,
                    selected: false
                };
            }
        );

        this.setState({
            selectors
        });
    };

    setOptionsValues = (name: string, values: Array<IAuthor>, currentAuthor?: IAuthor) => {
        let authors = values.map((item: IAuthor) => {
            return {
                value: item.id,
                label: item.fullName
            };
        });

        this.setState(
            {
                authors
            },
            () => {
                if (currentAuthor) {
                    this.setCurrentAuthor(currentAuthor);
                }
            }
        );
    };

    setSelectorValue = (name: string, values: Array<any>) => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        const ids = values.map(item => item.id);

        selectors[name].options = selectors[name].options.map((item: IOption) => {
            return {
                ...item,
                selected: ids.indexOf(item.id) >= 0
            };
        });

        this.setState({
            selectors
        });
    };

    handleCheckboxChanged = (name: string, checked: boolean, initialize: boolean = false) => {
        let checkboxes: any = { ...this.state.checkboxes };

        checkboxes[name].checked = checked;

        this.setState(
            {
                checkboxes
            },
            () => {
                !initialize && this.props.setData('alreadyPublished', checked);
            }
        );
    };

    setTextValue = (name: string, value: string | number) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value;

        this.setState({
            controls
        });
    };

    handleInputChanged = (name: string, value: string) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value;

        this.setState(
            {
                controls
            },
            () => {
                this.props.setData(name, +value);
            }
        );
    };

    handleBiographyChanged = (name: string, value: string) => {
        let controls: IControls | any = { ...this.state.controls };

        controls[name].value = value;

        this.setState(
            {
                controls
            },
            () => {
                //this.props.setData(name, value);

                this.setAuthorCheck(
                    this.props.lastAuthorData.data ? this.props.lastAuthorData.data.id : -1
                );
            }
        );
    };

    onChangeDate = (name: string, value: string, type: 'date' | 'month/year') => {
        let date = null;

        // if (type === 'date') {
        //     const testDate = value.split('/');
        //     const customDate = new Date(+testDate[2], +testDate[1] - 1, +testDate[0]);

        //     date = moment(customDate);
        // } else {
            const testDate = value.split('/');
            const customDate = new Date(+testDate[1], +testDate[0] - 1, 1);

            date = moment(customDate);
        // }

        if (date) {
            date = date.format('YYYY-MM-DD');
            this.props.setData(name, date);
        }
    };

    handleOptionsChanged = (name: string, values: Array<ISelectOption>): void => {
        this.setState(
            {
                currentBiography: ''
            },
            () => {
                this.setOptionsSelectSearchValue(name, values);
            }
        );
    };

    setCurrentAuthor = (author: IAuthor) => {
        const selected = {
            value: author.id,
            label: author.fullName
        };

        this.setOptionsSelectSearchValue('author', [selected]);
    };

    setOptionsSelectSearchValue = (name: string, values: Array<ISelectOption>) => {
        let isNew: boolean = false;

        let optionsSearchFields: any = { ...this.state.optionsSearchFields };

        optionsSearchFields[name].selected = values;

        let author = null;

        if (values && values.length && values[0]) {
            let controls: any = { ...this.state.controls };
            author = this.props.authorsList.find((item: IAuthor) => item.id === values[0].value);
            isNew = !author;

            controls.biography.value =
                author && !this.state.currentBiography
                    ? author.biography
                    : this.state.currentBiography;

            this.setState({
                controls
            });
        }

        this.setState(
            {
                optionsSearchFields,
                isNewAuthor: isNew
            },
            () => {
                //this.props.setData(name, values[0]);

                this.setAuthorCheck(author ? author.id : -1);
            }
        );
    };

    handleSelectorClicked = (name: string, index: number, selected: boolean): void => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        if (!selectors[name].multiple) {
            selectors[name].options = selectors[name].options.map((item: IOption) => {
                return {
                    ...item,
                    selected: false
                };
            });
        }

        selectors[name].options[index].selected = selected;

        this.setState(
            {
                selectors
            },
            () => {
                if (name === 'publicTargets') {
                    this.props.setData(
                        name,
                        selectors[name].options.filter((item: IOption) => item.selected)
                    );
                } else {
                    const selected = selectors[name].options.find((item: IOption) => item.selected);

                    if (selected) this.props.setData(name, selected);
                }
            }
        );
    };

    setAuthorCheck = (id: number) => {
        const {
            controls: { biography },
            optionsSearchFields: {
                author: { selected }
            },
            isNewAuthor
        } = this.state;

        const bio = biography.value;

        const author: IAuthor = {
            id: id || -1,
            fullName: selected && selected.length && selected[0] ? selected[0].label : null,
            biography: bio
        };

        this.props.authorChanged(author, isNewAuthor);
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.data !== prevProps.data && this.props.data && !this.state.initialized) {
            this.setState(
                {
                    initialized: true
                },
                () => {
                    this.initializeData();
                }
            );
        }
    }

    render() {
        const {
            controls,
            checkboxes,
            publishedAt,
            optionsSearchFields,
            authors,
            selectors
        } = this.state;

        const inputElementsTpl = Object.values(controls).map((item: IInput, index: number) => {
            return (
                <div key={index} className={mainClasses['TitleForm-field']}>
                    {item.type === 'textarea' ? (
                        <TextAreaEditor
                            name={item.name}
                            isPlainText={item.isPlainText}
                            toolbarHidden={item.toolbarHidden}
                            value={item.value}
                            autosize={item.autosize}
                            label={item.labelId ? <FormattedMessage id={item.labelId} /> : ''}
                            placeholderId={item.placeholderId}
                            required={item.required}
                            maxLength={item.maxLength}
                            instructionIds={item.instructionIds}
                            onChange={
                                item.name === 'biography'
                                    ? this.handleBiographyChanged
                                    : this.handleInputChanged
                            }
                        />
                    ) : (
                        <div
                            key={index}
                            className={[
                                mainClasses['TitleForm-field'],
                                mainClasses['TitleForm-field--small']
                            ].join(' ')}
                        >
                            <Input
                                label={item.labelId ? <FormattedMessage id={item.labelId} /> : ''}
                                required={item.required}
                                type={item.type}
                                name={item.name}
                                placeholderId={item.placeholderId}
                                value={item.value}
                                maxLength={item.maxLength}
                                instructionIds={item.instructionIds}
                                changed={this.handleInputChanged}
                            />
                        </div>
                    )}
                </div>
            );
        });

        const currentFormat = selectors.format.options.find(item => item.selected);

        return (
            <div className={[mainClasses.TitleForm, stepClasses.AuthorAndFormat].join(' ')}>
                <div className={mainClasses['TitleForm-header']}>
                    <Text tag={'h2'} transform={'uppercase'} weight={600} spacing={true}>
                        <FormattedMessage id={'title.section.authorAndFormat'} />
                    </Text>
                </div>
                <div className={mainClasses['TitleForm-content']}>
                    <div className={mainClasses['TitleForm-field']}>
                        <OptionsSelect
                            allowCreation={true}
                            minSelected={optionsSearchFields.author.minSelected}
                            maxSelected={2}
                            name={optionsSearchFields.author.name}
                            label={<FormattedMessage id={optionsSearchFields.author.labelId} />}
                            changed={this.handleOptionsChanged}
                            selected={optionsSearchFields.author.selected}
                            instructionIds={optionsSearchFields.author.instructionIds}
                            options={authors}
                        />
                    </div>
                    {inputElementsTpl[0]}
                    <div
                        className={[
                            mainClasses['TitleForm-field'],
                            mainClasses['TitleForm-field--small']
                        ].join(' ')}
                    >
                        <Selector
                            label={
                                selectors.format.labelId ? (
                                    <FormattedMessage id={selectors.format.labelId} />
                                ) : (
                                    ''
                                )
                            }
                            name={selectors.format.name}
                            clicked={this.handleSelectorClicked}
                            options={selectors.format.options}
                            minSelected={selectors.format.minSelected}
                        />
                    </div>
                    {currentFormat && isSaga(currentFormat.id) && inputElementsTpl[1]}
                    <div
                        className={[
                            mainClasses['TitleForm-field'],
                            mainClasses['TitleForm-field--small']
                        ].join(' ')}
                    >
                        <Switch
                            name={checkboxes.alreadyPublished.name}
                            changed={this.handleCheckboxChanged}
                            label={<FormattedMessage id="label.alreadyPublished" />}
                            checked={checkboxes.alreadyPublished.checked}
                        />
                    </div>
                    {checkboxes.alreadyPublished.checked && (
                        <div
                            className={[
                                mainClasses['TitleForm-field'],
                                mainClasses['TitleForm-field--small']
                            ].join(' ')}
                        >
                            <DateInput
                                label={<FormattedMessage id={'label.publishingDate'} />}
                                name={'publishedAt'}
                                value={publishedAt}
                                type="month/year"
                                placeholder="MM/YYYY"
                                required={true}
                                onChanged={this.onChangeDate}
                            />
                        </div>
                    )}
                    <div className={mainClasses['TitleForm-field']}>
                        <Selector
                            multiple={selectors.publicTargets.multiple}
                            label={
                                selectors.publicTargets.labelId ? (
                                    <FormattedMessage id={selectors.publicTargets.labelId} />
                                ) : (
                                    ''
                                )
                            }
                            name={selectors.publicTargets.name}
                            clicked={this.handleSelectorClicked}
                            instructionIds={selectors.publicTargets.instructionIds}
                            options={selectors.publicTargets.options}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        authorsList: state.userState.authors,
        publicTargets: state.targetState.items,
        formats: state.formatState.items
    };
};

export default connect(mapStateToProps)(AuthorAndFormat);
