import * as actionTypes from './actionTypes';
import FilesService from '../../services/api/files';
import { IResponse } from '../../shared/interfaces';

// CREATE
export const createFileStart = () => {
    return {
        type: actionTypes.CREATE_FILE_START
    };
};

export const createFileSuccess = (file: object | null) => {
    return {
        type: actionTypes.CREATE_FILE_SUCCESS,
        file
    };
};

export const createFileFail = (error: string) => {
    return {
        type: actionTypes.CREATE_FILE_FAIL,
        error
    };
};

export const createFile = (data: object) => {
    return (dispatch: any) => {
        dispatch(createFileStart());

        FilesService.create(data).then(
            (response: IResponse) => {
                if (response.data) {
                    dispatch(createFileSuccess(response.data));
                } else {
                    dispatch(createFileFail('A new file has been uploaded. Please wait.'));
                }
            },
            (errors: any) => {
                const {
                    data: { info, error }
                } = errors;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(createFileFail(errorMsg));
            }
        );
    };
};

export const resetFile = () => {
    FilesService.reset();

    return {
        type: actionTypes.RESET_FILE
    };
};

// READ/FETCH
export const fetchFileStart = () => {
    return {
        type: actionTypes.FETCH_FILE_START
    };
};

export const fetchFileSuccess = (file: object) => {
    return {
        type: actionTypes.FETCH_FILE_SUCCESS,
        file
    };
};

export const fetchFileFail = (error: string) => {
    return {
        type: actionTypes.FETCH_FILE_FAIL,
        error
    };
};

export const fetchFile = (id: string) => {
    return (dispatch: any) => {
        dispatch(fetchFileStart());

        FilesService.get(id).then(
            (response: IResponse) => {
                dispatch(fetchFileSuccess(response.data));
            },
            (error: any) => {
                const {
                    data: { info }
                } = error;

                let errorMsg = null;

                if (info && info.length > 0) {
                    errorMsg = info;
                } else {
                    errorMsg = error ? error : 'Internal server error';
                }

                dispatch(fetchFileFail(errorMsg));
            }
        );
    };
};
