import React, { Component } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions';
import * as routes from '../../shared/routes';
import {
    IAuthor,
    IChecker,
    IFile,
    IPersonage,
    IReview,
    ISelectOption,
    IServerOption,
    ITitle,
    IUser
} from '../../shared/interfaces';
import classes from './styles.module.scss';
import Loader from '../UI/Loader';
import FeedbackMessage from '../UI/FeedbackMessage';
import Toast from '../UI/Toast';
import SalesData from './SalesData';
import TitleAndStory from './TitleAndStory';
import GenreAndFeatures from './GenreAndFeatures';
import AuthorAndFormat from './AuthorAndFormat';
import CoverAndPreview from './CoverAndPreview';
import Personages from './Personages';
import Location from './Location';
import SavingBox from '../UI/SavingBox';
import IPDetails from './IPDetails';
import Documents from './Documents';
import { checkTitleFields, isSaga } from '../../shared/helpers/checks';
import Dialog from '../UI/Dialog';
import { FormattedMessage } from 'react-intl';
import Text from '../UI/Text';
import Button from '../UI/Button';

const DEBUG_TITLE = false;

interface IProps {
    match: {
        params: { step: number; id: number };
    };
    history: History;
    currentUser: IUser;
    title: ITitle;
    waitingForCreation?: boolean;
    waitingForUpdating?: boolean;
    error?: string;
    onCreateTitle(data: ITitle): ITitle;
    onGetTitle(id: number): ITitle;
    onUpdateTitle(id: number, data: ITitle): ITitle;
    onGetAuthors(userId: number): Array<IAuthor>;
    onCreateAuthor(titleId: number, data: IAuthor): ITitle;
    onUpdateAuthor(authorId: number, data: IAuthor): IAuthor;
    onResetMyLastTitle(): void;
    onResetFile(): void;
    onGetGenres(): Array<IServerOption>;
    onGetFeatures(): Array<IServerOption>;
    onGetTones(): Array<IServerOption>;
    onGetPublicTargets(): Array<IServerOption>;
    onGetTranslations(): Array<IServerOption>;
    onGetAwards(): Array<IServerOption>;
    onGetFormats(): Array<IServerOption>;
    authors: Array<IAuthor>;
    genres: Array<IServerOption>;
    features: Array<IServerOption>;
    tones: Array<IServerOption>;
    publicTargets: Array<IServerOption>;
    translations: Array<IServerOption>;
    awards: Array<IServerOption>;
    formats: Array<IServerOption>;
    lastCreatedId: number | null;
    waitingForTones: boolean;
    waitingForGenres: boolean;
    waitingForFetching: boolean;
    waitingForFeatures: boolean;
    waitingForAuthors: boolean;
    waitingForPublicTargets: boolean;
    waitingForTranslations: boolean;
    waitingForAwards: boolean;
    waitingForFormats: boolean;
    created: boolean;
    updated: boolean;
    lastAuthorCreatedForThisTitle: IAuthor;
    personagesList: Array<IPersonage>;
}

interface IState {
    data: ITitle;
    error: string;
    titleId: number;
    step: number;
    features: Array<ISelectOption>;
    tones: Array<ISelectOption>;
    hideToast: boolean;
    redirectUrl: string | null;
    checkStep: boolean;
    fromPreview: boolean;
    fieldsCheckerList: Array<IChecker>;
    showCheckerDialog: boolean;
    fileDocuments: {
        codes: Array<string>;
        files: Array<IFile>;
    };
    isReady: boolean;
    showConfirmPersonages: boolean;
    personagesStatus: {
        counter: number;
        formOpened: boolean;
    };
    currentAuthor: {
        data: IAuthor | null;
        isNew: boolean;
    };
}

class TitleForm extends Component<IProps, IState> {
    state = {
        currentAuthor: {
            data: null,
            isNew: false
        },
        isReady: false,
        showCheckerDialog: false,
        fieldsCheckerList: [],
        hideToast: false,
        newTitle: false,
        data: {
            id: -1,
            userId: this.props.currentUser.id,
            title: '',
            logline: '',
            synopsis: '',
            primaryGenre: null,
            secondaryGenre: null,
            features: [],
            tones: [],
            ambientWhereType: '',
            ambientWhere: [],
            ambientWhenType: '',
            ambientWhenStartAt: '',
            ambientWhenEndAt: '',
            format: null,
            volumes: 1,
            alreadyPublished: true,
            publishedAt: null,
            publicTargets: [],
            reprints: 0,
            awards: [],
            reviews: [],
            translations: [],
            commercialNews: '',
            fileDocuments: [],
            fileCover: null,
            authors: [],
            personages: [],
            lastStep: 1,
            copyrightType: null,
            copyrightText: '',
            isDraft: true
        },
        error: '',
        step: 1,
        titleId: 0,
        features: [],
        tones: [],
        redirectUrl: null,
        checkStep: false,
        fromPreview: false,
        fileDocuments: {
            files: [],
            codes: []
        },
        showConfirmPersonages: false,
        personagesStatus: {
            counter: 0,
            formOpened: false
        }
    };

    componentDidMount(): void {
        window.scrollTo(0, 0);
        const {
            match: {
                params: { id, step }
            },
            currentUser,
            genres,
            features,
            tones,
            publicTargets,
            awards,
            translations,
            formats
        } = this.props;

        if (features && features.length) {
            this.setFeatures(features);
        } else {
            this.props.onGetFeatures();
        }

        if (tones && tones.length) {
            this.setTones(tones);
        } else {
            this.props.onGetTones();
        }

        if (!genres || !genres.length) this.props.onGetGenres();
        if (!publicTargets || !publicTargets.length) this.props.onGetPublicTargets();
        if (!awards || !awards.length) this.props.onGetAwards();
        if (!translations || !translations.length) this.props.onGetTranslations();
        if (!formats || !formats.length) this.props.onGetFormats();

        if (currentUser) {
            this.props.onGetAuthors(currentUser.id);
        }

        //console.log('titleId', id, 'step', step);

        this.setState(
            {
                isReady: +id === 0,
                step: +step || 1,
                titleId: id || 0,
                checkStep: id && +id > 0,
                fromPreview: step > 0 && +id > 0
            },
            () => {
                if (this.state.titleId > 0) this.props.onGetTitle(id);
            }
        );
    }

    setAuthor = (data: IAuthor | null, isNew: boolean) => {
        const currentAuthor = {
            data,
            isNew
        };

        this.setState(
            {
                currentAuthor
            },
            () => {
                console.log('Current author', this.state.currentAuthor);
            }
        );
    };

    setDocuments = (documents: Array<IFile>) => {
        const fileDocuments = { ...this.state.fileDocuments };

        fileDocuments.files = documents;
        fileDocuments.codes = documents.map((item: IFile) => item.code);

        this.setState({
            fileDocuments
        });
    };

    setData = (name: string, value: any) => {
        const data: any = { ...this.state.data };

        data[name] =
            value && Array.isArray(value) ? value.filter((item: any) => item !== null) : value;

        this.setState({
            data
        });
    };

    handleSubmitClicked = (draft: boolean = true) => {
        let data = {
            ...this.state.data,
            isDraft: draft
        };

        this.setState(
            {
                data
            },
            () => {
                if (!draft) {
                    let fieldsToCheck = checkTitleFields(
                        this.state.data,
                        [this.state.currentAuthor.data],
                        this.state.personagesStatus.counter
                    );

                    if (!fieldsToCheck.length) {
                        this.updateTitle(draft);
                    } else {
                        this.setState({
                            fieldsCheckerList: fieldsToCheck,
                            showCheckerDialog: fieldsToCheck.length > 0
                        });
                    }
                } else {
                    this.updateTitle(draft);
                }
            }
        );
    };

    updateTitle = (draft: boolean) => {
        this.setState(
            {
                redirectUrl: draft ? null : routes.PREVIEW
            },
            () => {
                //console.log(this.state.redirectUrl);

                const { titleId, currentAuthor } = this.state;

                const authorData = currentAuthor.data;

                let authors: Array<number> = [];

                if (authorData && titleId) {
                    if (currentAuthor.isNew) {
                        this.props.onCreateAuthor(titleId, authorData);
                    } else {
                        this.props.onUpdateAuthor(authorData.id, authorData);
                        authors.push(authorData.id);

                        this.saveTitle(authors, draft);
                    }
                } else {
                    this.saveTitle(authors, draft);
                }
            }
        );
    };

    saveTitle = (authors: Array<number>, isDraft: boolean = true) => {
        const {
            data,
            data: {
                ambientWhere,
                primaryGenre,
                secondaryGenre,
                publicTargets,
                features,
                tones,
                fileCover,
                ambientWhenType,
                ambientWhenEndAt,
                ambientWhenStartAt,
                reviews,
                translations,
                awards,
                copyrightType,
                copyrightText,
                format,
                alreadyPublished,
                publishedAt,
                ambientWhereType,
                volumes
            },
            fileDocuments
        } = this.state;

        if (data.title && data.title.trim().length > 0) {
            let cover = fileCover ? fileCover.code : null;

            if (!cover) {
                cover = fileCover;
            }

            const featuresList = features.map((item: any) => item.value || item.id);
            const tonesList = tones.map((item: any) => item.value || item.id);
            const awardsList = awards.map((item: any) => item.value || item.id);
            const translationsList = translations.map((item: any) => item.value || item.id);

            let model = {
                ...data,
                primaryGenre: primaryGenre ? primaryGenre.id : null,
                secondaryGenre: secondaryGenre ? secondaryGenre.id : null,
                features: featuresList,
                tones: tonesList,
                translations: translationsList,
                awards: awardsList,
                publicTargets: publicTargets ? publicTargets.map((item: any) => item.id) : [],
                format: format ? format.id : null,
                volumes: format && isSaga(format.id) ? volumes : null,
                fileCover: cover,
                lastStep: this.state.step >= 2 ? this.state.step : 2,
                ambientWhere:
                    ambientWhereType === 'specifica luogo'
                        ? ambientWhere.filter((item: string) => item.trim() !== '')
                        : [],
                ambientWhenStartAt:
                    ambientWhenStartAt &&
                    (ambientWhenType === 'range di mesi-anni' ||
                        ambientWhenType === 'data specifica')
                        ? ambientWhenStartAt
                        : null,
                ambientWhenEndAt:
                    ambientWhenEndAt && ambientWhenType === 'range di mesi-anni'
                        ? ambientWhenEndAt
                        : null,
                reviews: reviews.filter((item: IReview) => item.source.trim().length > 3),
                copyrightText: copyrightType === 'partial' ? copyrightText : '',
                fileDocuments: fileDocuments.codes,
                publishedAt: alreadyPublished ? publishedAt : null,
                authors,
                isDraft
            };

            // Atomics calls
            delete model.personages;

            if (data.id > 0) {
                this.props.onUpdateTitle(data.id, model);
            } else {
                this.props.onCreateTitle(model);
            }
        } else {
            if (!data.title)
                Toast({ message: 'Controlla di aver inserito il titolo!', type: 'error' });
        }
    };

    onPrevClicked = () => {
        if (this.state.step === 2 && this.checkIfPersonageFormIsOpen()) {
            this.setState({
                showConfirmPersonages: true
            });
        } else {
            const step = this.state.step > 1 ? this.state.step - 1 : this.state.step;

            this.setState(
                {
                    step
                },
                () => {
                    window.scrollTo(0, 0);
                }
            );
        }
    };

    onNextClicked = () => {
        if (this.state.step === 2 && this.checkIfPersonageFormIsOpen()) {
            this.setState({
                showConfirmPersonages: true
            });
        } else {
            if (!this.props.title) {
                this.setState(
                    {
                        checkStep: true
                    },
                    () => {
                        this.updateTitle(true);
                    }
                );
            } else {
                const step = this.state.step + 1;

                this.setState(
                    {
                        step
                    },
                    () => {
                        window.scrollTo(0, 0);
                    }
                );
            }
        }
    };

    checkIfPersonageFormIsOpen = (): boolean => {
        const { personagesStatus } = this.state;

        this.setState({
            showConfirmPersonages: personagesStatus.formOpened
        });

        return personagesStatus.formOpened;
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({
                error: this.props.error,
                isReady: true
            });
        }

        if (this.props.lastCreatedId !== prevProps.lastCreatedId && this.props.lastCreatedId) {
            const titleId = this.props.lastCreatedId;

            this.setState(
                {
                    titleId: titleId
                },
                () => {
                    this.props.history.push(
                        routes.TITLE.replace(':id', titleId.toString()).replace(':step', '1')
                    );
                }
            );
        }

        if (this.props.created !== prevProps.created && this.props.created) {
            window.scrollTo(0, 0);
            Toast({ message: 'Titolo creato!' });
        }

        if (
            this.props.updated !== prevProps.updated &&
            this.props.updated &&
            !this.state.hideToast
        ) {
            Toast({ message: 'Titolo salvato!' });
        }

        if (this.props.title !== prevProps.title && this.props.title) {
            const title: ITitle = this.props.title;

            //const isNew = this.state.data.id < 0;

            if (title) {
                const fileDocuments = {
                    files: title.fileDocuments || [],
                    codes:
                        title.fileDocuments && title.fileDocuments.length
                            ? title.fileDocuments.map((item: IFile) => item.code)
                            : []
                };

                const personagesStatus = {
                    counter: title.personages ? title.personages.length : 0,
                    formOpened: false
                };

                const currentAuthor = {
                    data: title.authors && title.authors.length ? title.authors[0] : null,
                    isNew: false
                };

                this.setState(
                    {
                        personagesStatus,
                        currentAuthor,
                        fileDocuments,
                        data: {
                            ...title
                        },
                        step:
                            this.state.checkStep && !this.state.fromPreview
                                ? title.lastStep
                                : this.state.step
                    },
                    () => {
                        window.scrollTo(0, 0);

                        this.setState({
                            checkStep: false,
                            fromPreview: false
                        });

                        if (this.state.redirectUrl) {
                            if (this.state.redirectUrl === routes.PREVIEW) {
                                this.props.history.push(
                                    this.state.redirectUrl.replace(':id', title.id.toString())
                                );
                            }
                        }
                    }
                );
            }

            if (!this.state.isReady) {
                setTimeout(() => {
                    this.setState({
                        isReady: true
                    });
                }, 1000);
            }
        }

        // Check data
        if (this.state.data !== prevState.data) {
            if (DEBUG_TITLE) console.log('Current data', this.state.data);
        }

        if (this.state.step !== prevState.step) {
            window.scrollTo(0, 0);
        }

        if (
            this.props.lastAuthorCreatedForThisTitle !== prevProps.lastAuthorCreatedForThisTitle &&
            this.props.lastAuthorCreatedForThisTitle
        ) {
            this.saveTitle([this.props.lastAuthorCreatedForThisTitle.id], this.state.data.isDraft);
        }

        // Lists
        if (this.props.features !== prevProps.features && this.props.features.length) {
            this.setFeatures(this.props.features);
        }

        if (this.props.tones !== prevProps.tones && this.props.tones.length) {
            this.setTones(this.props.tones);
        }

        if (this.props.match !== prevProps.match && this.props.match) {
            if (this.props.match.params.id.toString() === '0') {
                window.location.reload();
            }
        }
    }

    setTones = (tones: Array<IServerOption>) => {
        const tonesList = tones.map((item: IServerOption) => {
            return {
                value: item.id,
                label: item.label
            };
        });

        this.setState({
            tones: tonesList
        });
    };

    setFeatures = (features: Array<IServerOption>) => {
        const featuresList = features.map((item: IServerOption) => {
            return {
                value: item.id,
                label: item.label
            };
        });

        this.setState({
            features: featuresList
        });
    };

    setPersonageChecker = (counter: number, formOpened: boolean) => {
        const personagesStatus = {
            counter: counter || 0,
            formOpened: formOpened || false
        };
        this.setState({
            personagesStatus
        });
    };

    handleConfirmPersonage = () => {
        this.setState({
            showConfirmPersonages: false
        });
    };

    componentWillUnmount(): void {
        this.props.onResetFile();
        this.props.onResetMyLastTitle();
    }

    render() {
        const {
            error,
            data,
            step,
            features,
            tones,
            showCheckerDialog,
            fieldsCheckerList,
            fileDocuments,
            showConfirmPersonages,
            isReady
        } = this.state;

        const {
            waitingForCreation,
            waitingForGenres,
            waitingForFeatures,
            waitingForTones,
            waitingForFetching,
            waitingForUpdating,
            waitingForAuthors,
            waitingForPublicTargets,
            waitingForAwards,
            waitingForTranslations,
            waitingForFormats
        } = this.props;

        if (
            !isReady ||
            waitingForFormats ||
            waitingForAwards ||
            waitingForTranslations ||
            waitingForFetching ||
            waitingForGenres ||
            waitingForTones ||
            waitingForFeatures ||
            waitingForAuthors ||
            waitingForPublicTargets
        ) {
            return <Loader fullPage size={'sm'} theme={'light'} />;
        }

        // steps title
        let stepTitle = null;
        if (step === 1) {
            stepTitle = 'title.step1.title';
        } else if (step === 2) {
            stepTitle = 'title.step2.title';
        } else if (step === 3) {
            stepTitle = 'title.step3.title';
        } else if (step === 4) {
            stepTitle = 'title.step4.title';
        }

        const previewRoute =
            data.id !== -1 ? routes.PREVIEW.replace(':id', data.id.toString()) : '';

        return (
            <>
                <SavingBox
                    title={stepTitle}
                    onDraftClicked={() => this.handleSubmitClicked(true)}
                    onPublishClicked={
                        step >= 4 || !data.isDraft
                            ? () => this.handleSubmitClicked(false)
                            : undefined
                    }
                    onPrevClicked={step > 1 ? this.onPrevClicked : undefined}
                    onNextClicked={step >= 4 ? undefined : this.onNextClicked}
                    previewRoute={previewRoute}
                />
                <div className={classes.TitleFormContainer}>
                    {step === 1 && (
                        <>
                            <TitleAndStory data={data} setData={this.setData} />
                            {!waitingForGenres &&
                                !waitingForFeatures &&
                                !waitingForTones &&
                                features.length &&
                                tones.length && (
                                    <GenreAndFeatures
                                        features={features}
                                        tones={tones}
                                        data={data}
                                        setData={this.setData}
                                    />
                                )}
                            <Location setData={this.setData} data={data} />
                        </>
                    )}
                    {step === 2 && data.id && (
                        <Personages
                            setPersonagesChecker={this.setPersonageChecker}
                            titleId={data.id}
                        />
                    )}
                    {step === 3 && (
                        <>
                            {!waitingForAuthors &&
                                !waitingForPublicTargets &&
                                !waitingForFormats && (
                                    <AuthorAndFormat
                                        authorChanged={this.setAuthor}
                                        lastAuthorData={this.state.currentAuthor}
                                        data={data}
                                        setData={this.setData}
                                    />
                                )}
                            {!waitingForAwards && !waitingForTranslations && (
                                <SalesData data={data} setData={this.setData} />
                            )}
                            <Documents
                                fileDocuments={fileDocuments.files}
                                // instructionIds={['instruction.documents']}
                                data={data}
                                setDocuments={this.setDocuments}
                            />
                        </>
                    )}
                    {step === 4 && (
                        <>
                            <CoverAndPreview data={data} setData={this.setData} />
                            <IPDetails data={data} setData={this.setData} />
                        </>
                    )}
                    <FeedbackMessage
                        size={'md'}
                        marginTop={20}
                        marginBottom={10}
                        visible={error !== null && error !== ''}
                        type={'error'}
                    >
                        {`${error}`}
                    </FeedbackMessage>
                    <div className={classes['TitleForm-footer']}>
                        <div className={classes['TitleForm-loader']}>
                            {(waitingForCreation || waitingForUpdating) && (
                                <Loader fullPage size={'sm'} theme={'light'} />
                            )}
                        </div>
                    </div>
                </div>
                <SavingBox
                    size={'small'}
                    onDraftClicked={() => this.handleSubmitClicked(true)}
                    onPublishClicked={
                        step >= 4 || !data.isDraft
                            ? () => this.handleSubmitClicked(false)
                            : undefined
                    }
                    onPrevClicked={step > 1 ? this.onPrevClicked : undefined}
                    onNextClicked={step >= 4 ? undefined : this.onNextClicked}
                    previewRoute={previewRoute}
                />
                <Dialog
                    showClose={true}
                    visible={showCheckerDialog}
                    onDialogCloseClicked={() => this.setState({ showCheckerDialog: false })}
                >
                    <div className={classes.CheckerFields}>
                        <h3>
                            <FormattedMessage id={'title.checker'} />
                        </h3>
                        <ul>
                            {fieldsCheckerList.map((item: IChecker, index: number) => (
                                <li key={index}>
                                    <FormattedMessage id={item.field} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </Dialog>
                <Dialog
                    visible={showConfirmPersonages}
                    onDialogCloseClicked={() =>
                        this.setState({
                            showConfirmPersonages: false
                        })
                    }
                >
                    <Text marginBottom={40}>
                        <FormattedMessage id={'title.confirm.personages'} />
                    </Text>
                    <div className={classes['TitlesList-dialogButtons']}>
                        <Button pullRight={true} clicked={this.handleConfirmPersonage}>
                            <FormattedMessage id={'general.close'} />
                        </Button>
                    </div>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.userState.user,
        title: state.titleState.title,
        waitingForCreation: state.titleState.isCreating,
        waitingForFetching: state.titleState.isFetching,
        waitingForUpdating: state.titleState.isUpdating,
        updated: state.titleState.updated,
        created: state.titleState.created,
        lastCreatedId: state.titleState.lastCreatedId,
        error: state.titleState.error,
        genres: state.genreState.items,
        waitingForGenres: state.genreState.isFetching,
        features: state.featureState.items,
        waitingForFeatures: state.featureState.isFetching,
        tones: state.toneState.items,
        waitingForTones: state.toneState.isFetching,
        formats: state.formatState.items,
        waitingForFormats: state.formatState.isFetching,
        publicTargets: state.targetState.items,
        waitingForPublicTargets: state.targetState.isFetching,
        translations: state.translationState.items,
        waitingForTranslations: state.translationState.isFetching,
        awards: state.awardState.items,
        waitingForAwards: state.awardState.isFetching,
        authors: state.userState.authors,
        waitingForAuthors: state.userState.isFetching,
        lastAuthorCreatedForThisTitle: state.titleState.lastAuthorCreated,
        personagesList: state.titleState.personagesList
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetTitle: (id: number) => dispatch(actions.fetchTitle(id)),
        onCreateTitle: (data: ITitle) => dispatch(actions.createTitle(data)),
        onUpdateTitle: (id: number, data: ITitle) => dispatch(actions.updateTitle(id, data)),
        onGetGenres: () => dispatch(actions.fetchGenres()),
        onGetFeatures: () => dispatch(actions.fetchFeatures()),
        onGetTones: () => dispatch(actions.fetchTones()),
        onGetTranslations: () => dispatch(actions.fetchTranslations()),
        onGetAwards: () => dispatch(actions.fetchAwards()),
        onGetPublicTargets: () => dispatch(actions.fetchTargets()),
        onGetFormats: () => dispatch(actions.fetchFormats()),
        onResetFile: () => dispatch(actions.resetFile()),
        onResetMyLastTitle: () => dispatch(actions.resetMyLastTitle()),
        onGetAuthors: (userId: number) => dispatch(actions.fetchMyAuthors(userId)),
        onCreateAuthor: (titleId: number, data: IAuthor) =>
            dispatch(actions.createAuthorForTitle(titleId, data)),
        onUpdateAuthor: (authorId: number, data: IAuthor) =>
            dispatch(actions.updateAuthor(authorId, data))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TitleForm));
