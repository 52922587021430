import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { IFile, ITitle, IUploader } from '../../../shared/interfaces';
import mainClasses from '../styles.module.scss';
import stepClasses from './styles.module.scss';
import Text from '../../UI/Text';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import Uploader from '../../Field/Uploader';
import Loader from '../../UI/Loader';
import constants from '../../../shared/constants';
import Instruction from '../../UI/Instruction';

interface IProps {
    data: ITitle;
    setDocuments(docs: Array<IFile>): void;
    file: Array<IFile>;
    onResetFile(): void;
    onCreateFile(data: object): IFile;
    waitingForSavingFile: boolean;
    instructionIds?: Array<string>;
    fileDocuments?: Array<IFile>;
}

interface IUploaderControls {
    document: IUploader;
}

interface IState {
    uploader: IUploaderControls;
    fileDocuments: Array<IFile>;
    initialized: boolean;
}

class Documents extends Component<IProps, IState> {
    state = {
        initialized: false,
        fileDocuments: [],
        uploader: {
            document: {
                value: null,
                accept: constants.TEXT_MIME_TYPES,
                titleId: 'upload.document.title',
                maxSize: 24,
                multiple: false
            }
        }
    };

    componentDidMount(): void {
        this.setState(
            {
                initialized: true
            },
            () => {
                this.initializeData();
            }
        );
    }

    handleUploadImage = (uploaded: Array<File>) => {
        const data = new FormData();

        data.append('file', uploaded[0]);

        this.props.onCreateFile(data);
    };

    initializeData = () => {
        const { fileDocuments } = this.props;

        this.setState({
            fileDocuments: fileDocuments || []
        });
    };

    removeFileFromList = (index: number) => {
        let fileDocuments = [...this.state.fileDocuments];

        fileDocuments.splice(index, 1);

        this.setState({
            fileDocuments
        });
    };

    componentDidUpdate(
        prevProps: Readonly<IProps>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (this.props.file !== prevProps.file && this.props.file) {
            let fileDocuments = [...this.state.fileDocuments];

            fileDocuments.push(this.props.file[0]);

            this.setState({
                fileDocuments
            });
        }

        if (this.state.fileDocuments !== prevState.fileDocuments && this.state.fileDocuments) {
            this.props.setDocuments(this.state.fileDocuments);
        }

        if (this.props.data !== prevProps.data && this.props.data && !this.state.initialized) {
            this.setState(
                {
                    initialized: true
                },
                () => {
                    //this.initializeData();
                }
            );
        }
    }

    render() {
        const {
            uploader: { document },
            fileDocuments
        } = this.state;
        const { waitingForSavingFile, instructionIds } = this.props;

        return (
            <div className={[mainClasses.TitleForm, stepClasses.Documents].join(' ')}>
                <div className={mainClasses['TitleForm-header']}>
                    <Text tag={'h2'} transform={'uppercase'} weight={600} spacing={true}>
                        <FormattedMessage id={'title.section.documents'} />
                    </Text>
                </div>
                <div className={mainClasses['TitleForm-content']}>
                    <div className={[mainClasses['TitleForm-flex'], mainClasses['TitleForm-flex--withinstructions']].join(' ')}>
                        <div>
                            {waitingForSavingFile && <Loader size={'sm'} fullContent theme={'light'} />}
                            <div className={stepClasses['Documents-field']}>
                                <Uploader
                                    squared
                                    large
                                    fullWidth
                                    disabled={waitingForSavingFile}
                                    titleId={document.titleId}
                                    accept={document.accept}
                                    maxSize={document.maxSize}
                                    onSelectedFiles={this.handleUploadImage}
                                />
                            </div>
                            { fileDocuments.length > 0 &&
                                <div className={stepClasses['Documents-list']}>
                                    {fileDocuments.map((file: IFile, index: number) => {
                                        return (
                                            <div className={stepClasses['Documents-item']} key={index}>
                                                <a
                                                    rel="noopener noreferrer"
                                                    href={file.url}
                                                    target={'_blank'}
                                                >
                                                    {file.name}
                                                </a>
                                                <button
                                                    className={stepClasses['Documents-item-btn--remove']}
                                                    onClick={() => this.removeFileFromList(index)}>
                                                    <span>
                                                        <FormattedMessage id={'general.remove'} />
                                                    </span>
                                                </button>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                        {instructionIds && <Instruction textIds={instructionIds} />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        file: state.fileState.file,
        waitingForSavingFile: state.fileState.isStoring
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCreateFile: (data: object) => dispatch(actions.createFile(data)),
        onResetFile: () => dispatch(actions.resetFile())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
