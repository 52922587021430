import React, { Component } from 'react';
import classes from './styles.module.scss';
import Scroll from 'react-scroll';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const scroll = Scroll.animateScroll;

interface IProps {
    children: any;
    onOffCanvasCloseClicked: () => void;
    visible: boolean;
    offCanvasChanged?: boolean;
    position: 'right' | 'left' | 'top' | 'bottom';
}

class OffCanvas extends Component<IProps> {
    offCanvas = React.createRef<HTMLDivElement>();
    targetElement: any;

    componentDidMount(): void {
        if (this.offCanvas && this.props.visible) disableBodyScroll(this.targetElement);
    }

    handleClickedOutsideMenu = (e: any) => {
        if (
            this.offCanvas.current &&
            !this.offCanvas.current.contains(e.target) &&
            this.props.visible
        ) {
            this.props.onOffCanvasCloseClicked();
        }
    };

    componentWillUnmount() {
        clearAllBodyScrollLocks();
        document.removeEventListener('click', this.handleClickedOutsideMenu, false);
    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (
            this.props.offCanvasChanged !== prevProps.offCanvasChanged &&
            this.props.offCanvasChanged
        ) {
            scroll.scrollToTop({
                containerId: 'Container',
                duration: 0
            });
        }

        if (this.props.visible !== prevProps.visible) {
            if (this.props.visible) {
                scroll.scrollToTop({
                    containerId: 'Container',
                    duration: 0
                });
                disableBodyScroll(this.targetElement);
                document.addEventListener('click', this.handleClickedOutsideMenu, false);
            } else {
                clearAllBodyScrollLocks();
                document.removeEventListener('click', this.handleClickedOutsideMenu, false);
            }
        }
    }

    render() {
        const { children, visible, position } = this.props;

        return (
            <>
                <div
                    className={[
                        classes['OffCanvas'],
                        visible ? classes['is-visible'] : '',
                        classes[`OffCanvas--${position}`]
                    ].join(' ')}
                    id={'Container'}
                    ref={this.offCanvas}
                >
                    <div className={classes['OffCanvas-content']}>{children}</div>
                </div>
                <div
                    className={[
                        classes['OffCanvas-overlay'],
                        visible ? classes['OffCanvas-overlay--active'] : ''
                    ].join(' ')}
                />
            </>
        );
    }
}

export default OffCanvas;
