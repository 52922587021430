import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { IAuthor } from '../../shared/interfaces';
import Text from '../UI/Text';
import classes from './styles.module.scss';
import * as routes from '../../shared/routes';
import { FormattedMessage } from 'react-intl';

interface IProps {
    author: IAuthor;
    titleId?: number;
    label?: boolean;
}

class AuthorCard extends Component<IProps, {}> {
    render() {
        const { author, titleId, label } = this.props;

        if (!author) return null;

        return (
            <div className={classes.AuthorCard}>
                {label &&
                    <div className={classes['AuthorCard-header']}>
                        <FormattedMessage id={'label.author'} />
                    </div>
                }
                {titleId && titleId > 0 && (
                    <NavLink
                        className={classes['AuthorCard-edit-btn']}
                        to={routes.TITLE.replace(':id', titleId.toString()).replace(':step', '3')}>
                        <FormattedMessage id={'general.edit'} />
                    </NavLink>
                )}
                <div className={classes['AuthorCard-content']}>
                    <Text tag={'h3'} size={'sm'} weight={600} marginBottom={20}>
                        {author.fullName}
                    </Text>
                    <Text tag={'p'}>
                        {author.biography}
                    </Text>
                </div>
            </div>
        );
    }
}

export default AuthorCard;
