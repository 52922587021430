import React, { Component } from 'react';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import Instruction from '../../UI/Instruction';

interface IProps {
    label?: string | object;
    required?: boolean;
    type: string;
    name: string;
    placeholderId?: string;
    readOnly?: boolean;
    value?: string;
    disabled?: boolean;
    clicked?: () => void;
    changed: (name: string, value: string) => void;
    submitted?: () => void;
    styles?: object;
    capitalize?: boolean;
    error?: boolean;
    errorMessage?: any;
    tabIndex?: number;
    valid?: boolean;
    maxLength?: number;
    instructionIds?: Array<string>;
    alert?: boolean;
    alertMessage?: any;
}

class Input extends Component<IProps> {
    handleInputKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13 && this.props.submitted) {
            this.props.submitted();
        }
    };

    render() {
        const {
            type,
            name,
            placeholderId,
            value,
            disabled,
            clicked,
            styles,
            capitalize,
            error,
            valid,
            readOnly,
            changed,
            label,
            required,
            maxLength,
            instructionIds,
            errorMessage,
            alert,
            alertMessage
        } = this.props;

        const inputFieldClasses = [
            classes['Input-field'],
            capitalize ? classes['Input-field--capitalize'] : '',
            disabled ? classes['is-disabled'] : '',
            error ? classes['is-invalid'] : '',
            valid ? classes['is-valid'] : '',
            alert ? classes['is-warning'] : ''
        ].join(' ');

        const inputTpl = (
            <FormattedMessage id={placeholderId || 'general.emptyMessage'} defaultMessage={''}>
                {(placeholder: any) => (
                    <input
                        placeholder={placeholder}
                        style={styles}
                        name={name}
                        className={inputFieldClasses}
                        disabled={disabled}
                        value={value}
                        type={type}
                        maxLength={maxLength || 1000000}
                        onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
                            changed(name, e.currentTarget.value)
                        }
                        readOnly={readOnly}
                        autoComplete="new-value"
                        onKeyDown={this.handleInputKeyDown}
                        onClick={clicked}
                    />
                )}
            </FormattedMessage>
        );

        return (
            <div className={classes.Input}>
                <div className={classes['Input-content']}>
                    <div className={classes['Input-wrapper']}>
                        {label && (
                            <div className={classes['Input-header']}>
                                <label className={classes['Input-label']}>{label}</label>
                                {required && (
                                    <span className={classes['Input-required']}>
                                        <FormattedMessage id={'general.required'} />
                                    </span>
                                )}
                            </div>
                        )}
                        {inputTpl}
                        {errorMessage && error && (
                            <span className={classes['Input-errorMessage']}>{errorMessage}</span>
                        )}
                        {alertMessage && alert && (
                            <span className={classes['Input-alertMessage']}>{alertMessage}</span>
                        )}
                    </div>
                    {instructionIds && <Instruction textIds={instructionIds} />}
                </div>
            </div>
        );
    }
}

export default Input;
