import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    label?: any;
    description?: any;
    name: string;
    checked?: boolean;
    disabled?: boolean;
    changed: (name: string, value: boolean) => void;
    styles?: object;
    autoSize?: boolean;
}

const Switch = ({
    label,
    checked,
    disabled,
    name,
    styles,
    autoSize,
    changed,
    description
}: IProps) => {
    return (
        <div
            className={[
                classes.Switch,
                checked ? classes['is-active'] : '',
                autoSize ? classes['Switch--autosize'] : ''
            ].join(' ')}
        >
            {label && (
                <div className={classes['Switch-text']}>
                    <div className={classes['Switch-label']}>
                        <span>{label}</span>
                    </div>
                    <div className={classes['Switch-description']}>
                        {description && <span>{description}</span>}
                    </div>
                </div>
            )}
            <div className={classes['Switch-wrapper']}>
                <button
                    disabled={disabled || false}
                    type={'button'}
                    id={name}
                    data-name={name}
                    style={styles}
                    onClick={() => changed(name, !checked)}
                    className={classes['Switch-button']}
                >
                    <div className={classes['Switch-content']}>
                        <div className={classes['Switch-round']} />
                    </div>
                </button>
            </div>
        </div>
    );
};

export default Switch;
