import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { History } from 'history';
import classes from './styles.module.scss';
import Text from '../../components/UI/Text';
import PublisherRegistrationForm from '../../components/PublisherRegistrationForm';
import ProducerRegistrationForm from '../../components/ProducerRegistrationForm';
import * as routes from '../../shared/routes';
import FeedbackMessage from '../../components/UI/FeedbackMessage';

interface IProps {
    history: History;
    match: {
        params: { type: string };
    };
}

interface IState {
    checkboxes: any;
    type: string;
    successfullyRegistration: boolean;
}

class Registration extends Component<IProps, IState> {
    state = {
        successfullyRegistration: false,
        type: '',
        checkboxes: {
            terms: {
                name: 'terms',
                labelId: 'registration.terms',
                checked: false,
                type: 'toggle'
            }
        }
    };

    componentDidMount() {
        const {
            match: {
                params: { type }
            }
        } = this.props;

        if (type && (type === 'publisher' || type === 'producer')) {
            this.setState({
                type: type.toUpperCase()
            });
        } else {
            this.props.history.push(routes.HOMEPAGE);
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.match.params.type !== prevProps.match.params.type) {
            if (this.props.match && this.props.match.params && this.props.match.params.type) {
                this.setState({
                    type: this.props.match.params.type.toUpperCase()
                });
            }
        }

        if (this.state.type !== prevState.type) {
            console.log('type', this.state.type);
        }
    }

    render() {
        const { type, successfullyRegistration } = this.state;

        return (
            <main className={classes.Registration}>
                {successfullyRegistration ? (
                    <div className={classes['Registration-success']}>
                        <Text marginBottom={30}>
                            <FormattedMessage id="general.registration.thanks" />
                        </Text>
                        <FeedbackMessage size={'lg'} type={'success'} visible={true}>
                            <FormattedMessage id="general.registration.completed" />
                        </FeedbackMessage>
                    </div>
                ) : type === 'PRODUCER' ? (
                    <>
                        <div className={classes['Registration-header']}>
                            <Text
                                tag={'h2'}
                                marginBottom={30}
                                weight={400}
                                transform={'uppercase'}
                                spacing={true}
                            >
                                <span>
                                    <FormattedMessage id={'registrationPage.title'} />
                                </span>
                                {
                                    // Print type registration
                                    //<span>{type}</span>
                                }
                            </Text>
                        </div>
                        <ProducerRegistrationForm />
                    </>
                ) : (
                    <>
                        <div className={classes['Registration-header']}>
                            <Text
                                tag={'h2'}
                                marginBottom={30}
                                weight={400}
                                transform={'uppercase'}
                                spacing={true}
                            >
                                <span>
                                    <FormattedMessage id={'registrationPage.title'} />
                                </span>
                                {
                                    // Print type registration
                                    //<span>{type}</span>
                                }
                            </Text>
                        </div>
                        <PublisherRegistrationForm
                            onSuccessfullyRegistration={() =>
                                this.setState({ successfullyRegistration: true })
                            }
                        />
                    </>
                )}
            </main>
        );
    }
}

export default Registration;
