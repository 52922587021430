import React, { Component } from 'react';
import classes from './styles.module.scss';
import Rodal from 'rodal';
import CSS from 'csstype';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// include styles
// https://github.com/chenjiahan/rodal
import 'rodal/lib/rodal.css';
import './styles.scss';

interface IProps {
    title?: object | string;
    children: any;
    onDialogCloseClicked: () => void;
    showClose?: boolean;
    visible: boolean;
    size?: 'small' | 'large';
    animation?:
        | 'zoom'
        | 'fade'
        | 'flip'
        | 'door'
        | 'rotate'
        | 'slideUp'
        | 'slideDown'
        | 'slideLeft'
        | 'slideRight';
    styles?: CSS.Properties;
}

class Dialog extends Component<IProps> {
    targetRef = React.createRef<HTMLDivElement>();
    targetElement: any;

    componentDidMount(): void {
        if (this.targetRef && this.props.visible) disableBodyScroll(this.targetElement);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.visible !== prevProps.visible && this.targetRef) {
            if (this.props.visible) {
                disableBodyScroll(this.targetElement);
            } else {
                clearAllBodyScrollLocks();
            }
        }
    }

    componentWillUnmount(): void {
        clearAllBodyScrollLocks();
    }

    render() {
        const {
            children,
            onDialogCloseClicked,
            showClose,
            visible,
            animation,
            size,
            styles
        } = this.props;

        return (
            <Rodal
                animation={animation || 'fade'}
                width={100}
                height={100}
                customStyles={styles}
                measure={'%'}
                className={[classes.Dialog, size ? classes[`Dialog--${size}`] : ''].join(' ')}
                showCloseButton={showClose || false}
                visible={visible}
                onClose={onDialogCloseClicked}
            >
                <div ref={this.targetRef} className={classes['Dialog-content']}>
                    {children}
                </div>
            </Rodal>
        );
    }
}

export default Dialog;
