import { IAuthor, IChecker, IPersonage, IRegistration, IReview, ITitle } from '../interfaces';

export const isValidEmail = (email: string): boolean => {
    if (!email || email.trim() === '') {
        return false;
    }

    const regExp =
        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

    const matcher = new RegExp(regExp);
    const keywordTest = email.trim();

    return matcher.test(keywordTest);
};

export const isValidSecureURL = (str: string) => {
    if (!str.trim().length) return false;

    const matcher = new RegExp(
        '^(https:\\/\\/www\\.|https:\\/\\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$'
    );

    return matcher.test(str);
};

export const isValidURL = (str: string) => {
    const matcher = new RegExp(
        '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$'
    );

    return !matcher.test(str);
};

export const isValidPhone = (prefix: string, phone: string): boolean => {
    if ((!prefix || !phone) && (prefix.trim() === '' || phone.trim() === '')) {
        return false;
    }

    let valid = false;

    const fullPhone = prefix.trim() + phone.trim();

    if (fullPhone.length > 15) {
        valid = false;
    } else {
        if (phone.trim() !== '') {
            const regExp = '^[0-9]*$';
            const matcher = new RegExp(regExp);

            const keywordTest = phone.trim();

            valid = matcher.test(keywordTest);
        } else {
            valid = false;
        }
    }

    return valid;
};

export const isValidity = (value: string, rules: any) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
    }

    return isValid;
};

export const checkRegistrationFields = (data: IRegistration): Array<IChecker> => {
    let checkFields: Array<IChecker> = [];

    if (!data.data.publishingHouse || !data.data.publishingHouse.trim().length) {
        checkFields.push({
            field: 'label.publishingHouse',
            success: false
        });
    }

    if (!data.name || !data.name.trim().length) {
        checkFields.push({
            field: 'label.name',
            success: false
        });
    }

    if (!data.surname || !data.surname.trim().length) {
        checkFields.push({
            field: 'label.surname',
            success: false
        });
    }

    if (!data.email || !data.email.trim().length) {
        checkFields.push({
            field: 'label.email',
            success: false
        });
    } else {
        if (!isValidEmail(data.email)) {
            checkFields.push({
                field: 'error.email',
                success: false
            });
        }
    }

    if (!data.password || !data.password.trim().length) {
        checkFields.push({
            field: 'label.password',
            success: false
        });
    }

    if (!data.data.vatNumber || !data.data.vatNumber.trim().length) {
        checkFields.push({
            field: 'label.vatNumber',
            success: false
        });
    }

    if (!data.data.terms) {
        checkFields.push({
            field: 'error.terms',
            success: false
        });
    }

    if (!data.data.privacy) {
        checkFields.push({
            field: 'error.privacy',
            success: false
        });
    }

    if (data.data.website.trim().length > 0 && !isValidSecureURL(data.data.website)) {
        checkFields.push({
            field: 'error.url',
            success: false
        });
    }

    return checkFields;
};

export const checkTitleFields = (
    data: ITitle,
    authors: Array<IAuthor>,
    currentPersonagesCreated: number
): Array<IChecker> => {
    let checkFields: Array<IChecker> = [];

    if (!data.title || !data.title.trim().length) {
        checkFields.push({
            field: 'label.title',
            success: false
        });
    }

    if (!data.synopsis || !data.synopsis.trim().length) {
        checkFields.push({
            field: 'label.synopsis',
            success: false
        });
    }

    if (!data.primaryGenre) {
        checkFields.push({
            field: 'label.primaryGenre',
            success: false
        });
    }

    if (!data.features || !data.features.length) {
        checkFields.push({
            field: 'label.features',
            success: false
        });
    }

    if (!data.tones || !data.tones.length) {
        checkFields.push({
            field: 'label.tones',
            success: false
        });
    }

    if (data.alreadyPublished && !data.publishedAt) {
        checkFields.push({
            field: 'label.publishingDate',
            success: false
        });
    }

    if (!data.ambientWhereType.trim().length) {
        checkFields.push({
            field: 'label.ambientWhereType',
            success: false
        });
    } else {
        if (data.ambientWhereType === 'specifica luogo') {
            let items = data.ambientWhere.filter((item: string) => item.trim() !== '');

            if (!items.length) {
                checkFields.push({
                    field: 'label.noLocationsFound',
                    success: false
                });
            }
        }
    }

    if (!data.ambientWhenType.trim().length) {
        checkFields.push({
            field: 'label.ambientWhenType',
            success: false
        });
    } else {
        if (
            data.ambientWhenType === 'range di mesi-anni' ||
            data.ambientWhenType === 'data specifica'
        ) {
            let items =
                data.ambientWhenType === 'data specifica'
                    ? data.ambientWhenStartAt !== null
                    : data.ambientWhenStartAt !== null && data.ambientWhenEndAt !== null;

            if (!items) {
                checkFields.push({
                    field:
                        data.ambientWhenType === 'range di mesi-anni'
                            ? 'label.noPeriodFound'
                            : 'label.noDateFound',
                    success: false
                });
            }
        }
    }

    if (!currentPersonagesCreated) {
        checkFields.push({
            field: 'label.noCharactersFound',
            success: false
        });
    }

    if (!authors || !authors.length) {
        checkFields.push({
            field: 'label.noAuthorInfo',
            success: false
        });
    } else {
        const author = authors[0];

        if (!author) {
            checkFields.push({
                field: 'label.noAuthorInfo',
                success: false
            });
        } else {
            if (!author.fullName || !author.biography || !author.biography.trim().length) {
                checkFields.push({
                    field: 'label.noAuthorInfo',
                    success: false
                });
            }
        }
    }

    if (!data.format) {
        checkFields.push({
            field: 'label.format',
            success: false
        });
    }

    if (!data.copyrightType) {
        checkFields.push({
            field: 'label.noCopyright',
            success: false
        });
    }

    if (data.reviews.length) {
        const warnings: Array<IReview> = data.reviews.filter(
            (item: IReview) => !isValidSecureURL(item.link)
        );

        if (warnings.length) {
            checkFields.push({
                field: 'error.reviews',
                success: false
            });
        }
    }

    return checkFields;
};

export const checkPersonageForm = (personage: IPersonage) => {
    let checkFields = [];

    if (!personage.fullName || !personage.fullName.trim().length) {
        checkFields.push({
            field: 'placeholder.fullName',
            success: false
        });
    }

    if (!personage.genre) {
        checkFields.push({
            field: 'label.genre',
            success: false
        });
    }

    if (!personage.description || !personage.description.trim().length) {
        checkFields.push({
            field: 'placeholder.personageDescription',
            success: false
        });
    }

    if (!personage.adjectives.length) {
        checkFields.push({
            field: 'label.adjectives',
            success: false
        });
    }

    return checkFields;
};

export const isSaga = (id: number): boolean => {
    return id >= 11 && id <= 13;
};
