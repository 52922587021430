import * as actionTypes from '../actions/actionTypes';
import { IServerOption } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    tone: IServerOption;
    tones: Array<IServerOption>;
    error: string;
}

const INITIAL_STATE = {
    item: null,
    items: [],
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null
};

const fetchToneStart = (state = INITIAL_STATE) => {
    return updateObject(state, { item: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchToneSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { item: action.tone, isFetching: false });
};

const fetchToneFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchTonesStart = (state = INITIAL_STATE) => {
    return updateObject(state, { items: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchTonesSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { items: action.tones, isFetching: false });
};

const fetchTonesFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const toneReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // READ/FETCHING
        case actionTypes.FETCH_TONE_START:
            return fetchToneStart(state);
        case actionTypes.FETCH_TONE_SUCCESS:
            return fetchToneSuccess(state, action);
        case actionTypes.FETCH_TONE_FAIL:
            return fetchToneFail(state, action);
        case actionTypes.FETCH_TONES_START:
            return fetchTonesStart(state);
        case actionTypes.FETCH_TONES_SUCCESS:
            return fetchTonesSuccess(state, action);
        case actionTypes.FETCH_TONES_FAIL:
            return fetchTonesFail(state, action);

        default:
            return state;
    }
};

export default toneReducer;
