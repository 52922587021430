import React, { Component } from 'react';
import classes from './styles.module.scss';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';

interface IProps {
    children: object | string;
    clicked: () => void;
    styles?: object;
    disabled?: boolean;
    type?: string;
    tooltip?: any;
    id?: string;
    pullRight?: boolean;
    isActive?: boolean;
}

class Button extends Component<IProps, {}> {
    render() {
        const {
            children,
            clicked,
            styles,
            disabled,
            type,
            tooltip,
            id,
            pullRight,
            isActive
        } = this.props;

        const buttonClasses = [
            classes.Button,
            disabled ? classes['Button--disabled'] : '',
            type ? classes[`Button--${type}`] : '',
            pullRight ? classes[`Button--pullRight`] : '',
            isActive ? classes[`is-active`] : ''
        ].join(' ');

        return (
            <button
                disabled={disabled}
                style={styles}
                onClick={clicked}
                className={buttonClasses}
                data-tip
                data-for={id}
            >
                {children}
                {tooltip && (
                    <ReactTooltip effect="solid" id={id}>
                        <FormattedMessage id={tooltip} />
                    </ReactTooltip>
                )}
            </button>
        );
    }
}

export default Button;
