import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { History } from 'history';
import classes from './styles.module.scss';
import Text from '../../components/UI/Text';

interface IProps {
    history: History;
}

class Offers extends Component<IProps> {
    render() {
        return (
            <main className={classes.Offers}>
                <Text>
                    <FormattedMessage id={'navigation.offers'} />
                </Text>
            </main>
        );
    }
}

export default Offers;
