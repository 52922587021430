import { IInput, IOption, ISelector, ISwitch } from '../interfaces';
import { isValidEmail } from './checks';

export const resetControl = (control: IInput): IInput => {
    return {
        ...control,
        value: ''
    };
};

export const resetSwitch = (switchControl: ISwitch): ISwitch => {
    return {
        ...switchControl,
        checked: false
    };
};

export const resetSelector = (selector: ISelector): ISelector => {
    const newOptions = selector.options.map((option: IOption) => {
        return {
            ...option,
            selected: false
        };
    });

    return {
        ...selector,
        options: newOptions
    };
};

// Multiple controls Validator
export const isControlsValid = (controls: any): boolean => {
    let valid: Array<boolean> = Object.values({ ...controls }).map((item: any) => {
        if (!item.required) return true;

        // Check email types
        if (item.type === 'email' && !isValidEmail(item.value)) {
            return false;
        }

        return item.required && item.value.trim() !== '';
    });

    return valid.filter(value => !value).length === 0;
};

// Multiple checkboxes Validator
export const isCheckboxesValid = (checkboxes: any): boolean => {
    let valid: Array<boolean> = Object.values({ ...checkboxes }).map((item: any) => {
        if (!item.required) return true;

        return item.required && item.checked;
    });

    return valid.filter(value => !value).length === 0;
};

// Single selector Validator
export const isSelectorValid = (selector: ISelector): boolean => {
    if (!selector.minSelected) return true;

    const selected = selector.options.filter((item: IOption) => item.selected);

    return selector.minSelected <= selected.length;
};
