import React from 'react';
import classes from './styles.module.scss';

interface IProps {
    src?: string | null;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    borderColor?: string;
}

const RoundImage = (props: IProps) => {
    const { src, size, borderColor } = props;

    const roundImageClasses = [classes.RoundImage, size && classes[`RoundImage--${size}`]].join(
        ' '
    );

    const styles = {
        backgroundImage: src ? `url(${src})` : 'none',
        border: borderColor ? `1px solid ${borderColor}` : '0'
    };

    return <div style={styles} className={roundImageClasses} />;
};

export default RoundImage;
