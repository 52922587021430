import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/helpers/utilities';
import { IAuthor, IUser } from '../../shared/interfaces';

interface IAction {
    type: string;
    users: Array<IUser>;
    authors: Array<IAuthor>;
    selected: object;
    user: IUser;
    error: string;
    removed: boolean;
    noUsersFound: boolean;
}

const INITIAL_STATE = {
    users: [],
    authors: [],
    user: null,
    selected: null,
    isFetching: false,
    isStoring: false,
    didInvalidate: false,
    error: null,
    removed: null,
    userReset: null
};

const fetchAuthorsStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        authors: [],
        isFetching: true,
        error: null,
        didInvalidate: false
    });
};

const fetchAuthorsSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { authors: action.authors, isFetching: false });
};

const fetchAuthorsFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const createUserStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        user: null,
        isStoring: true,
        error: null,
        didInvalidate: false,
        isStored: null
    });
};

const createUserSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { user: action.user, isStoring: false });
};

const createUserFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchUsersStart = (state = INITIAL_STATE) => {
    return updateObject(state, { users: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchUsersSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { users: action.users, isFetching: false });
};

const fetchUsersFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchCurrentUserStart = (state = INITIAL_STATE) => {
    return updateObject(state, { user: null, isFetching: true, error: null, didInvalidate: false });
};

const fetchCurrentUserSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        user: action.user,
        isFetching: false
    });
};

const fetchCurrentUserFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchUserByIdStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        selected: null,
        isFetching: true,
        error: null,
        didInvalidate: false
    });
};

const fetchUserByIdSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { selected: action.selected, isFetching: false });
};

const fetchUserByIdFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const updateUserStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        isStoring: true,
        error: null,
        didInvalidate: false,
        isStored: null
    });
};

const updateUserSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { user: action.user, isStoring: false });
};

const updateUserFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const deleteUserStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        isStoring: true,
        removed: null,
        error: null,
        didInvalidate: false
    });
};

const deleteUserSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { removed: action.removed, isStoring: false });
};

const deleteUserFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        removed: false,
        didInvalidate: true,
        isStoring: false
    });
};

const recoveryPasswordUserStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        userReset: null,
        isFetching: true,
        error: null,
        didInvalidate: false,
        isStored: null
    });
};

const recoveryPasswordUserSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { userReset: action.user, isFetching: false });
};

const recoveryPasswordUserFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const updatePasswordUserStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        user: null,
        isStoring: true,
        error: null,
        didInvalidate: false,
        isStored: null
    });
};

const updatePasswordUserSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { user: action.user, isStoring: false });
};

const updatePasswordUserFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const resetUserSuccess = (state = INITIAL_STATE) => {
    return updateObject(state, { user: null });
};

const userReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // CREATE
        case actionTypes.CREATE_USER_START:
            return createUserStart(state);
        case actionTypes.CREATE_USER_SUCCESS:
            return createUserSuccess(state, action);
        case actionTypes.CREATE_USER_FAIL:
            return createUserFail(state, action);

        // READ/FETCHING
        case actionTypes.FETCH_CURRENT_USER_START:
            return fetchCurrentUserStart(state);
        case actionTypes.FETCH_CURRENT_USER_SUCCESS:
            return fetchCurrentUserSuccess(state, action);
        case actionTypes.FETCH_CURRENT_USER_FAIL:
            return fetchCurrentUserFail(state, action);

        case actionTypes.FETCH_USERS_START:
            return fetchUsersStart(state);
        case actionTypes.FETCH_USERS_SUCCESS:
            return fetchUsersSuccess(state, action);
        case actionTypes.FETCH_USERS_FAIL:
            return fetchUsersFail(state, action);

        case actionTypes.FETCH_USER_BY_ID_START:
            return fetchUserByIdStart(state);
        case actionTypes.FETCH_USER_BY_ID_SUCCESS:
            return fetchUserByIdSuccess(state, action);
        case actionTypes.FETCH_USER_BY_ID_FAIL:
            return fetchUserByIdFail(state, action);

        // AUTHORS
        case actionTypes.FETCH_AUTHORS_BY_USER_START:
            return fetchAuthorsStart(state);
        case actionTypes.FETCH_AUTHORS_BY_USER_SUCCESS:
            return fetchAuthorsSuccess(state, action);
        case actionTypes.FETCH_AUTHORS_BY_USER_FAIL:
            return fetchAuthorsFail(state, action);

        // UPDATE
        case actionTypes.UPDATE_USER_START:
            return updateUserStart(state);
        case actionTypes.UPDATE_USER_SUCCESS:
            return updateUserSuccess(state, action);
        case actionTypes.UPDATE_USER_FAIL:
            return updateUserFail(state, action);

        // DELETE
        case actionTypes.DELETE_USER_START:
            return deleteUserStart(state);
        case actionTypes.DELETE_USER_SUCCESS:
            return deleteUserSuccess(state, action);
        case actionTypes.DELETE_USER_FAIL:
            return deleteUserFail(state, action);

        // RECOVERY PASSWORD
        case actionTypes.RECOVERY_USER_PASSWORD_START:
            return recoveryPasswordUserStart(state);
        case actionTypes.RECOVERY_USER_PASSWORD_SUCCESS:
            return recoveryPasswordUserSuccess(state, action);
        case actionTypes.RECOVERY_USER_PASSWORD_FAIL:
            return recoveryPasswordUserFail(state, action);

        // UPDATE PASSWORD
        case actionTypes.UPDATE_USER_PASSWORD_START:
            return updatePasswordUserStart(state);
        case actionTypes.UPDATE_USER_PASSWORD_SUCCESS:
            return updatePasswordUserSuccess(state, action);
        case actionTypes.UPDATE_USER_PASSWORD_FAIL:
            return updatePasswordUserFail(state, action);

        // RESET USER
        case actionTypes.RESET_USER_SUCCESS:
            return resetUserSuccess(state);

        default:
            return state;
    }
};

export default userReducer;
