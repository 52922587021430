import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import classes from './styles.module.scss';
import { History } from 'history';
import Logo from '../../components/UI/Logo';
import * as routes from '../../shared/routes';
import { checkSession } from '../../shared/helpers/session';
import OffCanvas from '../OffCanvas';
import SigninForm from '../../components/SigninForm';
import Navigation from '../../components/UI/Navigation';
import { IUser } from '../../shared/interfaces';

interface IProps {
    currentUser: IUser;
    onSignout(): boolean;
    onResetUser(): void;
    isAuthenticated: boolean;
    history: History;
}

interface IState {
    showSignin: boolean;
    hideOffCanvas: boolean;
}

class Header extends Component<IProps, IState> {
    state = {
        showSignin: false,
        hideOffCanvas: false
    };

    handleSignoutClicked = () => {
        this.props.onSignout();
    };

    handleNewTitle = () => {
        this.props.history.push(routes.TITLE.replace(':id', '0').replace(':step', '1'));
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
            if (this.props.isAuthenticated) {
                this.setState({
                    showSignin: false
                });

                setTimeout(() => {
                    this.setState({
                        hideOffCanvas: true
                    });
                }, 1000);
            } else {
                this.setState(
                    {
                        hideOffCanvas: false
                    },
                    () => {
                        this.props.onResetUser();
                        setTimeout(() => {
                            this.props.history.push('/');
                        }, 500);
                    }
                );
            }
        }
    }

    handleToggleSigninVisibility = () => {
        const showSignin = !this.state.showSignin;

        this.setState({ showSignin });
    };

    render() {
        const { isAuthenticated, currentUser } = this.props;
        const { showSignin, hideOffCanvas } = this.state;

        return (
            <>
                <header className={classes.Header}>
                    <Logo to={isAuthenticated ? routes.PUBLISHER : routes.HOMEPAGE} />
                    <div className={classes['Header-navigation']}>
                        <Navigation
                            currentUser={currentUser}
                            isAuthenticated={isAuthenticated || checkSession()}
                            onSignout={this.handleSignoutClicked}
                            onSignin={this.handleToggleSigninVisibility}
                            onNewTitle={this.handleNewTitle}
                        />
                    </div>
                </header>
                {!hideOffCanvas && (
                    <OffCanvas
                        position={'right'}
                        onOffCanvasCloseClicked={this.handleToggleSigninVisibility}
                        visible={showSignin}
                    >
                        <SigninForm
                            recoveryMode={showSignin}
                            onResetSigninModal={this.handleToggleSigninVisibility}
                        />
                    </OffCanvas>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.userState.user,
        isAuthenticated: state.sessionState.authenticated
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSignout: () => dispatch(actions.signout()),
        onResetUser: () => dispatch(actions.resetUser())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
