import React, { Component } from 'react';
import classes from './styles.module.scss';
import Slider from "react-slick";
import FadeIn from 'react-fade-in';
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./styles.scss";

import { FormattedMessage } from 'react-intl';

interface IProps {
    textIds: Array<string>;
    authors?: Array<string>;
    reference?: string;
}

class Example extends Component<IProps> {

    state = {
        open: false,
        oldSlide: 0,
        activeSlide: 0
    };

    handleExampleOpen = () => {
        let currentState = this.state.open;
        this.setState({
            open: !currentState
        })
    }
    
    render() {

        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            centerMode: false,
            beforeChange: (current, next) => this.setState({ oldSlide: current, activeSlide: next }),
            afterChange: current => this.setState({ activeSlide2: current })
        };

        const { open, activeSlide } = this.state;
        const { textIds, authors, reference } = this.props;

        const examplesTpl = textIds.map((str: string, index: number) => {
            return (
                <div key={index} className={classes['Example-content-block']}>
                    <p key={index}>
                        <FormattedMessage id={str}/>
                    </p>
                </div>
            );
        });

        return (
            <div className={classes['Example']}>
                <div className={classes['Example-info']} onClick={this.handleExampleOpen}>
                    <span>?</span>
                </div>
                { open &&
                    <FadeIn>
                    <div className={[classes['Example-content'], open ? classes['Example-content--active'] : ''].join(' ')}>
                        <h4 className={classes['Example-content-title']}>
                            <FormattedMessage
                                id="label.example.title"
                                values={{
                                    reference: <FormattedMessage id={reference} />,
                                    author: <em><FormattedMessage id={authors[activeSlide]} /></em>
                                }}  />
                        </h4>
                        <div className={[classes['Example-content-slider'], 'Example-slider'].join('')}>
                            <Slider {...settings}>
                                { examplesTpl }
                            </Slider>
                        </div>
                    </div>
                    </FadeIn>
                }
            </div>
        );
    }
};

export default Example;
