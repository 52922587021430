import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';

interface IProps {
    onDraftClicked(): void;
    onPublishClicked(): void;
    onNextClicked(): void;
    onPrevClicked(): void;
    previewRoute?: string;
    size?: string;
    title?: string;
}

class SavingBox extends Component<IProps, {}> {
    render() {
        const {
            onDraftClicked,
            onNextClicked,
            onPublishClicked,
            onPrevClicked,
            size,
            title,
            previewRoute
        } = this.props;

        return (
            <section
                className={[classes.SavingBox, size ? classes[`SavingBox--${size}`] : ''].join(' ')}
            >
                <div>
                    {onPrevClicked && size === 'small' && (
                        <Button clicked={onPrevClicked} type={'savebox-prev'}>
                            <FormattedMessage id={'button.prev'} />
                        </Button>
                    )}
                    {title && (
                        <h3>
                            <FormattedMessage id={'title.generic.title'} />
                            {' | '}
                            <span>
                                <FormattedMessage id={title} />
                            </span>
                        </h3>
                    )}
                </div>
                <div>
                    {onPrevClicked && size !== 'small' && (
                        <Button clicked={onPrevClicked} type={'savebox-prev'}>
                            <FormattedMessage id={'button.prev'} />
                        </Button>
                    )}
                    {onDraftClicked && (
                        <Button clicked={onDraftClicked} type={'savebox-draft'}>
                            <FormattedMessage id={'button.draft'} />
                        </Button>
                    )}
                    {onNextClicked && (
                        <Button clicked={onNextClicked} type={'savebox-next'}>
                            <FormattedMessage id={'button.next'} />
                        </Button>
                    )}
                    {onPublishClicked && (
                        <Button clicked={onPublishClicked} type={'savebox-publish'}>
                            <FormattedMessage id={'button.publish'} />
                        </Button>
                    )}
                    {previewRoute && (
                        <NavLink to={previewRoute} className={classes['SavingBox-preview-btn']} target="_blank" rel="noopener noreferrer">
                            <span>
                                <FormattedMessage id={'general.preview'} />
                            </span>
                        </NavLink>
                    )}
                </div>
            </section>
        );
    }
}

export default SavingBox;
