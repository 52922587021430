import * as actionTypes from '../actions/actionTypes';
import { IInterlocutorMessage, IMessage } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    type: string;
    error: string;
    message: IMessage;
    interlocutors: Array<IInterlocutorMessage>;
    conversation: Array<IMessage>;
    isNew: boolean;
    messageRead: IMessage;
    isLastPage: boolean;
    newMessagesFound: boolean;
}

const INITIAL_STATE = {
    list: [],
    currentConversation: [],
    interlocutors: [],
    message: null,
    isFetching: false,
    isFetchingInterlocutors: false,
    isFetchingConversation: false,
    isSending: false,
    didInvalidate: false,
    error: null,
    loadingConversation: false,
    messageRead: null,
    isLastPage: false,
    unreadMessagesFound: false
};

const createMessageStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        message: null,
        isSending: true,
        error: null,
        didInvalidate: false
    });
};

const createMessageSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { message: action.message, isSending: false });
};

const createMessageFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isSending: false, didInvalidate: true });
};

const fetchInterlocutorsStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        isFetchingInterlocutors: true,
        error: null,
        didInvalidate: false
    });
};

const fetchInterlocutorsSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        interlocutors: action.interlocutors,
        isFetchingInterlocutors: false
    });
};

const fetchInterlocutorsFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingInterlocutors: false,
        didInvalidate: true
    });
};

const fetchConversationStart = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        currentConversation: action.isNew ? [] : state.currentConversation,
        isLastPage: false,
        isFetchingConversation: true,
        error: null,
        didInvalidate: false
    });
};

const fetchConversationSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        currentConversation: action.conversation,
        isLastPage: action.isLastPage,
        isFetchingConversation: false
    });
};

const fetchConversationFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isFetchingConversation: false,
        didInvalidate: true
    });
};

const setReadMessageStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        messageRead: null,
        isStoring: true,
        error: null,
        didInvalidate: false,
        isStored: null
    });
};

const setReadMessageSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        messageRead: action.messageRead,
        interlocutors: state.interlocutors.map((message: IInterlocutorMessage) => {
            const messageReadId = action.messageRead && action.messageRead.id;

            if (messageReadId)
                message.readAt =
                    +message.id === +messageReadId ? action.messageRead.readAt : message.readAt;

            return message;
        }),
        isStoring: false
    });
};

const setReadMessageFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isStoring: false, didInvalidate: true });
};

const fetchUnreadMessagesFoundStart = (state = INITIAL_STATE) => {
    return updateObject(state, { unreadMessagesFound: state.unreadMessagesFound });
};

const fetchUnreadMessagesFoundSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { unreadMessagesFound: action.newMessagesFound });
};

const fetchUnreadMessagesFoundFail = (state = INITIAL_STATE) => {
    return updateObject(state, { unreadMessagesFound: false });
};

const resetConversation = (state = INITIAL_STATE) => {
    return updateObject(state, { currentConversation: [] });
};

const messageReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // CREATE
        case actionTypes.CREATE_MESSAGE_START:
            return createMessageStart(state);
        case actionTypes.CREATE_MESSAGE_SUCCESS:
            return createMessageSuccess(state, action);
        case actionTypes.CREATE_MESSAGE_FAIL:
            return createMessageFail(state, action);

        // INTERLOCUTORS
        case actionTypes.FETCH_INTERLOCUTORS_START:
            return fetchInterlocutorsStart(state);
        case actionTypes.FETCH_INTERLOCUTORS_SUCCESS:
            return fetchInterlocutorsSuccess(state, action);
        case actionTypes.FETCH_INTERLOCUTORS_FAIL:
            return fetchInterlocutorsFail(state, action);

        // CONVERSATION
        case actionTypes.FETCH_CONVERSATION_START:
            return fetchConversationStart(state, action);
        case actionTypes.FETCH_CONVERSATION_SUCCESS:
            return fetchConversationSuccess(state, action);
        case actionTypes.FETCH_CONVERSATION_FAIL:
            return fetchConversationFail(state, action);

        // SET READ
        case actionTypes.SET_READ_MESSAGE_START:
            return setReadMessageStart(state);
        case actionTypes.SET_READ_MESSAGE_SUCCESS:
            return setReadMessageSuccess(state, action);
        case actionTypes.SET_READ_MESSAGE_FAIL:
            return setReadMessageFail(state, action);

        // UNREAD MESSAGES
        case actionTypes.FETCH_UNREAD_MESSAGES_BY_USER_START:
            return fetchUnreadMessagesFoundStart(state);
        case actionTypes.FETCH_UNREAD_MESSAGES_BY_USER_SUCCESS:
            return fetchUnreadMessagesFoundSuccess(state, action);
        case actionTypes.FETCH_UNREAD_MESSAGES_BY_USER_FAIL:
            return fetchUnreadMessagesFoundFail(state);

        // RESET CONVERSATION
        case actionTypes.RESET_CONVERSATION:
            return resetConversation(state);

        default:
            return state;
    }
};

export default messageReducer;
