import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { IOption, ISelector, IServerOption, ITitle } from '../../../shared/interfaces';
import mainClasses from '../styles.module.scss';
import stepClasses from './styles.module.scss';
import Text from '../../UI/Text';
import Selector from '../../Field/Selector';
import constants from '../../../shared/constants';
import DateInput from '../../UI/DateInput';
import moment from 'moment';
import AddressAutocomplete from '../../Field/AddressAutocomplete';

interface IProps {
    data: ITitle;
    setData(name: string, value: any): void;
}

interface ISelectors {
    ambientWhereType: ISelector;
    ambientWhenType: ISelector;
}

interface ILocationData {
    ambientWhereType: string;
    ambientWhere: Array<string>;
    ambientWhenType: string;
    ambientWhenStartAt: string | null;
    ambientWhenEndAt: string | null;
}

interface IState {
    selectors: ISelectors;
    data: ILocationData;
    initialized: boolean;
}

class Location extends Component<IProps, IState> {
    state = {
        initialized: false,
        data: {
            ambientWhereType: '',
            ambientWhere: [''],
            ambientWhenType: '',
            ambientWhenStartAt: '',
            ambientWhenEndAt: ''
        },
        selectors: {
            ambientWhereType: {
                labelId: 'label.ambientWhereType',
                name: 'ambientWhereType',
                options: constants.WHERE_OPTIONS,
                minSelected: 1,
                instructionIds: ['instruction.where']
            },
            ambientWhenType: {
                labelId: 'label.ambientWhenType',
                name: 'ambientWhenType',
                options: constants.WHEN_OPTIONS,
                minSelected: 1,
                instructionIds: ['instruction.when']
            }
        }
    };

    componentDidMount(): void {
        this.initializeData();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.data !== prevProps.data && !this.state.initialized) {
            this.setState(
                {
                    initialized: true
                },
                () => {
                    this.initializeData();
                }
            );
        }
    }

    handleSelectorClicked = (name: string, index: number, selected: boolean): void => {
        let selectors: ISelectors | any = { ...this.state.selectors };
        let data: ILocationData = { ...this.state.data };

        selectors[name].options = selectors[name].options.map((item: IOption) => {
            return {
                ...item,
                selected: false
            };
        });

        selectors[name].options[index].selected = selected;
        data[name] = selectors[name].options[index].code;

        /*
        if(name === 'ambientWhenType'){
            data.ambientWhenStartAt = '';
            data.ambientWhenEndAt = '';
        }

        if(name === 'ambientWhereType'){
            data.ambientWhere = [''];
        }*/

        this.setState(
            {
                selectors,
                data
            },
            () => {
                this.props.setData(name, data[name]);
            }
        );
    };

    initializeData = () => {
        const {
            data: {
                ambientWhereType,
                ambientWhere,
                ambientWhenType,
                ambientWhenStartAt,
                ambientWhenEndAt
            }
        } = this.props;

        let data = { ...this.state.data };

        const startDate = ambientWhenStartAt ? moment(ambientWhenStartAt).format('YYYY-MM-DD') : '';
        const endDate = ambientWhenStartAt ? moment(ambientWhenEndAt).format('YYYY-MM-DD') : '';

        if (ambientWhereType) {
            data.ambientWhereType = ambientWhereType;
            this.setArraySelectorValue('ambientWhereType', ambientWhereType);
        }
        if (ambientWhenType) {
            data.ambientWhenType = ambientWhenType;
            this.setSelectorValue('ambientWhenType', ambientWhenType);
        }

        if (startDate) {
            data.ambientWhenStartAt =
                ambientWhenType === 'range di mesi-anni'
                    ? moment(startDate).format('YYYY')
                    : moment(startDate).format('DD/MM/YYYY');
        }
        if (endDate) {
            data.ambientWhenEndAt =
                ambientWhenType === 'range di mesi-anni'
                    ? moment(endDate).format('YYYY')
                    : moment(endDate).format('DD/MM/YYYY');
        }

        if (ambientWhere) {
            data.ambientWhere = ambientWhere;
        }

        this.setState({
            data
        });
    };

    setArraySelectorValue = (name: string, value: string) => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        selectors[name].options = selectors[name].options.map((item: IServerOption) => {
            return {
                ...item,
                selected: item.code === value
            };
        });

        this.setState({
            selectors
        });
    };

    setSelectorValue = (name: string, values: any) => {
        let selectors: ISelectors | any = { ...this.state.selectors };

        selectors[name].options = selectors[name].options.map((item: IServerOption) => {
            return {
                ...item,
                selected: item.code === values
            };
        });

        this.setState({
            selectors
        });
    };

    onChangeRange = (name: string, value: string, type: 'date' | 'year') => {
        let date = null;

        if (type === 'date') {
            const testDate = value.split('/');
            const customDate = new Date(+testDate[2], +testDate[1] - 1, +testDate[0]);

            date = moment(customDate);
        } else {
            const testDate = value;
            const customDate = new Date(+testDate, 0, 1);

            date = moment(customDate);
        }

        if (date) {
            date = date.format('YYYY-MM-DD');
            this.props.setData(name, date);
        }
    };

    onAddNewPlace = () => {
        let data = { ...this.state.data };

        data.ambientWhere.push('');

        this.setState({
            data
        });
    };

    removePlace = (index: number) => {
        let data = { ...this.state.data };

        data.ambientWhere.splice(index, 1);

        this.setState(
            {
                data
            },
            () => {
                this.props.setData('ambientWhere', data.ambientWhere);
            }
        );
    };

    onChangeWhereValue = (index: number, value: { formatted_address; lat; lng }) => {
        let data = { ...this.state.data };

        data.ambientWhere[index] = value.formatted_address;

        this.setState(
            {
                data
            },
            () => {
                this.props.setData('ambientWhere', data.ambientWhere);
            }
        );
    };

    render() {
        const { selectors, data } = this.state;

        return (
            <div className={[mainClasses.TitleForm, stepClasses.GenreAndFeatures].join(' ')}>
                <div className={mainClasses['TitleForm-header']}>
                    <Text tag={'h2'} transform={'uppercase'} weight={600} spacing={true}>
                        <FormattedMessage id={'title.section.location'} />
                    </Text>
                </div>
                <div className={mainClasses['TitleForm-content']}>
                    <div className={mainClasses['TitleForm-field']}>
                        <Selector
                            label={
                                selectors.ambientWhereType.labelId ? (
                                    <FormattedMessage id={selectors.ambientWhereType.labelId} />
                                ) : (
                                    ''
                                )
                            }
                            name={selectors.ambientWhereType.name}
                            clicked={this.handleSelectorClicked}
                            instructionIds={selectors.ambientWhereType.instructionIds}
                            options={selectors.ambientWhereType.options}
                        />
                        {data.ambientWhereType === 'specifica luogo' && (
                            <div className={mainClasses['TitleForm-place-row']}>
                                {data.ambientWhere.map((item: string, index: number) => {
                                    return (
                                        <div key={index} className={mainClasses['TitleForm-row']}>
                                            <AddressAutocomplete
                                                onlyCities={true}
                                                placeholderId="Inizia a digitare il nome della città o il paese"
                                                value={item}
                                                name={`where-${index}`}
                                                changed={(
                                                    name: string,
                                                    value: { formatted_address; lat; lng }
                                                ) => this.onChangeWhereValue(index, value)}
                                            />
                                            <button
                                                className={mainClasses['TitleForm-remove']}
                                                onClick={() => this.removePlace(index)}>
                                                    <span><FormattedMessage id="general.remove" /></span>
                                            </button>
                                        </div>
                                    );
                                })}
                                <button
                                    className={mainClasses['TitleForm-plus']}
                                    onClick={this.onAddNewPlace}>
                                        <span><FormattedMessage id="general.add" /></span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className={mainClasses['TitleForm-field']}>
                        <Selector
                            label={
                                selectors.ambientWhenType.labelId ? (
                                    <FormattedMessage id={selectors.ambientWhenType.labelId} />
                                ) : (
                                    ''
                                )
                            }
                            name={selectors.ambientWhenType.name}
                            clicked={this.handleSelectorClicked}
                            instructionIds={selectors.ambientWhenType.instructionIds}
                            options={selectors.ambientWhenType.options}
                        />
                        {data.ambientWhenType === 'data specifica' && (
                            <div className={mainClasses['TitleForm-range']}>
                                <DateInput
                                    type="date"
                                    name={'ambientWhenStartAt'}
                                    value={data.ambientWhenStartAt}
                                    onChanged={this.onChangeRange}
                                />
                            </div>
                        )}
                        {data.ambientWhenType === 'range di mesi-anni' && (
                            <div className={mainClasses['TitleForm-range']}>
                                <DateInput
                                    name={'ambientWhenStartAt'}
                                    placeholder={'YYYY'}
                                    type={'year'}
                                    value={data.ambientWhenStartAt}
                                    onChanged={this.onChangeRange}
                                />
                                <DateInput
                                    name={'ambientWhenEndAt'}
                                    placeholder={'YYYY'}
                                    type={'year'}
                                    value={data.ambientWhenEndAt}
                                    onChanged={this.onChangeRange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Location;
