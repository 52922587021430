export const HOMEPAGE = '/';
export const REGISTRATION = '/registration/:type';
export const ACTIVATION = '/activation/:code';
export const PUBLISHER = '/publisher';
export const DASHBOARD = '/dashboard';
export const TITLES = '/titles';
export const TITLE = '/title/:id/:step';
export const PREVIEW = '/preview/title/:id';
export const OFFERS = '/offers';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const THANK_YOU = '/thank-you';
export const ERROR_PAGE = '/error';
export const SUPPORT = '/support';
export const RECOVERY = '/recovery/:code';
