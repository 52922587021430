import React from 'react';
import classes from './styles.module.scss';

import { FormattedMessage } from 'react-intl';

interface IProps {
    textIds: Array<string>;
    onTop?: boolean;
    type?: string;
    additionalContent?: any;
}

const Instruction = (props: IProps) => {
    const { textIds, onTop, type, additionalContent } = props;

    return (
        <div className={[classes['Instruction'], onTop ? classes['Instruction--ontop'] : ''].join(' ')}>
            <div className={classes['Instruction-content']}>
                { additionalContent &&
                    <div className={classes['Instruction-additional']}>
                        { additionalContent }
                    </div>
                }
                {textIds.map((str: string, index: number) => {
                    return (
                        <p key={index}>
                            <FormattedMessage id={str} />
                        </p>
                    );
                })}
            </div>
            <div className={[
                classes['Instruction-info'],
                type ? classes[`Instruction-info--${type}`] : ''
                ].join(' ')}>
                <span>i</span>
            </div>
        </div>
    );
};

export default Instruction;
