import * as actionTypes from '../actions/actionTypes';
import { IAuthor, IPersonage, ITitle } from '../../shared/interfaces';
import { updateObject } from '../../shared/helpers/utilities';

interface IAction {
    id: number;
    type: string;
    list: Array<ITitle>;
    title: ITitle;
    error: string;
    personage: IPersonage;
    author: IAuthor;
}

const INITIAL_STATE = {
    myList: [],
    userList: [],
    title: null,
    error: null,
    isCreating: false,
    isFetching: false,
    isDeleting: false,
    isUpdating: false,
    isOrdering: false,
    created: false,
    updated: false,
    removed: true,
    lastCreatedId: null,
    personagesList: [],
    isUpdatingPersonages: false,
    personageUpdated: false,
    personageRemoved: false,
    lastPersonageCreated: null,
    authorsList: [],
    isUpdatingAuthors: false,
    lastAuthorCreated: null,
    newOrderSuccess: false
};

const createTitleStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastPersonageCreated: null,
        title: null,
        isCreating: true,
        error: null,
        didInvalidate: false,
        created: false,
        lastCreatedId: null,
        personagesList: []
    });
};

const createTitleSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldList: Array<ITitle> = [...state.myList];
    const updatedList = oldList.concat(action.title);

    return updateObject(state, {
        title: action.title,
        myList: updatedList,
        isCreating: false,
        created: true,
        lastCreatedId: action.title.id
    });
};

const createTitleFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isCreating: false, didInvalidate: true });
};

const fetchTitleStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastPersonageCreated: null,
        title: null,
        isFetching: true,
        error: null,
        didInvalidate: false
    });
};

const fetchTitleSuccess = (state = INITIAL_STATE, action: IAction) => {
    const personagesList = action.title ? action.title.personages : [];

    return updateObject(state, {
        title: action.title,
        isFetching: false,
        personagesList
    });
};

const fetchTitleFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const fetchMyTitlesStart = (state = INITIAL_STATE) => {
    return updateObject(state, { myList: [], isFetching: true, error: null, didInvalidate: false });
};

const fetchMyTitlesSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldMyList: Array<ITitle> = [...state.myList];
    const updatedMyList = oldMyList.concat(action.list);

    return updateObject(state, { myList: updatedMyList, isFetching: false });
};

const fetchMyTitlesFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isFetching: false, didInvalidate: true });
};

const updateTitleStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastPersonageCreated: null,
        isUpdating: true,
        error: null,
        didInvalidate: false,
        updated: false
    });
};

const updateTitleSuccess = (state = INITIAL_STATE, action: IAction) => {
    const personagesList = action.title ? action.title.personages : [];

    return updateObject(state, {
        title: action.title,
        personagesList,
        isUpdating: false,
        updated: true
    });
};

const updateTitleFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, { error: action.error, isUpdating: false, didInvalidate: true });
};

const deleteTitleStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastPersonageCreated: null,
        isDeleting: true,
        error: null,
        didInvalidate: false,
        deleted: false
    });
};

const deleteTitleSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        removed: true,
        myList: state.myList.filter((item: ITitle) => item.id !== action.id),
        isDeleting: false
    });
};

const deleteTitleFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        removed: false,
        didInvalidate: true,
        isCreating: false
    });
};

// Personages
const createPersonageForTitleStart = (state = INITIAL_STATE) => {
    return updateObject(state, { lastPersonageCreated: null, isUpdatingPersonages: true });
};

const createPersonageForTitleSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldList: Array<IPersonage> = [...state.personagesList];
    const updatedList = oldList.concat(action.personage);

    return updateObject(state, {
        personagesList: updatedList,
        isUpdatingPersonages: false,
        lastPersonageCreated: action.personage
    });
};

const createPersonageForTitleFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingPersonages: false,
        didInvalidate: true
    });
};

const deletePersonageForTitleStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastPersonageCreated: null,
        isUpdatingPersonages: true,
        error: null,
        didInvalidate: false,
        personageRemoved: false
    });
};

const deletePersonageForTitleSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        personagesList: state.personagesList.filter((item: IPersonage) => item.id !== action.id),
        isUpdatingPersonages: false,
        personageRemoved: true
    });
};

const deletePersonageForTitleFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        didInvalidate: true,
        isUpdatingPersonages: false
    });
};

const updatePersonageStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastPersonageCreated: null,
        isUpdatingPersonages: true,
        personageUpdated: false
    });
};

const updatePersonageSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldList: Array<IPersonage> = [...state.personagesList];
    const updatedList = oldList.map((per: IPersonage) =>
        per.id === action.personage.id
            ? {
                  ...per,
                  ...action.personage
              }
            : per
    );

    return updateObject(state, {
        personagesList: updatedList,
        isUpdatingPersonages: false,
        personageUpdated: true
    });
};

const updatePersonageFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingPersonages: false,
        didInvalidate: true
    });
};

// Authors
const createAuthorForTitleStart = (state = INITIAL_STATE) => {
    return updateObject(state, { lastAuthorCreated: null, isUpdatingAuthors: true });
};

const createAuthorForTitleSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldList: Array<IAuthor> = [...state.authorsList];
    const updatedList = oldList.concat(action.author);

    return updateObject(state, {
        authorsList: updatedList,
        isUpdatingAuthors: false,
        lastAuthorCreated: action.author
    });
};

const createAuthorForTitleFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingAuthors: false,
        didInvalidate: true
    });
};

const deleteAuthorForTitleStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        lastAuthorCreated: null,
        isUpdatingAuthors: true,
        error: null,
        didInvalidate: false
    });
};

const deleteAuthorForTitleSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        authorsList: state.authorsList.filter((item: IAuthor) => item.id !== action.id),
        isUpdatingAuthors: false
    });
};

const deleteAuthorForTitleFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        didInvalidate: true,
        isUpdatingAuthors: false
    });
};

const updateAuthorStart = (state = INITIAL_STATE) => {
    return updateObject(state, { lastAuthorCreated: null, isUpdatingAuthors: true });
};

const updateAuthorSuccess = (state = INITIAL_STATE, action: IAction) => {
    const oldList: Array<IAuthor> = [...state.authorsList];
    const updatedList = oldList.map((per: IAuthor) =>
        per.id === action.author.id ? action.author : per
    );

    return updateObject(state, { authorsList: updatedList, isUpdatingAuthors: false });
};

const updateAuthorFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: action.error,
        isUpdatingAuthors: false,
        didInvalidate: true
    });
};

// Sort
const sortTitlesStart = (state = INITIAL_STATE) => {
    return updateObject(state, {
        error: null,
        didInvalidate: false,
        isOrdering: true,
        newOrderSuccess: false
    });
};

const sortTitlesSuccess = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        isOrdering: false,
        newOrderSuccess: true
    });
};

const sortTitlesFail = (state = INITIAL_STATE, action: IAction) => {
    return updateObject(state, {
        error: 'Error during ordering',
        didInvalidate: true,
        isOrdering: false
    });
};

// Reset
const resetMyTitles = (state = INITIAL_STATE) => {
    return updateObject(state, {
        myList: [],
        personagesList: [],
        lastPersonageCreated: null,
        authorsList: [],
        lastAuthorCreated: null
    });
};

const resetMyTitle = (state = INITIAL_STATE) => {
    return updateObject(state, {
        title: null,
        personagesList: [],
        lastPersonageCreated: null,
        authorsList: [],
        lastAuthorCreated: null
    });
};

const originalReducer = (state = INITIAL_STATE, action: IAction) => {
    switch (action.type) {
        // CREATE
        case actionTypes.CREATE_TITLE_START:
            return createTitleStart(state);
        case actionTypes.CREATE_TITLE_SUCCESS:
            return createTitleSuccess(state, action);
        case actionTypes.CREATE_TITLE_FAIL:
            return createTitleFail(state, action);
        // READ/FETCHING
        case actionTypes.FETCH_TITLE_START:
            return fetchTitleStart(state);
        case actionTypes.FETCH_TITLE_SUCCESS:
            return fetchTitleSuccess(state, action);
        case actionTypes.FETCH_TITLE_FAIL:
            return fetchTitleFail(state, action);

        case actionTypes.FETCH_MY_TITLES_START:
            return fetchMyTitlesStart(state);
        case actionTypes.FETCH_MY_TITLES_SUCCESS:
            return fetchMyTitlesSuccess(state, action);
        case actionTypes.FETCH_MY_TITLES_FAIL:
            return fetchMyTitlesFail(state, action);
        // UPDATE
        case actionTypes.UPDATE_TITLE_START:
            return updateTitleStart(state);
        case actionTypes.UPDATE_TITLE_SUCCESS:
            return updateTitleSuccess(state, action);
        case actionTypes.UPDATE_TITLE_FAIL:
            return updateTitleFail(state, action);
        // DELETE
        case actionTypes.DELETE_TITLE_START:
            return deleteTitleStart(state);
        case actionTypes.DELETE_TITLE_SUCCESS:
            return deleteTitleSuccess(state, action);
        case actionTypes.DELETE_TITLE_FAIL:
            return deleteTitleFail(state, action);

        // CREATE PERSONAGE FOR TITLE
        case actionTypes.CREATE_PERSONAGE_FOR_TITLE_START:
            return createPersonageForTitleStart(state);
        case actionTypes.CREATE_PERSONAGE_FOR_TITLE_SUCCESS:
            return createPersonageForTitleSuccess(state, action);
        case actionTypes.CREATE_PERSONAGE_FOR_TITLE_FAIL:
            return createPersonageForTitleFail(state, action);
        // DELETE PERSONAGE FOR TITLE
        case actionTypes.DELETE_PERSONAGE_FOR_TITLE_START:
            return deletePersonageForTitleStart(state);
        case actionTypes.DELETE_PERSONAGE_FOR_TITLE_SUCCESS:
            return deletePersonageForTitleSuccess(state, action);
        case actionTypes.DELETE_PERSONAGE_FOR_TITLE_FAIL:
            return deletePersonageForTitleFail(state, action);
        // UPDATE PERSONAGE
        case actionTypes.UPDATE_PERSONAGE_START:
            return updatePersonageStart(state);
        case actionTypes.UPDATE_PERSONAGE_SUCCESS:
            return updatePersonageSuccess(state, action);
        case actionTypes.UPDATE_PERSONAGE_FAIL:
            return updatePersonageFail(state, action);

        // CREATE AUTHOR FOR TITLE
        case actionTypes.CREATE_AUTHOR_FOR_TITLE_START:
            return createAuthorForTitleStart(state);
        case actionTypes.CREATE_AUTHOR_FOR_TITLE_SUCCESS:
            return createAuthorForTitleSuccess(state, action);
        case actionTypes.CREATE_AUTHOR_FOR_TITLE_FAIL:
            return createAuthorForTitleFail(state, action);
        // DELETE AUTHOR FOR TITLE
        case actionTypes.DELETE_AUTHOR_FOR_TITLE_START:
            return deleteAuthorForTitleStart(state);
        case actionTypes.DELETE_AUTHOR_FOR_TITLE_SUCCESS:
            return deleteAuthorForTitleSuccess(state, action);
        case actionTypes.DELETE_AUTHOR_FOR_TITLE_FAIL:
            return deleteAuthorForTitleFail(state, action);
        // UPDATE AUTHOR
        case actionTypes.UPDATE_AUTHOR_START:
            return updateAuthorStart(state);
        case actionTypes.UPDATE_AUTHOR_SUCCESS:
            return updateAuthorSuccess(state, action);
        case actionTypes.UPDATE_AUTHOR_FAIL:
            return updateAuthorFail(state, action);

        // SORT TITLES
        case actionTypes.SORT_TITLES_START:
            return sortTitlesStart(state);
        case actionTypes.SORT_TITLES_SUCCESS:
            return sortTitlesSuccess(state, action);
        case actionTypes.SORT_TITLES_FAIL:
            return sortTitlesFail(state, action);

        case actionTypes.RESET_MY_TITLES:
            return resetMyTitles(state);
        case actionTypes.RESET_MY_LAST_TITLE:
            return resetMyTitle(state);

        default:
            return state;
    }
};

export default originalReducer;
