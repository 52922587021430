import * as actionTypes from './actionTypes';
import GenresService from '../../services/api/genres';
import { IError, IServerOption, IResponse } from '../../shared/interfaces';

// READ/FETCH
export const fetchGenreStart = () => {
    return {
        type: actionTypes.FETCH_GENRE_START
    };
};

export const fetchGenreSuccess = (genre: IServerOption) => {
    return {
        type: actionTypes.FETCH_GENRE_SUCCESS,
        genre
    };
};

export const fetchGenreFail = (error: string) => {
    return {
        type: actionTypes.FETCH_GENRE_FAIL,
        error
    };
};

export const fetchGenre = (id: number) => {
    return (dispatch: any) => {
        if (id) dispatch(fetchGenreStart());

        GenresService.get(id).then(
            (response: IResponse) => {
                dispatch(fetchGenreSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchGenreFail(errorMsg));
            }
        );
    };
};

export const fetchGenresStart = () => {
    return {
        type: actionTypes.FETCH_GENRES_START
    };
};

export const fetchGenresSuccess = (genres: Array<IServerOption>) => {
    return {
        type: actionTypes.FETCH_GENRES_SUCCESS,
        genres
    };
};

export const fetchGenresFail = (error: string) => {
    return {
        type: actionTypes.FETCH_GENRES_FAIL,
        error
    };
};

export const fetchGenres = () => {
    return (dispatch: any) => {
        dispatch(fetchGenresStart());

        GenresService.get().then(
            (response: IResponse) => {
                dispatch(fetchGenresSuccess(response.data));
            },
            (error: IError) => {
                const {
                    data: { errors }
                } = error;

                let errorMsg = null;

                if (errors && errors.length > 0) {
                    errorMsg = errors.map((error: any) => error.message).join('; ');
                } else {
                    errorMsg = 'Internal server error';
                }

                dispatch(fetchGenresFail(errorMsg));
            }
        );
    };
};
